<script>
import MessageModal from '@/components/common/message-modal/index.vue';
import { EarlyWarningAPI } from '@/api';
import moment from 'moment/moment';

export default {
  name: 'Index',
  components: {
    MessageModal
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    currentWarning: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      },
      searchDate: '',
      currentDate: undefined,
      tableData: []
    };
  },
  mounted() {
    this.getMarkWaterWarringChartInfo();
  },
  methods: {
    getMarkWaterWarringChartInfo() {
      EarlyWarningAPI.getMarkWaterWarringChartInfo({
        id: this.currentWarning.id,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        ...this.currentDate
      }).then((res) => {
        this.tableData = res.data.items || [];
        this.pagination.totalCount = res.data.totalCount;
      });
    },
    // 表格数据-分页
    handleCurrentChange(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getMarkWaterWarringChartInfo();
    },
    // 筛选
    searchSubmit() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      if (this.searchDate) {
        this.currentDate = {
          startDate: moment(this.searchDate[0]).format('YYYY-MM-DD'),
          endDate: moment(this.searchDate[1]).format('YYYY-MM-DD')
        };
      } else {
        this.currentDate = undefined;
      }
      this.getMarkWaterWarringChartInfo();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <div class="water-warning-modal">
    <message-modal :show="show" :title="$t('header.viewAlerts')" :width="1100" @close="close">
      <div class="water-warning-modal-content">
        <div class="content-search">
          <el-form :inline="true">
            <el-form-item :label="$t('header.warningTime')">
              <el-date-picker
                v-model="searchDate"
                type="daterange"
                :range-separator="$t('header.To')"
                :start-placeholder="$t('header.startDate')"
                :end-placeholder="$t('header.endDate')"
                style="width: 250px; background-color: #000"
              />

            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="searchSubmit">{{$t('header.query')}}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content-table">
          <el-table style="width: 100%" :height="400" border :data="tableData">
            <el-table-column :label="$t('header.serialNumber')" align="center">
              <template v-slot="{ $index }">{{ $index + 1 }}</template>
            </el-table-column>
            <el-table-column :label="$t('header.warningTime')" align="center" prop="lastDate" width="160">
            </el-table-column>
            <el-table-column :label="$t('home.longitudeAndLatitude')" align="center">
              <template slot-scope="scoped"
                ><div>{{$t('home.longitude')}}：{{ scoped.row.lng }}</div>
                <div>{{$t('home.latitude')}}：{{ scoped.row.lat }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('header.latitudeAndLongitudeAddress')" align="center" prop="place"> </el-table-column>
            <el-table-column :label="$t('header.detectionValue')" align="center" prop="reportValue"> </el-table-column>
            <el-table-column :label="$t('header.unit')" align="center">
              <template> m </template>
            </el-table-column>
            <el-table-column :label="$t('header.rangeOfWarning')" align="center" prop="threshold"> </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              layout="total,prev, pager, next"
              :total="pagination.totalCount"
              :page-size="pagination.pageSize"
              :current-page.sync="pagination.pageNo"
              :page-sizes="[10, 20, 30, 50]"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </message-modal>
  </div>
</template>

<style scoped lang="scss">
  .water-warning-modal {
    &-content {
      background-color: #2c313b;
      padding: 8px;
      .content-table {
        background-color: #0a0b0d;
        padding-bottom: 8px;
        margin-top: 10px;
        .pagination {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        }
      }
    }
  }
</style>
