<script>
export default {
  name: 'Index',
  props: {
    thrustDataList: {
      type: Array,
      default: () => [
       /*  {
          label: 'webrtc',
          brand: 'webrtc',
          url: 'webrtc://live.mmcuav.cn/hls/shouguang01',
          title: '监控视频'
        } */
      ]
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <div class="">
    <MMCPlayer
      ref="MMCPlayer"
      :title="title"
      :stream-options="thrustDataList"
      :size="1"
      @close="close"
    />
  </div>
</template>

<style scoped lang="scss"></style>
