<script>
import MessageModal from '@/components/common/message-modal/index.vue';
import { EarlyWarningAPI } from '@/api';
export default {
  name: 'Index',
  components: {
    MessageModal
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    currentGasObj: {
      type: Object,
      default: () => undefined
    },
    currentDate: {
      type: Object,
      default: () => undefined
    }
  },
  data() {
    return {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      },
      tableData: []
    };
  },
  mounted() {
    this.getGasAlertRecords();
  },
  methods: {
    getGasAlertRecords() {
      EarlyWarningAPI.getGasAlertRecords({
        ...this.currentDate,
        pageNum: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        mountHardId: this.currentGasObj.mountHardId
      }).then((res) => {
        this.tableData = res.data.content || [];
        this.pagination.totalCount = res.data.totalElements;
      });
    },
    // 表格数据-分页
    handleCurrentChange(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getGasAlertRecords();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <div class="gas-warning-modal">
    <message-modal :show="show" :title="$t('header.viewAlerts')" :width="1100" @close="close">
      <div class="gas-warning-modal-content">
        <div class="content-table">
          <el-table style="width: 100%" :height="400" border :data="tableData">
            <el-table-column :label="$t('header.serialNumber')" align="center">
              <template v-slot="{ $index }">{{ $index + 1 }}</template>
            </el-table-column>
            <el-table-column :label="$t('header.warningTime')" align="center" prop="alertTime"> </el-table-column>
            <el-table-column :label="$t('home.longitudeAndLatitude')" align="center" width="160">
              <template slot-scope="scoped">
                <div>{{$t('home.longitude')}}：{{ scoped.row.longitude }}</div>
                <div>{{$t('home.latitude')}}：{{ scoped.row.latitude }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('header.latitudeAndLongitudeAddress')" align="center" prop="location">
            </el-table-column>
            <el-table-column :label="$t('header.earlyWarningGas')" align="center" prop="type"> </el-table-column>
            <el-table-column :label="$t('header.detectionValue')" align="center" prop="alertValue"> </el-table-column>
            <el-table-column :label="$t('header.unit')" align="center" prop="unit"> </el-table-column>
            <el-table-column :label="$t('header.rangeOfWarning')" align="center" prop="thresholdValue">
              <template slot-scope="scoped">>={{ scoped.row.thresholdValue }}</template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              layout="total,prev, pager, next"
              :total="pagination.totalCount"
              :page-size="pagination.pageSize"
              :current-page.sync="pagination.pageNo"
              :page-sizes="[10, 20, 30, 50]"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </message-modal>
  </div>
</template>

<style scoped lang="scss">
.gas-warning-modal {
  &-content {
    background-color: #2c313b;
    padding: 8px;
    .content-table {
      background-color: #0a0b0d;
      padding-bottom: 8px;
      .pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
  }
}
</style>
