<template>
  <div class="flyModelBox" :class="{ hangar: currentEagle }">
    <div class="airplane_mode">
      <div class="label mt6 ml10">飞行模式：</div>
      <el-select
        v-model="uav_status"
        style="width: 153px"
        placeholder="请选择"
        @change="changeMode"
      >
        <el-option
          v-for="item in uav_status_options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
  </div>
</template>
<script>
import { Control_API } from "@/api";
export default {
  data() {
    return {
      uav_status: "",
      uav_status_options: [
        {
          value: "LAND",
          label: "安全降落",
        },
        {
          value: "AUTO_RTL",
          label: "返航模式",
        },
        {
          value: "AUTO_MISSION",
          label: "航线模式",
        },
        {
          value: "POSITION",
          label: "定点模式",
        },
        {
          value: "HOLD",
          label: "保持模式",
        },
        {
          value: "AUTO_FOLLOW_TARGET",
          label: "跟随模式",
        },
      ],
    };
  },
  computed: {

    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    currentEagle() {
      return this.$store.state.uavApplicationsEagleNest.currentEagle;
    },
  },
  methods: {
    // 无人机接管更新状态
    async updateTakeOver() {
      await Control_API.updateTakeOver(this.$uav.id);
    },
    changeMode(mode) {
      const modeName = this.uav_status_options.filter(
        (e) => e.value === mode
      )[0].label;
      this.$store.dispatch(`uavApplications/mode${mode}`, {
        callback: (isOk) => {
          isOk && this.$message.success(`${modeName}指令已下发`);
        },
      });
      this.updateTakeOver();
    },
  },
};
</script>
<style lang="scss" scoped>
.flyModelBox {
  position: fixed;
  width: 234px;
  height: 36px;
  line-height: 36px;
  background: url("./img/bg.png");
  top: 64px;
  right: 80px;

  &.hangar {
    // top: 49px;
  }

  .airplane_mode {
    display: flex;
    align-items: center;

    .label {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
    }
  }
}
</style>
