<template>
  <div class="entitiesModelBox" :style="EntitiesStyle">
    <header class="header" v-interact>
      <div>{{ title }}</div>
      <div class="cp" @click="$emit('close')">X</div>
    </header>
    <main class="entitiesModel_mian" v-if="title == '全景图'">
      <el-image
        class="dib"
        crossorigin="anonymous"
        style="width: 100%; height: 98%"
        :src="entitiesModelData.panoramaUrl"
      >
      </el-image>
    </main>
    <main v-if="title == '图片'" class="entitiesModel_mian">
      <el-image
        v-for="item in entitiesModelData.sources"
        v-show="item.sourceType == 1"
        class="item-img"
        :src="item.sourceUrl"
        @click="addAttribute"
        :preview-src-list="[item.sourceUrl]"
        crossorigin="anonymous"
        lazy
      />
    </main>
    <main v-if="title == '监控视频'" class="entitiesModel_mian">
      <MMCPlayer
        class="enMMCPlayer MMCPlayerStyle"
        :stream-options="entitiesModelData.streamUrlList"
        :obstacle-data="null"
        :size="1"
        network-type=""
        style="top: 0; left: 0"
      >
      </MMCPlayer>
    </main>
    <main v-if="title == '视频'" class="entitiesModel_mian">
      <div v-for="item in entitiesModelData.sources" in>
        <video
          crossorigin="use-credentials"
          v-if="item.sourceType == 2"
          style="width: 100%"
          controls
          autoplay
          loop
        >
          <source :src="item.sourceUrl" type="video/mp4" />
        </video>
      </div>
    </main>
    <main v-if="title == '水位预警'" class="entitiesModel_mian">
      <div :id="echartsDomId" class="wih100 ht100"></div>
    </main>
    <div class="ml10 mb10 cf">标注名称：{{ entitiesModelData.markName }}</div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import moment from "moment";
import { getRandomInt } from "@/utils";
export default {
  props: {
    entitiesModelData: {
      typeof: Object,
      default: () => {
        {
        }
      },
    },
    EntitiesStyle: {
      typeof: Object,
      default: () => {
        null;
      },
    },
    title: {
      typeof: String,
      default: () => {
        "";
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: null,
      streamOptions: [
        // 视频流数据
        /* {
          label: "flv",
          brand: "flv",     //支持以下品牌播放器"srs|fly|liveNVR|QingLiu|webrtc", 为空则根据url判断使用liveNVR或QingLiu
          url:
            "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
        },
        {
          label: "liveNVR",
          brand: 'liveNVR',
          url:
            "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
          fpvUrl:     //只有liveNVR支持fpvUrl
            "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
        {
          label: "srs",
          brand: 'srs',
          url:
            "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
        {
          label: "QingLiu",
          url:
            "pzsp://pzlink999bju.powzamedia.com:7788/live/ld/trans/test/mlinkm/channel1?ndselect=2&linkmode=8&fstusrd=1&us=1&wsinfo=pzlink999bju.powzamedia.com-13000&only-video=1&rct=500",
        },
        {
          label: "WebRtc",
          brand: 'webrtc',
          url:
            "webrtc://live.mmcuav.cn/hls/shouguang01",
        }, */
        {
          // label: "WebRtc",
          brand: "webrtc",
          url: "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
      ],
      echartsDomId: "",
      myChart: "",
    };
  },
  watch: {
    title: function (val) {
      if (val == "水位预警") {
     setTimeout(() => {
      this.initEchartsArea();
     },500);
      } else {
        if (this.myChart) {
          this.myChart.clear();
          this.myChart = "";
        }
      }
    },
  },
  created() {
    this.echartsDomId = this.getRangeCode();
  },
  mounted() {
    this.initEchartsArea();
  },
  methods: {
    getRangeCode(len = 6) {
      var orgStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let returnStr = "";
      for (var i = 0; i < len; i++) {
        returnStr += orgStr.charAt(Math.floor(Math.random() * orgStr.length));
      }
      return returnStr;
    },
    initEchartsArea() {
      const currentHour = moment().hour();
      const hoursBeforeCurrentHour = [
        currentHour < 10 ? `0${currentHour}:00` : `${currentHour}:00`,
      ];
      for (let i = 1; i <= currentHour; i++) {
        const hour = moment().subtract(i, "hours").hour();
        hoursBeforeCurrentHour.push(hour < 10 ? `0${hour}:00` : `${hour}:00`);
      }
      if (this.myChart) {
        this.myChart.clear();
        this.myChart = "";
      }
      const chartDom = document.getElementById(this.echartsDomId);
      if (chartDom) {
        this.myChart = echarts.init(chartDom);
        const option = {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: hoursBeforeCurrentHour.reverse(),
            axisLine: {
              show: true,
            },
          },
          yAxis: {
            type: "value",
            name: "水位（m）",
            axisLine: {
              show: true,
            },
          },
          dataZoom: [
            {
              type: "slider",
              xAxisIndex: 0,
              filterMode: "filter",
            },
          ],
          series: [
            {
              data: new Array(hoursBeforeCurrentHour.length)
                .fill(0)
                .map((v) => getRandomInt(10, 1)),
              type: "line",
              areaStyle: {
                color: "#50ABFF",
              },
            },
          ],
        };
        this.myChart.setOption(option);
      }
    },
    addAttribute() {
      this.$nextTick((e) => {
        document
          .querySelector(".el-image-viewer__canvas")
          .childNodes[0].setAttribute("crossorigin", "anonymous");
      });
    },
    showDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.entitiesModelBox {
  width: 600px;
  position: absolute;
  z-index: 10;
  .entitiesModel_mian {
    height: 35vh;
    overflow-x: hidden;
    overflow-y: auto;
    .item-img {
      width: 100%;
      height: 100%;
    }
  }
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(52, 223, 255, 1),
      rgba(24, 113, 214, 0)
    )
    1 1;
  backdrop-filter: blur(4px);
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    color: #fff;
    line-height: 32px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000,
      0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000;
    border-radius: 4px 4px 0px 0px;
  }
}
::v-deep {
  .el-dialog {
    // width: 627px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  }
  .el-dialog__title {
    color: #fff;
  }
  .enMMCPlayer {
    position: relative !important;
    left: 9px !important;
    top: 10px !important;
    height: 345px;
    .interact-wrap {
      // width: 424px;
      height: 26.6vh;
      transform: none !important;
      .cpt_video {
        width: 568px;
        height: 295px;
      }
      .size_type {
        display: none;
      }
      .close {
        display: none;
      }
    }
  }
  .MMCPlayerStyle {
    //全屏按钮
    video::-webkit-media-controls-fullscreen-button {
      display: none;
    }
    //播放按钮
    video::-webkit-media-controls-play-button {
      display: none;
    }
    //进度条
    video::-webkit-media-controls-timeline {
      display: none;
    }
    //观看的当前时间
    video::-webkit-media-controls-current-time-display {
      display: none;
    }
    //剩余时间
    video::-webkit-media-controls-time-remaining-display {
      display: none;
    }
    //音量按钮
    video::-webkit-media-controls-mute-button {
      display: none;
    }
    video::-webkit-media-controls-toggle-closed-captions-button {
      display: none;
    }
    //音量的控制条
    video::-webkit-media-controls-volume-slider {
      display: none;
    }
    //所有控件
    video::-webkit-media-controls-enclosure {
      display: none;
    }
  }
}
</style>
