<template>
  <div class="database">
    <div v-interact class="database-head">
      <div class="left-title">{{ $t(`operation.${showStatus}`) }} {{$t('flightApplication.Airspace')}}</div>
      <i class="el-icon-close" @click="onClose" />
    </div>
    <div class="database-body">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item :label="`${$t('flightApplication.airspaceName')}：`" prop="name">
          <el-input maxlength="16" v-model="ruleForm.name" :placeholder="$t('flightApplication.pleaseInputAirspaceName')" />
        </el-form-item>
        <el-form-item :label="`${$t('flightApplication.airspaceType')}：`" prop="typeId">
          <el-select v-model="ruleForm.typeId" :placeholder="$t('flightApplication.pleaseSelectAirspaceType')"> 
            <el-option :label="$t('flightApplication.电子围栏')" value="1" />
            <el-option :label="$t('flightApplication.禁飞区')" value="2" />
            <el-option :label="$t('flightApplication.限高区')" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="ruleForm.typeId === '3'"
          :label="`${$t('flightApplication.limitHeight')}(m):`"
          :prop="ruleForm.typeId === '3' ? 'limitedHeight' : 'name'"
        >
          <el-input v-model="ruleForm.limitedHeight" type="number" :placeholder="$t('flightApplication.pleaseInputLimitHeight')" />
        </el-form-item>
        <el-form-item :label="`${$t('flightApplication.所属机构')}：`" prop="organizationId">
          <el-cascader
            v-model="ruleForm.organizationId"
            :placeholder="$t('flightApplication.请选择所属机构')"
            :show-all-levels="false"
            :options="orgOptions"
            :props="{
              value: 'id',
              label: 'name',
              children: 'childNodes',
              checkStrictly: true,
              emitPath: false
            }"
          />
        </el-form-item>
        <el-form-item :label="`${$t('flightApplication.绘制方式')}：`" class="tl">
          <div class="region tl dib tc">
            <el-tooltip
              content="点击按钮开始绘制空域,双击右键完成绘制"
              placement="top"
              effect="dark"
            >
              <div class="dib cp" @click="drawClick">
                <div class="draw" />
                <div class="cf tc">{{$t('flightApplication.绘制多边形')}}</div>
              </div>
            </el-tooltip>
          </div>
          <el-tooltip
            content="点击按钮开始绘制空域，第一个点为起点第二个点为终点，以两个点为半径生成一个圆形区域"
            placement="top"
            effect="dark"
          >
            <div class="dib ml20 cp" @click="drawCircleClick">
              <div class="dib f22 ml20 vam iconfont icon-fuwuwangdian cf" />
              <div class="cf tc">{{$t('flightApplication.绘制圆形')}}</div>
            </div>
          </el-tooltip>
        </el-form-item>
        <el-form-item :label="`${$t('flightApplication.坐标内容')}：`" prop="region">
          <div> <el-input v-model="ruleForm.region" type="textarea" /></div>
        </el-form-item>

        <el-form-item :label="`${$t('flightApplication.色值')}：`" prop="color">
          <el-color-picker v-model="ruleForm.color" style="width: 100%" />
        </el-form-item>

        <el-form-item :label="`${$t('flightApplication.airspaceStatus')}：`" prop="isEnable">
          <!-- <el-radio-group v-model="ruleForm.isEnable">
            <el-radio name="启用" :label="true"  ></el-radio>
            <el-radio  name="停用"  :label="fasle"  ></el-radio>
          </el-radio-group> -->
          <el-switch v-model="ruleForm.isEnable" class="fl mt10"
            inactive-color="#ff4949"
            :active-text="$t('flightApplication.启用')"
            :inactive-text="$t('flightApplication.停用')"
          />
        </el-form-item>
        <el-form-item :label="`${$t('header.remark')}：`" prop="remark">
          <el-input v-model="ruleForm.remark" :placeholder="$t('header.pleaseInputRemark')" type="textarea" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')" size="small">{{$t(`operation.${showStatus}`)}}</el-button>
          <el-button  @click="resetForm('ruleForm')">{{$t('operation.Reset')}}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Control_API, Airspace } from '@/api';
import { mapActions } from 'vuex';
import { Utils } from '@/utils/cesium';
let viewer;
let entities = [];
let positions = [];
let centerPositionEntity = null;
// 绘制的空域
let polygonEntity = null;
export default {
  props: {
    showStatus: {
      typeof: String,
      default: ' 新增'
    },
    editData: {
      typeof: Object,
      default: {}
    }
  },
  data() {
    return {
      ruleForm: {
        color: '#1BABE8',
        isEnable: true,
        name: null,
        organizationId: null,
        region: null,
        remark: null,
        limitedHeight: null,
        typeId: null
      },
      rules: {
        name: [{ required: true, message: this.$t('flightApplication.pleaseInputAirspaceName'), trigger: 'blur' }],
        typeId: [{ required: true, message: this.$t('flightApplication.pleaseSelectAirspaceType'), trigger: 'blur' }],
        organizationId: [{ required: true, message: this.$t('flightApplication.pleaseSelectOrganization'), trigger: 'blur' }],
        region: [
          {
            required: true,
            message: this.$t('flightApplication.pleaseDrawRegion'),
            trigger: 'change'
          }
        ],
        isEnable: [{ required: true, message: this.$t('flightApplication.pleaseSelectStatus'), trigger: 'change' }],
        color: [{ required: true, message: this.$t('flightApplication.pleaseSelectColor'), trigger: 'change' }],
        limitedHeight: [{ required: true, message: this.$t('flightApplication.pleaseInputLimitHeight'), trigger: 'blur' }]
      },
      orgOptions: [],
      leftClickHandler: null,
      rightClickHandler: null
    };
  },
  methods: {
    ...mapActions(['showAllArea']),
    // 创建圆形 start
    drawCircleClick() {
      this.removeInputAction();
      this.clearMarker();
      this.$message.success(this.$t('flightApplication.pleaseSelectFirstPoint'));
      const _this = this;
      this.leftClickHandler = viewer.screenSpaceEventHandler.setInputAction(function (event) {
        const position = viewer.scene.pickPosition(event.position);
        if (position) {
          _this.creatPoint(position);
        }
        _this.$message.success(this.$t('flightApplication.pleaseSelectSecondPoint'));
        if (positions.length == 2) {
          _this.createPolyline(position);
          // 生成圆形
          _this.createCircle(_this.ruleForm.name);
          // 清除之前的点击位置和圆形实体
          viewer.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
            _this.leftClickHandler
          );
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    createCircle(text) {
      let color = '#ff0000';
      if (this.ruleForm.color) {
        color = this.ruleForm.color;
      }
      const radius = Cesium.Cartesian3.distance(positions[0], positions[1]);
      const center = positions[0];
      try {
        polygonEntity = viewer.entities.add({
          position: center,
          label: {
            text: text || '',
            scaleByDistance: new Cesium.NearFarScalar(800, 2.0, 900, 0.6),
            scale: 0.8,
            verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
            pixelOffset: new Cesium.Cartesian2(20, 20) // 文本偏移量
          },
          ellipse: {
            semiMinorAxis: radius,
            semiMajorAxis: radius,
            material: Cesium.Color.fromCssColorString(color),
            outline: true,
            outlineColor: Cesium.Color.WHITE
          }
        });
        let points = [];
        positions.map((item) => {
          const { lng, lat } = Utils.transformCartesian2WGS84(item);
          points.push([lng, lat].join(','));
        });
        points = points.join(' ');
        this.ruleForm.region = points;
        entities.push(polygonEntity);
      } catch (error) {
        console.log(error);
      }
    },
    // 创建圆形 end
    // 创建矩形 start
    drawClick() {
      this.clearMarker();
      const _this = this;
      // 点击事件处理函数
      this.leftClickHandler = viewer.screenSpaceEventHandler.setInputAction(function (e) {
        // 获取点击位置的地理坐标
        const position = viewer.scene.pickPosition(e.position);
        if (position) {
          _this.creatPoint(position);
          _this.createPolyline(position);
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      var rightClickTimer; // 计时器用于判断双击事件
      var isRightDoubleClick = false; // 标记是否为右键双击事件
      this.rightClickHandler = viewer.screenSpaceEventHandler.setInputAction(function (click) {
        // 判断是否为右击事件
        if (click.button === Cesium.ScreenSpaceEventType.RIGHT_BUTTON) {
          if (rightClickTimer) {
            // 如果计时器已经存在，则表示已经进行了一次右击
            // 此次右击为双击事件
            clearTimeout(rightClickTimer);
            isRightDoubleClick = true;
            _this.handleRightDoubleClick();
          } else {
            // 否则，启动计时器等待一段时间判断是否为双击事件
            rightClickTimer = setTimeout(function () {
              if (!isRightDoubleClick) {
                // 如果计时器结束后仍然没有触发双击事件，则为右击事件
              }
              // 重置计时器和标记
              rightClickTimer = undefined;
              isRightDoubleClick = false;
            }, 300); // 调整延时时间以适应您的需求
          }
        }
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
    // 双击右键绘制矩形
    handleRightDoubleClick() {
      if (positions.length < 3) {
        return this.$message.warning(this.$t('flightApplication.PleaseSelectAtLeastThreePoints'));
      }
      const _this = this;
      // 取消事件监听
      this.removeInputAction();
      let color = '#ff0000';
      if (this.ruleForm.color) {
        color = this.ruleForm.color;
      }
      // 添加连线，形成闭合区域,
      if (positions.length > 2) {
        polygonEntity = viewer.entities.add({
          polygon: {
            hierarchy: positions,
            material: Cesium.Color.fromCssColorString(color)
          }
        });
        const centerPosition = Cesium.BoundingSphere.fromPoints(positions).center;
        centerPositionEntity = viewer.entities.add({
          position: centerPosition,
          label: {
            text: _this.ruleForm.name || '',
            scaleByDistance: new Cesium.NearFarScalar(800, 2.0, 900, 0.6),
            scale: 0.8,
            verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
            pixelOffset: new Cesium.Cartesian2() // 文本偏移量
          }
        });
        this.createAfterPolyline();
        entities.push(polygonEntity);
        entities.push(centerPositionEntity);
        // this.ruleForm.region = JSON.stringify(positions)
        let points = [];
        positions.map((item) => {
          const { lng, lat } = Utils.transformCartesian2WGS84(item);
          points.push([lng, lat].join(','));
        });
        points = points.join(' ');
        console.log(points, 'ashdnkajshbdnkasjhbdniaskjdbnksa');
        this.ruleForm.region = points;
      }
      // 创建矩形最后一条边
      this.createAfterPolyline();
    },
    createAfterPolyline() {
      if (positions.length > 1) {
        const lineEntity = viewer.entities.add({
          polyline: {
            positions: [positions[0], positions[positions.length - 1]],
            width: 2,
            material: Cesium.Color.RED
          }
        });
        entities.push(lineEntity);
        // 计算两个点之间的距离
        const distance = Cesium.Cartesian3.distance(positions[0], positions[positions.length - 1]);
        console.log('创建最后一条边-距离', distance);

        // 计算线的中心位置
        const center = Cesium.Cartesian3.add(
          positions[positions.length - 1],
          positions[0],
          new Cesium.Cartesian3()
        );
        console.log('创建最后一条边-距离计算线的中心位置', center);

        const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
        // 创建 Label 标签来显示距离
        var labelEntity = viewer.entities.add({
          position: newcenter,
          label: {
            text: distance.toFixed(2) + 'm',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            pixelOffset: new Cesium.Cartesian2(0, -20)
          }
        });
        entities.push(labelEntity);
      }
    },
    // 创建矩形 end
    creatPoint(position) {
      // 添加点位
      const pointEntity = viewer.entities.add({
        position: position,
        point: {
          pixelSize: 20,
          color: Cesium.Color.RED,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2
        },
        label: {
          text: (positions.length + 1).toString(),
          scale: 0.5,
          font: 'bold 24px Microsoft YaHei',
          fillColor: Cesium.Color.WHITE,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: false,
          outlineWidth: 0
        }
      });

      entities.push(pointEntity);
      positions.push(position);
    },
    createPolyline(position) {
      // 添加线
      if (positions.length > 1) {
        var lineEntity = viewer.entities.add({
          polyline: {
            positions: [positions[positions.length - 2], position],
            width: 2,
            material: Cesium.Color.RED
          }
        });
        entities.push(lineEntity);
        // 计算两个点之间的距离
        const distance = Cesium.Cartesian3.distance(position, positions[positions.length - 2]);
        // 计算线的中心位置
        const center = Cesium.Cartesian3.add(
          positions[positions.length - 2],
          position,
          new Cesium.Cartesian3()
        );
        const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
        // 创建 Label 标签来显示距离
        var labelEntity = viewer.entities.add({
          position: newcenter,
          label: {
            text: distance.toFixed(2) + 'm',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            pixelOffset: new Cesium.Cartesian2(0, -20)
          }
        });
        entities.push(labelEntity);
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.showStatus === '新增') {
            this.ruleForm.id = null;
            const res = await Airspace.Add({
              ...this.ruleForm
            });
            if (res.code === 200) {
              this.$message.success(this.$t('flightApplication.添加成功'));
              this.$emit('closeAdd');
              this.clearMarker();
              this.showAllArea(viewer);
            }
          } else {
            const res = await Airspace.Edit({
              ...this.ruleForm
            });
            if (res.code === 200) {
              this.$message.success(this.$t('flightApplication.修改成功'));
              this.$emit('closeAdd');
              this.clearMarker();
              this.showAllArea(viewer);
            }
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 清除数据
    clearMarker() {
      if (entities) {
        for (let i = 0; i < entities.length; i++) {
          const element = entities[i];
          viewer.entities.remove(element);
        }
        entities = [];
        positions = [];
        // 绘制的空域
        polygonEntity = null;
      }
    },
    // 取消事件监听
    removeInputAction() {
      viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_CLICK,
        this.leftClickHandler
      );
      viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.RIGHT_CLICK,
        this.rightClickHandler
      );
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.clearMarker();
    },
    onClose() {
      this.clearMarker();
      this.$emit('closeAdd');
    },
    async getOrgList() {
      const res = await Control_API.getAllOrg();
      if (res.code === 200) {
        this.orgOptions = res.data;
        this.find(this.orgOptions);
      }
    },
    find(list) {
      list.map((item) => {
        if (!item.childNodes.length) {
          return (item.childNodes = undefined);
        } else {
          this.find(item.childNodes);
        }
      });
    },
    showArea(data) {
      let color = this.ruleForm.Color;
      if (data.color) {
        color = data.color;
      }
      const coordinates = JSON.parse(data.region);
      // 代表矩形区域
      if (coordinates.length > 2) {
        for (let i = 0; i < coordinates.length; i++) {
          const position = coordinates[i];
          this.creatPoint(position);
          this.createPolyline(position);
        }
        if (positions.length > 2) {
          polygonEntity = viewer.entities.add({
            polygon: {
              hierarchy: positions,
              material: Cesium.Color.fromCssColorString(color)
            }
          });
          const centerPosition = Cesium.BoundingSphere.fromPoints(positions).center;
          centerPositionEntity = viewer.entities.add({
            position: centerPosition,
            label: {
              text: data.name || '',
              scaleByDistance: new Cesium.NearFarScalar(800, 2.0, 900, 0.6),
              scale: 0.8,
              verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
              pixelOffset: new Cesium.Cartesian2() // 文本偏移量
            }
          });
          this.createAfterPolyline();
          viewer.camera.flyTo(polygonEntity);
          entities.push(polygonEntity);
          entities.push(centerPositionEntity);
        }
      } else if (coordinates.length == 2) {
        // 代表圆形区域
        for (let i = 0; i < coordinates.length; i++) {
          const position = coordinates[i];
          this.creatPoint(position);
          this.createPolyline(position);
        }
        this.createCircle(data.name);
      }
    }
  },
  watch: {
    'ruleForm.color': {
      handler(value) {
        if (value && polygonEntity && polygonEntity.polygon) {
          polygonEntity.polygon.material = Cesium.Color.fromCssColorString(value);
        } else if (value && polygonEntity && polygonEntity.ellipse) {
          polygonEntity.ellipse.material = Cesium.Color.fromCssColorString(value);
          // 执行相应的操作
        }
      },
      deep: true,
      immediate: true
    },
    'ruleForm.name': {
      handler(value) {
        if (value && centerPositionEntity && centerPositionEntity.label) {
          centerPositionEntity.label.text = value;
        } else if (value && polygonEntity && polygonEntity.ellipse) {
          polygonEntity.label.text = value;
        }
      },
      deep: true,
      immediate: true
    },

    editData: {
      handler(value) {
        if (value) {
          this.clearMarker();
          this.ruleForm = value;
          this.ruleForm.typeId = String(value.typeId);
          this.showArea(value);
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.clearMarker();
  },
  async mounted() {
    viewer = window.viewer;
    await this.getOrgList();
    if (this.editData) {
      this.clearMarker();
      this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      this.ruleForm.typeId = String(this.editData.typeId);
      this.showArea(this.editData);
    }
  }
};
</script>

<style lang="scss" scoped>
.database {
  // width: 300px;
  // height: 455px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(52, 223, 255, 1), rgba(24, 113, 214, 0)) 1 1;
  backdrop-filter: blur(4px);
  position: fixed;
  text-align: right;
  padding: 0;
  margin: 0;
  top: 200px;
  right: 65px;

  ::v-deep {
    .el-input__inner {
      width: 263px;
    }

    .el-switch__label {
      color: #fff;
    }

    .el-switch__label {
      .is-active {
        color: #409eff !important;
      }
    }
    .el-form-item {
      margin-bottom: 22px;
    }
  }

  .database-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 270px;
    height: 32px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000;
    border-radius: 4px 4px 0px 0px;
    color: #fff;
    font-size: 14px;

    .left-title {
      margin-left: 10px;
    }
  }

  .database-body {
    margin: 0 auto;
    width: 389px;
    // height: 419px;
    background: #0a0b0d;
    padding: 0 20px;
    overflow-y: auto;
    overflow-x: hidden;

    ::v-deep .el-color-picker__trigger {
      width: 100%;

      .el-color-picker__color {
        .el-color-picker__empty.el-icon-close {
          right: unset;
        }
      }
    }

    .region {
      .draw {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background: url('~@/assets/home/subplatForm/icon/draw.png') no-repeat;
        background-size: 100% 100%;
        margin: 0 AUTO;
      }
    }
  }
}
</style>
