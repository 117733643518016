<template>
  <div>
    <el-upload
      :limit="3"
      action="#"
      :on-exceed="handleExceed"
      list-type="picture-card"
      :auto-upload="false"
      accept=".png, .jpg"
      :file-list="fileList"
      multiple
      :on-change="changePic"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }" class="wih100 ht100">
        <img
          crossorigin="anonymous"
          class="el-upload-list__item-thumbnail"
          :src="file.url"
          alt=""
        />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog title="预览" :append-to-body="true" :visible.sync="dialogVisible">
      <img
        crossorigin="anonymous"
        style="max-width: 500px"
        class="dib"
        :src="dialogImageUrl"
        alt=""
      />
    </el-dialog>
  </div>
</template>
<script>
import { AirLine } from '@/api';
export default {
  data() {
    return {
      url: '',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: []
    };
  },
  methods: {
    changePic(file, fileList) {
      this.fileList = fileList;
    },
    async getfileUpload(src) {
      const params = new FormData();
      const blobFile = dataURItoBlob(src);
      params.append('file', blobFile);
      const res = await AirLine.uploadFile(params);
      console.log(res.msg, ' res.msg');
    },

    beforeAvatarUpload(file) {
      console.log(file, 'file');
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      if (isJPG && isLt2M) {
        this.fileList.push(file);
      }
      this.fileList = [...this.fileList]; // 触发视图更新
      return false; // 阻止自动上传
    },
    handleRemove(file) {
      console.log(this.fileList, 'this.fileListl');
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件`);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #51abfc;
  border-radius: 0px;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  cursor: pointer;
  line-height: 16px;
  vertical-align: top;
  background: #4faafd;
  .el-upload-list__item-actions:hover {
    font-size: 12px;
  }
  i {
    color: #fff;
    font-size: 9px;

    // transform:
  }
}
::v-deep {
  .el-upload-list__item-actions:hover {
    opacity: 1;
    font-size: 12px;
  }
}
::v-deep {
  .el-upload-list__item-actions {
  }
}
::v-deep {
  .el-upload-list--picture-card .el-upload-list__item {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    display: inline-block;
    span {
      margin-left: 0;
    }
  }
  .el-dialog {
    width: 627px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  }
  .el-dialog__title {
    color: #fff;
  }
}
</style>
