<template>
  <div>
    <!-- <v2-sider v-if="this.userInfo && this.userInfo.roleType === 4" /> -->
    <!-- <v3-sider v-else/> -->
    <v34-sider v-if="this.userInfo" />
  </div>
</template>

<script>
import v34Sider from './v3.4.0';
import v3Sider from './v3';
import v2Sider from './v2';
import { mapState } from 'vuex';
export default {
  name: 'Navsider',
  components: { v3Sider, v2Sider, v34Sider },
  data() {
    return {};
  },

  computed: {
    ...mapState(['userInfo'])
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log('用户信息--->', this.userInfo);
  },
  methods: {}
};
</script>
<style scoped lang="scss"></style>
