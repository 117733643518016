<script>
import MessageModal from '@/components/common/message-modal/index.vue';
import * as echarts from 'echarts';
import moment from 'moment/moment';
import { getRandomInt } from '@/utils';
export default {
  name: 'Index',
  components: {
    MessageModal
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    }
  },
  mounted() {
    this.initEchartsArea();
  },
  methods: {
    initEchartsArea() {
      const currentHour = moment().hour();
      const hoursBeforeCurrentHour = [
        currentHour < 10 ? `0${currentHour}:00` : `${currentHour}:00`
      ];
      for (let i = 1; i <= currentHour; i++) {
        const hour = moment().subtract(i, 'hours').hour();
        hoursBeforeCurrentHour.push(hour < 10 ? `0${hour}:00` : `${hour}:00`);
      }
      const chartDom = document.getElementById('water-echarts');
      const myChart = echarts.init(chartDom);
      const option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: hoursBeforeCurrentHour.reverse(),
          axisLine: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          name: '水位（m）',
          axisLine: {
            show: true
          }
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            filterMode: 'filter'
          }
        ],
        series: [
          {
            data: new Array(hoursBeforeCurrentHour.length).fill(0).map((v) => getRandomInt(10, 1)),
            type: 'line',
            areaStyle: {
              color: '#50ABFF'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <div class="water-history-modal">
    <message-modal :show="show" title="历史曲线" @close="close">
      <div class="water-echarts-wrap">
        <div id="water-echarts" class="water-echarts"></div>
      </div>
    </message-modal>
  </div>
</template>

<style scoped lang="scss">
.water-history-modal {
  .water-echarts-wrap {
    background-color: #2c313b;
    padding: 8px;
    .water-echarts {
      height: 400px;
      background-color: #0a0b0d;
    }
  }
}
</style>
