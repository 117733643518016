export function transformWGS842Cartesian(position, alt) {
  return position
    ? Cesium.Cartesian3.fromDegrees(
        position.lng,
        position.lat,
        (position.alt = alt || position.alt),
        Cesium.Ellipsoid.WGS84
      )
    : Cesium.Cartesian3.ZERO;
}

export function transformCartesian2WGS84(cartesian) {
  const ellipsoid = Cesium.Ellipsoid.WGS84;
  const cartographic = ellipsoid.cartesianToCartographic(cartesian);
  return {
    lng: Cesium.Math.toDegrees(cartographic.longitude),
    lat: Cesium.Math.toDegrees(cartographic.latitude),
    alt: cartographic.height
  };
}

export function getCartesian3FromPX(viewer, px) {
  const picks = viewer.scene.drillPick(px);
  let isOn3dtiles = false;
  let isOnTerrain = false;
  let cartesian = null;

  for (const i in picks) {
    const pick = picks[i];

    if (
      pick &&
      (pick.primitive instanceof Cesium.Cesium3DTileFeature ||
        pick.primitive instanceof Cesium.Cesium3DTileset ||
        pick.primitive instanceof Cesium.Model)
    ) {
      isOn3dtiles = true;
    }

    // is on 3dtiles
    if (isOn3dtiles) {
      viewer.scene.pick(px);
      cartesian = viewer.scene.pickPosition(px);
      if (cartesian) {
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        if (cartographic.height < 0) cartographic.height = 0;
        const lng = Cesium.Math.toDegrees(cartographic.longitude);
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        const alt = cartographic.height;
        cartesian = transformWGS842Cartesian({ lng, lat, alt });
      }
    }
  }

  // is ellipsoid terrain
  const boolTerrain = viewer.terrainProvider instanceof Cesium.EllipsoidTerrainProvider;

  // is other terrain
  if (!isOn3dtiles && !boolTerrain) {
    const ray = viewer.scene.camera.getPickRay(px);
    if (!ray) return null;
    cartesian = viewer.scene.globe.pick(ray, viewer.scene);
    isOnTerrain = true;
  }

  if (!isOn3dtiles && !isOnTerrain && boolTerrain) {
    cartesian = viewer.scene.camera.pickEllipsoid(px, viewer.scene.globe.ellipsoid);
  }

  // 避免位置陷入地下
  if (cartesian) {
    const position = transformCartesian2WGS84(cartesian);
    if (position.alt < 0) {
      cartesian = transformWGS842Cartesian(position, 0.1);
    }
  }

  return cartesian;
}

export default {
  transformWGS842Cartesian,
  transformCartesian2WGS84,
  getCartesian3FromPX
};
