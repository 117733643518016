<script>
import MessageModal from '@/components/common/message-modal/index.vue';
import { taskNodeStatusDictionary, taskNodeOperateDictionary } from '@/utils/dictionary';
export default {
  name: 'Index',
  components: {
    MessageModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    flowItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      processNodeList: [
        {
          label: '流程节点名称',
          value: '',
          key: 'labelName'
        },
        {
          label: '节点功能',
          value: '',
          key: 'functionType',
          optionList: taskNodeStatusDictionary
        },
        {
          label: '指派操作员',
          value: '',
          key: 'operatorName'
        },
        {
          label: '指派类型',
          value: '',
          key: 'operateClass',
          optionList: [
            {
              label: '指定用户',
              value: 0
            },
            {
              label: '指定机构',
              value: 1
            }
          ]
        },
        {
          label: '操作状态',
          value: '',
          key: 'isOperate',
          optionList: taskNodeOperateDictionary
        },
        {
          label: '实际操作员',
          value: '',
          key: 'operateRealUsername'
        },
        {
          label: '实际操作员机构',
          value: '',
          key: 'operateRealOgrName'
        },
        {
          label: '操作时间',
          value: '',
          key: 'operateTime'
        }
      ]
    };
  },
  computed: {
    covertProcessNode() {
      return this.processNodeList.map((v) => ({
        ...v,
        value: Object.keys(this.flowItem).some((i) => i === v.key)
          ? v.optionList
            ? v.optionList.find((j) => j.value === this.flowItem[v.key])?.label
            : this.flowItem[v.key] || this.$t('home.no')
          : this.$t('home.no')
      }));
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <div class="process-node">
    <message-modal :show="show" title="流程节点描述" :width="400" @close="close">
      <div class="process-node-content">
        <div v-for="(v, index) in covertProcessNode" :key="index" class="content-form-item">
          <div class="item-label">{{ v.label }}</div>
          <div class="item-value">
            {{ v.value }}
          </div>
        </div>
      </div>
    </message-modal>
  </div>
</template>

<style scoped lang="scss">
.process-node {
  position: relative;
  &-content {
    padding: 8px;
    background-color: #1e2026;
    .content-form-item {
      display: flex;
      margin-top: 8px;
      .item-label {
        width: 100px;
        margin-top: 8px;
        font-size: 12px;
        color: #b3bbc5;
        line-height: 16px;
      }
      .item-value {
        flex: 1;
        background: #16181b;
        border-radius: 8px;
        border: 1px solid #233a58;
        padding: 6px 10px;
        font-size: 14px;
        color: #d8dde8;
      }
    }
  }
}
</style>
