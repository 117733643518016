var PopupNew = function (info) {
  this.constructor(info);
};
PopupNew.prototype.id = 0;
PopupNew.prototype.constructor = function (info) {
  const _this = this;
  _this.viewer = info.viewer; // 弹窗创建的viewer
  _this.geometry = info.geometry; // 弹窗挂载的位置
  _this.data = info.data; // 点位数据
  _this.del_real = info.del_real; // 是否展示
  // _this.vueThis = info.arr;
  // if(info.hasOwnProperty('arr')) {
  //   _this.arr = info.arr
  // }

  _this.id = 'popup_' + _this.__proto__.id++;
  _this.ctn = $("<div class='popup-new-body' id='" + _this.id + "'>");
  $(_this.viewer.container).append(_this.ctn);

  const contentHtml = '';

  var testConfig = {
    header: _this.data.name || _this.data.carNo || _this.data.account_name || '',
    content: `<div>${contentHtml}</div>`
  };
  _this.ctn.append(_this.createHtml(testConfig.header, testConfig.content));

  _this.offsetWidth = _this.ctn.get(0).offsetWidth;
  _this.offsetHeight = _this.ctn.get(0).offsetHeight;

  _this.eventListener = () => {
    _this.render(_this.geometry);
  };
  _this.viewer.scene.postRender.addEventListener(_this.eventListener);
};

PopupNew.prototype.render = function (geometry) {
  var _this = this;
  var position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(_this.viewer.scene, geometry);
  _this.ctn.css('left', position.x - _this.offsetWidth / 2);
  _this.ctn.css('top', position.y - _this.offsetHeight - 10);
};

PopupNew.prototype.createHtml = function (header, content) {
  var img = require(`../../../../assets/dataImage/${this.data.img_type}.png`);
  var html =
    '<div class="popup-new-header">' +
    header +
    '</div>' +
    // 内容
    '<div class="popup-new-content" >' +
    '<div class="popup-new-content-left" >' +
    '<div class="up" >' +
    ' <img src=' +
    img +
    ' alt="Smiley face" width="100%" height="30%">' +
    '</div>' +
    '<div class="dowm" >' +
    '</div>' +
    '</div>' +
    '<div class="popup-new-content-right" >' +
    '<div class="popup-new-content-right-up" >' +
    '<span>' +
    '详情 :' +
    '</span>' +
    '<div class="content" >' +
    '<div class="tb-box" >' +
    '<div class="tb-hd-box" >' +
    '<div class="tb-hd" >' +
    '无人机名称' +
    '</div>' +
    '<div class="tb-hd" >' +
    '无人机id' +
    '</div>' +
    '<div class="tb-hd" >' +
    '无人机序列号' +
    '</div>' +
    '<div class="tb-hd" >' +
    '无人机型号' +
    '</div>' +
    '</div>' +
    // tb-hd-box 结束
    '<div class="tb-bd-box" >' +
    '<div class="td" >' +
    header +
    '</div>' +
    '<div class="td" >' +
    '暂无' +
    '</div>' +
    '<div class="td" >' +
    '暂无' +
    '</div>' +
    '<div class="td" >' +
    '暂无' +
    '</div>' +
    '</div>' +
    // tb-bd-box 结束

    '<div class="tb-hd-box" >' +
    '<div class="tb-hd" >' +
    '无人机类型' +
    '</div>' +
    '<div class="tb-hd" >' +
    '飞行状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '联网状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '暂无' +
    '</div>' +
    '</div>' +
    // tb-hd-box 结束
    '<div class="tb-bd-box" >' +
    '<div class="td" >' +
    this.data.device_name +
    '</div>' +
    '<div class="td" >' +
    '暂无' +
    '</div>' +
    '<div class="td" >' +
    '暂无' +
    '</div>' +
    '<div class="td" >' +
    '暂无' +
    '</div>' +
    '</div>' +
    // tb-bd-box 结束

    '</div>' +
    // tb-box 结束
    '</div>' +
    // content 结束
    '</div>' +
    // popup-new-content-right-up

    '<div class="popup-new-content-right-down" >' +
    '<span>' +
    '健康状态 :' +
    '</span>' +
    '<div class="content" >' +
    '<div class="tb-box" >' +
    '<div class="tb-hd-box" >' +
    '<div class="tb-hd" >' +
    '陀螺仪状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '加速度计状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '罗盘状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '气压计状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '相对气压差状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '定位模块状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '光流模组状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '机载计算机状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '激光定位模块状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '定高功能模块状态' +
    '</div>' +
    '</div>' +
    // tb-hd-box1 结束
    '<div class="tb-bd-box" >' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '</div>' +
    // tb-bd-box1 结束

    '<div class="tb-hd-box" >' +
    '<div class="tb-hd" >' +
    '角速度控制状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '角度控制状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '航向控制状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '垂直位置状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '水平位置控制状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '动力状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '遥控器状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '冗余陀螺仪状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '冗余加速度计状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '冗余罗盘状态' +
    '</div>' +
    '</div>' +
    // tb-hd-box2 结束
    '<div class="tb-bd-box" >' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '</div>' +
    // tb-bd-box2 结束

    '<div class="tb-hd-box" >' +
    '<div class="tb-hd" >' +
    '电子围栏功能状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '姿态解算功能状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '仿地飞行功能状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '电机状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '日志记录功能状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '电池及电源状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '距离检测功能状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '卫星通信状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '起飞检测状态' +
    '</div>' +
    '<div class="tb-hd" >' +
    '避障功能模块状态' +
    '</div>' +
    '</div>' +
    // tb-hd-box3 结束
    '<div class="tb-bd-box" >' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '<div class="td" >' +
    '正常' +
    '</div>' +
    '</div>' +
    // tb-bd-box3 结束

    '</div>' +
    // tb-box 结束
    '</div>' +
    // content 结束
    '</div>' +
    '</div>' +
    '</div>';

  // //底部箭头
  // '<div class="bx-popup-new-tip-container" >' +
  // '<div class="bx-popup-new-tip" >' +
  // "</div>" +
  // "</div>"

  // '<div class="bx-popup-new-content" >' +
  //   content +
  //   "</div>" +
  return html;
};

PopupNew.prototype.close = function () {
  var _this = this;
  const { category, deviceHardId } = _this.data;
  _this.ctn.remove();
  if (category === 1) {
    _this.del_real(deviceHardId);
  }
  _this.viewer.scene.postRender.removeEventListener(_this.eventListener);
};

export default PopupNew;
