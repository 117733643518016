<template>
  <div ref="chart" style="width: 270px; height: 270px" />
</template>

<script>
const echarts = require("echarts");
export default {
  name: "ThrottleValue",
  props: {},
  data() {
    return {
      chart: null,
      data: 20,
      option: {
        series: [
          {
            startAngle: -80, // 饼图的角度
            hoverAnimation: false, // 取消饼图放大效果
            name: "访问来源",
            type: "pie",
            radius: ["50%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {},
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 20,
                name: "",
                itemStyle: {
                  normal: {
                    // 渐变色操作
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 1, color: "#2D0180" }, //
                      { offset: 0.7, color: "#0094D6" },
                      { offset: 0.4, color: "#00EBFF" },
                      { offset: 0, color: "#FFCB00" },
                    ]), //
                  },
                },
              },
              {
                value: 80,
                name: "",
                itemStyle: {
                  normal: {
                    // 渐变色操作
                    color: "rgba(0,0,0,0.66)",
                  },
                },
              },
              {
                value: 50,
                name: "",
                itemStyle: {
                  normal: {
                    // 渐变色操作
                    color: "transparent",
                  },
                },
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    // 油门值
    throttleValue() {
      return (
        this.$store.state.uavApplications.uavRealTimeData?.accelerator || 0
      );
    },
  },
  watch: {
    throttleValue: {
      immediate: true,
      handler(value) {
        if (value !== undefined) {
          this.$nextTick(() => {
            this.updateDom(value);
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const chartDom = this.$refs.chart;
      this.chart = echarts.init(chartDom);
      this.chart.setOption(this.option);
    },
    updateDom(value) {
      // const myChart = echarts.init(chartDom);
      const value1 = value / 10;
      const value2 = 100 - value1;

      let linear = [
        { offset: 1, color: "#2D0180" }, //
        { offset: 0.7, color: "#0094D6" },
        { offset: 0.4, color: "#00EBFF" },
        { offset: 0, color: "#FFCB00" },
      ];
      if (value > 600) {
        linear = [
          { offset: 1, color: "#2D0180" }, //
          { offset: 0.7, color: "#0094D6" },
          { offset: 0.4, color: "#00EBFF" },
          { offset: 0, color: "#f30909" },
        ];
      }
      this.chart.setOption({
        series: [
          {
            data: [
              {
                value: value1,
                name: "",
                itemStyle: {
                  normal: {
                    // 渐变色操作
                    color: new echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      linear
                    ), //
                  },
                },
              },
              {
                value: value2,
                name: "",
                itemStyle: {
                  normal: {
                    // 渐变色操作
                    color: "rgba(0,0,0,0.66)",
                  },
                },
              },
              {
                value: 50,
                name: "",
                itemStyle: {
                  normal: {
                    // 渐变色操作
                    color: "transparent",
                  },
                },
              },
            ],
          },
        ],
      });
    },
  },
};
</script>

<style scoped></style>
