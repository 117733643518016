<template>
  <div class="analysis">
    <div class="title">数据统计</div>
    <div class="pt4 pb5">
      <div class="tc cp" @click="linkageFn">
        <div :class="{ Select_Bg: linkage }" class="w50 h42">
          <img
            v-if="!linkage"
            style="width: 50px; height: 42px"
            src="./img/区域统计.png"
            alt="#"
          />
          <img
            v-else
            style="width: 50px; height: 42px"
            src="./img/区域统计.png"
            alt=""
          />
        </div>
        <span class="childTitle">区域统计</span>
      </div>
    </div>
    <div class="pt4 pb5">
      <div class="tc cp" @click="analysisFn">
        <div :class="{ Select_Bg: submergedAnalysis }" class="w50 h42">
          <img
            v-if="!submergedAnalysis"
            style="width: 50px; height: 42px"
            src="./img/淹没分析.png"
            alt="#"
          />
          <img
            v-else
            style="width: 50px; height: 42px"
            src="./img/淹没分析.png"
            alt=""
          />
        </div>
        <span class="childTitle">淹没分析</span>
      </div>
    </div>
    <!-- 淹没分析 -->
    <SubmergedAnalysis
      v-if="submergedAnalysis"
      :submerged-analysis-data="submergedAnalysisData"
      @close="submergedAnalysis = false"
    ></SubmergedAnalysis>

    <!--区域统计结果弹框 -->
    <div v-if="showResult" class="result">
      <div v-interact class="head">
        <div class="left">分析结果</div>
        <div class="right">
          <span v-hover class="iconfont icon-guanbi" @click="closeResult" />
        </div>
      </div>
      <div class="body">
        <div id="chartType" />
        <div class="download" @click="downloadFiles">
          <img
            class="downloadImg"
            src="~@/assets/image/icons/download.png"
            alt="#"
          />
          <div class="label">生成报告并导出</div>
        </div>
      </div>
    </div>
    <!-- 淹没分析 -->
    <Regional
      v-if="regionalFlag"
      :type="2"
      @close="regionalFlag = false"
      @showSubmergedAnalysis="showSubmergedAnalysisFn"
    ></Regional>
    <!-- 区域统计 -->
    <Regional
      v-if="showRegional"
      :type="1"
      ref="Regional"
      :_style="RegionalStyle2"
      @close="showRegional = false"
      @showEcharts="showEchartsFn"
    ></Regional>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { Utils } from "@/utils/cesium";
import * as echarts from "echarts";
import { ThreeDimensionalApi } from "@/api";
import SubmergedAnalysis from "./com/submergedAnalysis/index";
import Regional from "./com/Regional/index.vue";
export default {
  components: { SubmergedAnalysis, Regional },
  data() {
    return {
      RegionalStyle2: { right: "80px", top: "-80px" },
      regionalFlag: false,
      submergedAnalysis: false,
      linkage: false,
      showRegional: false,
      chartType: 1,
      showResult: false,
      markType: "",
      pointDownloadStr: "",
      submergedAnalysisData: null,
    };
  },
  methods: {
    ...mapMutations("subplatform", ["SET_TOTAL_MAPS"]),
    analysisFn() {
      this.regionalFlag = !this.regionalFlag;
    },
    showSubmergedAnalysisFn(data) {
      this.submergedAnalysisData = data;
      this.submergedAnalysis = true;
    },
    showEchartsFn(data) {
      this.showResult = true;
      this.$nextTick((e) => {
        this.chartType = data.chartType;
        if (data.chartType === 1) {
          this.initPieChart(data.data);
        } else {
          this.initColumnarChart(data.data);
        }
      });
    },
    showRegionalBox() {
      this.showRegional = true;
    },
    close() {
      this.showRegional = false;
    },

    closeResult() {
      this.showResult = false;
    },
    async downloadFiles() {
      this.getImage();
    },
    async getImage() {
      const echarts =
        document.getElementById("chartType").childNodes[0].childNodes[0];
      console.log(echarts);
      const canvas = document.createElement("canvas");
      canvas.width = echarts.videoWidth || echarts.width;
      canvas.height = echarts.videoHeight || echarts.width;
      const canvasContext = canvas.getContext("2d");
      canvasContext.drawImage(echarts, 0, 0);
      canvas.toBlob(
        async (blob) => {
          if (blob) {
            const data = new FormData();
            let RegionalDom = this.$refs.Regional;
            console.log(RegionalDom, "RegionalDom");
            data.append(
              "chartType",
              this.chartType === 1 ? "饼状图" : "柱状图"
            );
            data.append("file", blob);
            if (RegionalDom.markType) {
              data.append("markType", RegionalDom.markType);
            }
            if (RegionalDom.pointDownloadStr) {
              data.append("markLine", RegionalDom.pointDownloadStr);
            }
            const res = await ThreeDimensionalApi.getMarkChartReport(data);
            if (res) {
              this.downloadBlob(res, "标注统计报告");
            } else {
              this.$message({
                type: "error",
                message: res.msg || "出错了",
                duration: 1000,
              });
            }
          }
        },
        "image/png",
        1
      );
    },
    downloadBlob(content, filename) {
      const blob = new Blob([content], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
      this.$message({
                type: "success",
                message:'导出成功！',
                duration: 1000,
              });
    },
    initColumnarChart(data) {
      const chartDom = document.getElementById("chartType");
      const myChart = echarts.init(chartDom);
      myChart.clear();
      const chartData1 = [];
      const chartData2 = [];
      data.map((item) => {
        if (item.lableType === 2) {
          chartData1.push("其他");
          chartData2.push(item.markSize);
          return;
        }
        chartData1.push(item.lableName);
        chartData2.push(item.markSize);
      });
      let option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: chartData1,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: -45, // 代表逆时针旋转45度
            },
          },
        ],
        yAxis: [
          {
            minInterval: 1,
            type: "value",
          },
        ],
        series: [
          {
            name: "数量",
            type: "bar",
            barWidth: "20%",
            data: chartData2,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    initPieChart(data) {
      var chartDom = document.getElementById("chartType");
      var myChart = echarts.init(chartDom);
      myChart.clear();
      const chartData = [];
      data.map((item) => {
        if (item.lableType === 2) {
          chartData.push({
            value: item.markSize,
            name: "其他",
          });
          return;
        }
        chartData.push({
          value: item.markSize,
          name: item.lableName,
        });
      });
      var option;

      option = {
        title: {
          //   text: 'Referer of a Website',
          //   subtext: 'Fake Data',
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        series: [
          {
            name: "数量",
            type: "pie",
            radius: "50%",
            data: chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    linkageFn() {
      this.linkage = !this.linkage;
      //   this.$store.commit("subplatform/SET_LINK_AGE", this.linkage)
      //   if (!this.linkage) {
      //     this.SET_TOTAL_MAPS(1);
      //   }
      this.showRegional = !this.showRegional;
    },
  },
  beforeDestroy() {
    // this.$store.commit("subplatform/SET_LINK_AGE", false)
    // this.SET_TOTAL_MAPS(1);
  },
};
</script>
<style lang="scss" scoped>
.analysis {
  position: fixed;
  top: 136px;
  right: 4px;
  width: 56px;
  // height: 111px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border: 1px solid rgba(52, 223, 255, 1);
  border-radius: 8px;
  // border-image: linear-gradient(180deg, rgba(52, 223, 255, 1), rgba(24, 113, 214, 0)) 1 1;
  backdrop-filter: blur(4px);
  padding: 2px;
  box-sizing: border-box;
  text-align: center;
  .active {
    border: 1px solid #000;
  }
  .title {
    color: #d4f8ff;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #d4f8ff;
    line-height: 20px;
    border-bottom: 1px solid #000;
  }

  .childTitle {
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #d4f8ff;
    line-height: 20px;
  }

  .bgIMG {
    width: 50px;
    height: 50px;
  }

  .Select_Bg {
    background-image: url("./img/Select_Bg.png");
    background-repeat: no-repeat;
    background-position: 5px 0px;
  }
}
.regional-analysis {
  position: fixed;
  right: 80px;
  top: -80px;
  width: 346px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(52, 223, 255, 1),
      rgba(24, 113, 214, 0)
    )
    1 1;
  backdrop-filter: blur(4px);
  .head {
    height: 32px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05),
      inset 0px -1px 0px 0px #000000;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .left {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ebf5ff;
    }
    .right {
      color: #ebf5ff;
    }
  }
  .body {
    padding: 10px;
    .checkout {
      display: flex;
      align-items: center;
      .label {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        line-height: 15px;
        height: 19px;
      }
      .el-radio {
        color: #fff;
      }
    }
    .draw {
      margin-top: 5px;
      .label {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        text-align: left;
      }
      .draw {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background: url("~@/assets/home/subplatForm/icon/draw.png") no-repeat;
        background-size: 100% 100%;
        margin: 0 AUTO;
      }
    }
  }
  .foot {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      cursor: pointer;
      width: 96px;
      height: 32px;
      line-height: 32px;
      background: #50abff;
      border-radius: 2px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ebf5ff;
      margin-left: 10px;
      border: 1px solid transparent;
      transition: border 0.5s;
      &:hover {
        border: 1px solid #fff;
      }
    }
  }
}
.result {
  position: fixed;
  right: 80px;
  top: 220px;
  width: 396px;
  height: 276px;
  display: flex;
  flex-direction: column;
  .head {
    height: 32px;
    background: #292c35;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05),
      inset 0px -1px 0px 0px #000000,
      inset 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .left {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ebf5ff;
    }
    .right {
      color: #ebf5ff;
    }
  }
  .body {
    flex: 1;
    background-color: #fff;
    .download {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: right;
      align-items: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #b3bbc5;
      .downloadImg {
        width: 16px;
        height: 16px;
      }
      .label {
        vertical-align: bottom;
      }
    }
  }
}
#chartType {
  width: 100%;
  height: 300px;
}
</style>
