<template>
  <div class="seap-meta">
    <div class="seap-meta-title">行业板块</div>
    <div class="seap-meta-content">
      <p>
        SESP-U1无人机仿真系统目前设置了<span class="color"
          >基础训练、应急处理、自由飞行、电力巡检、安防搜救、特种作业、农业植保</span
        >
        和<span class="color">航测</span
        >等多种版块。针对行业应用中电力巡检的训练模式主要是采用不同类型的飞行器对不同类型的杆塔按照国网、南网的机巡流程模拟无人机精细化巡检训练飞行技能；
      </p>
    </div>
    <div class="seap-meta-title">飞行技能设计</div>
    <div class="seap-meta-content">
      <p>
        而相应考核模块主要是对技能掌握情况的检查，根据操作者对流程的完成度给出相应的评判；基础训练根据AOPA取证要求，设计了<span
          class="color"
          >起飞、降落、悬停、慢速自旋、四边航线、水平8字</span
        >等飞行技能；
      </p>
      <ul class="content-ul">
        <li>应急处理主要针对无人机动力流失、信号干扰等情况进行操作模拟；</li>
        <li>航拍自由场景飞行，让学员积累更多的飞行经验和学习行业常规应用；</li>
        <li>安防搜救针对野外搜救和水上救援两种场景进行模拟；</li>
        <li>特种作业模拟了喷火除异物和憎水性检测两种场景；</li>
        <li>农业植保中结合了航线规划和手动飞行于一体，对农田等地块的喷洒作业。</li>
      </ul>
    </div>
    <div class="seap-meta-footer">
      <div class="seap-meta-download">
        <div class="seap-meta-zip">
          <img src="@/assets/home/zip.png" />
          <span class="meta"> SESP-U1无人机仿真软件安装包</span>
        </div>
        <div class="seap-meta-btn">
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="downloadClick('https://pan.baidu.com/s/1Aj9Pdj2yPgJJtYTC00oKaw?pwd=xbhe')"
            >点击下载</el-button
          >
        </div>
      </div>
      <!--      <div class="seap-meta-download">-->
      <!--        <div class="seap-meta-zip">-->
      <!--          <img src="@/assets/home/zip.png">-->
      <!--          <span class="meta"> SESP-U1无人机仿真软件环境安装包</span>-->
      <!--        </div>-->
      <!--        <div class="seap-meta-btn">-->
      <!--          <el-button type="primary" icon="el-icon-download" @click="downloadClick('https://pan.baidu.com/s/1rAfIyvTAkjcMTh8ZToqwLA?pwd=qggf')">点击下载</el-button>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import fileSaver from 'file-saver';
export default {
  name: 'SespMeta',
  data() {
    return {};
  },
  methods: {
    downloadClick(url) {
      fileSaver.saveAs(url, 'SESP-U1安装包');
    }
  }
};
</script>

<style scoped lang="scss">
.seap-meta {
  padding-left: 12px;
  &-title {
    font-size: 24px;
    font-weight: 600;
    color: #2c313b;
    padding: 34px 0 27px 0;
  }
  &-content {
    font-size: 18px;
    color: #6c7073;
    line-height: 36px;
    text-indent: 2em;
    .color {
      color: #0457de;
    }
    .content-ul {
      padding-left: 24px;
      li {
        text-indent: 0em;
      }
      li::marker {
        color: #0457de;
      }
    }
  }
  &-footer {
    padding: 56px 0 38px 0;
    display: flex;
    align-items: center;
    .seap-meta-download {
      flex: 1;
    }
    .seap-meta-zip {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50px;
        height: 38px;
        margin-right: 30px;
      }
      span {
        font-size: 18px;
        color: #2c313b;
      }
    }
    .seap-meta-btn {
      text-align: center;
      margin-top: 36px;
      button {
        width: 478px;
        height: 44px;
        background: #1890ff;
        border-radius: 4px;
        border: 1px solid #3bc1e5;
      }
    }
  }
}
::v-deep {
  ul,
  li {
    list-style: disc !important;
  }
}
</style>
