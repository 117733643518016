<script>
import { EarlyWarningAPI } from '@/api';
import { createPoint } from '@/utils/CesiumFunction/CreateLabel';
import SurveillanceVideo from './components/surveillance-video/index.vue';
import WaterWarning from './components/water-warning/index.vue';

let viewer = null;
export default {
  name: 'Index',
  components: { SurveillanceVideo, WaterWarning },
  data() {
    return {
      options: [
        {
          label: '水位预警',
          value: 1
        },
        {
          label: '监控视频',
          value: 2
        }
      ],
      selectValue: [1, 2],
      markerEntityArr: [],
      monitorList: [],
      currentMonitor: undefined,
      isVideoShow: false,
      thrustDataList: [],
      isWaterLineShow: false,
      playerTitle: '',
    };
  },
  watch: {
    selectValue(newVal, oldVal) {
      this.markerEntityArr.forEach((v) => {
        window.viewer.entities.remove(v);
      });
      const cartographic = Cesium.Cartographic.fromCartesian(viewer.camera.positionWC);

      const longitude = Cesium.Math.toDegrees(cartographic.longitude);
      const latitude = Cesium.Math.toDegrees(cartographic.latitude);
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, 10000)
      });
      this.getMarkMonitorList();
    }
  },
  mounted() {
    viewer = window.viewer;
    this.getMarkMonitorList();

    this.$bus.$on('getMarkMonitorList', this.getMarkMonitorList);
  },
  beforeDestroy() {
    this.markerEntityArr.forEach((v) => {
      viewer.entities.remove(v);
    });
  },
  methods: {
    getMarkMonitorList() {
      EarlyWarningAPI.getMarkMonitorList({ monitorTypes: this.selectValue.join(',') }).then(
        (res) => {
          this.monitorList = (res.data || []).map((v) => ({ ...v, uid: Math.random() }));
          const waterList = this.monitorList
            .filter(item => {
              return item.waterType;
            })
            .map((v) => {
              return createPoint(viewer, {
                id: v.uid,
                position: Cesium.Cartesian3.fromDegrees(
                  v.lng + 0.00001141 * 50,
                  v.lat + 0.00000899 * 50,
                  10
                ), // 一米相当于经度0.00001141, 纬度0.00000899, 偏移X米,与视频监控的图标区分开, 防止重叠
                image: require('@/assets/home/water_marker.png'),
                title: v.markName,
                markInfo: v
              });
            });
          const streamList = this.monitorList
            .filter((item) => {
              return item.streamUrl;
            })
            .map((v) => {
              return createPoint(viewer, {
                id: v.uid,
                position: Cesium.Cartesian3.fromDegrees(v.lng, v.lat, 10),
                image: require('@/assets/home/video_marker.png'),
                title: v.markName,
                markInfo: v
              });
            });
          this.markerEntityArr = [...waterList, ...streamList];
          console.log('markerEntityArr', this.markerEntityArr)
          this.init_events();
        }
      );
    },
    init_events() {
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction(async (movement) => {
        const pickedLabel = viewer.scene.pick(movement.position);
        if (Cesium.defined(pickedLabel)) {
          const item = this.monitorList.find((v) => v.uid === pickedLabel.id._id);
          if (item) {
            let brand = '';
            if (item.streamUrlType === 'webrtc') {
              brand = 'SRS';
            } else {
              brand = 'liveNVR';
            }
            this.currentMonitor = item;
            this.playerTitle = item.markName;
            this.thrustDataList = item.streamUrl
              ? [
                {
                  url: item.streamUrl,
                  label: item.streamUrlType,
                  brand: brand
                }
              ] : [];
            // 是否监控视频
            this.isVideoShow = !!item.streamUrl;
            this.isWaterLineShow = !!item.waterType;
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    surveillanceVideoClose() {
      this.isVideoShow = false;
    },
    waterWarningModalClose() {
      this.isWaterLineShow = false;
    }
  }
};
</script>

<template>
  <div class="monitoring-parameters">
    <el-form>
      <el-form-item label="监控参数：" :inline="true" label-width="90px">
        <el-select v-model="selectValue" multiple placeholder="请选择监控参数">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <surveillance-video
      v-if="isVideoShow"
      :thrust-data-list="thrustDataList"
      :title="playerTitle"
      @close="surveillanceVideoClose"
    />
    <water-warning
      v-if="isWaterLineShow"
      :show="isWaterLineShow"
      :current-mark-info="currentMonitor"
      @close="waterWarningModalClose"
    />
  </div>
</template>

<style scoped lang="scss">
.monitoring-parameters {
  width: 310px;
  height: 40px;
  background-color: #16181b;
  position: fixed;
  top: 58px;
  right: 381px;
  border-radius: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  .el-input__inner {
    padding: 0;
  }
  .el-select {
    width: 210px;
  }
  .el-input,
  .el-select {
    height: 40px;
  }
}
</style>
