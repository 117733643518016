var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.leftMenuAction != 1)?_c('div',{staticClass:"silderBar",style:({ width: _vm.width, top: _vm.isIframe ? '10px' : '' })},[(_vm.isShow)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginState && _vm.active !== -1),expression:"loginState && active !== -1"}],staticClass:"zhedie1",on:{"click":_vm.changewidth}},[_c('i',{staticClass:"iconfont icon-guanbi1",class:{ 'icon-guanbi1': _vm.isShow, 'icon-zhedie1': !_vm.isShow },attrs:{"id":"change_icon"}})]):_vm._e(),_c('div',{staticClass:"silderBar_top",style:('width:' + _vm.width)}),(_vm.curNavId !== null && _vm.leftMenuAction === 5)?_c('div',{staticClass:"total-bar"},_vm._l((_vm.totalBarData),function(item,i){return _c('span',{key:i,staticClass:"total-item"},[_vm._v(_vm._s(item.title)+": "),_c('span',{staticClass:"total-bar__num"},[_vm._v(_vm._s(item.value))]),_vm._v(" "+_vm._s(item.unit)+" ")])}),0):_vm._e(),_c('div',{ref:"navRef",staticClass:"silderBar_nav"},[_c('div',{staticClass:"silderBar_box_top"},[_c('div',{staticClass:"up-arrow-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.scrollTop && _vm.appShowCount < _vm.appProjectList.length),expression:"scrollTop && appShowCount < appProjectList.length"}],staticClass:"up-arrow",on:{"click":_vm.upArrowClick}},[_c('div',{staticClass:"up-triangle"})])]),_c('div',{ref:"appRef",staticClass:"application-list-wrap",class:{ preventClick: _vm.isPreventClick_ },style:({ height: _vm.appListHeight + 'px' })},[(_vm.leftMenuAction === 4)?_c('div',_vm._l((_vm.appProjectList),function(item,index){return _c('div',{key:item.id},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.name,"placement":"right"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionFn({ pid: 453 })),expression:"permissionFn({ pid: 453 })"}],class:{
                    item: true,
                    item_icon: true,
                    'item-active': _vm.currentAppIndex === index,
                  },on:{"click":function($event){return _vm.appSelectEvent(index)}}},[_c('img',{attrs:{"crossorigin":"anonymous","src":item.icon}})])])],1)}),0):(_vm.leftMenuAction === 2 && _vm.loginState)?_c('div',[(_vm.loginState)?_c('div',{ref:"sliderBar_bottom",staticClass:"sliderBar_bottom"},_vm._l((_vm.silderBar_bottom),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionFn(item)),expression:"permissionFn(item)"},{name:"pms",rawName:"v-pms",value:(['飞行应用', item.name]),expression:"['飞行应用', item.name]"}],key:index,staticClass:"item item_icon_top",class:{
                  active: _vm.openLeftNavSilder && _vm.currentOptionIndex === index,
                },on:{"click":function($event){return _vm.goMeg(item.id + 1, index)}}},[(item.icon)?_c('i',{staticClass:"item_uav",class:item.icon,style:({ fontSize: item.fontSize })}):_c('img',{style:({ opacity: '0.7', width: '24px', height: '24px' }),attrs:{"src":item.img}}),_c('div',{staticClass:"item_hover_bottom"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e()]):(_vm.leftMenuAction === 5)?_c('div',_vm._l((_vm.listNav),function(item,index){return _c('div',{key:item.id},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.propertyName,"placement":"right"}},[_c('div',{class:{
                    item: true,
                    'item-icon': true,
                    'item-active': _vm.currentAppIndex === index,
                  },on:{"click":function($event){return _vm.handleClick(item, index)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.isShow),expression:"item.isShow"}],attrs:{"crossorigin":"anonymous","src":item.propertyUrl}})])])],1)}),0):(_vm.leftMenuAction === 6)?_c('div',_vm._l((_vm.application_3D),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionFn(item)),expression:"permissionFn(item)"}],key:item.id},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.name,"placement":"right"}},[_c('div',{class:{
                    item: true,
                    'item-icon': true,
                    'item-active': _vm.currentAppIndex === item.id,
                  },on:{"click":function($event){return _vm.application_3DFn(item, _vm.currentAppIndex == item.id)}}},[_c('img',{attrs:{"src":item.img}})])])],1)}),0):(_vm.leftMenuAction === 3 && _vm.loginState)?_c('div',[(_vm.loginState)?_c('div',{ref:"sliderBar_bottom",staticClass:"sliderBar_bottom"},_vm._l((_vm.applicationsList),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionFn(item)),expression:"permissionFn(item)"}],key:index,staticClass:"item item_icon_top",class:{
                  active: _vm.openLeftNavSilder && _vm.currentOptionIndex === index,
                },on:{"click":function($event){return _vm.goToControl(item.type, item.id, item, index)},"mouseover":function($event){item.isShow = false},"mouseout":function($event){item.isShow = true}}},[_c('img',{attrs:{"src":item.img}}),_c('div',{staticClass:"item_hover_bottom"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e()]):(_vm.subplatFormStatus)?_c('div',[(_vm.loginState && _vm.subplatFormActive === 1)?_c('div',{ref:"sliderBar_bottom",staticClass:"sliderBar_bottom"},_vm._l((_vm.subplatFormTaskList),function(item,index){return _c('div',{key:index,staticClass:"item item_icon_top",class:{
                  active: _vm.openLeftNavSilder && _vm.currentOptionIndex === index,
                },on:{"click":function($event){return _vm.goMeg(item.id + 1, index)}}},[_c('img',{staticStyle:{"width":"24px","opacity":"0.7"},attrs:{"src":item.img}}),_c('div',{staticClass:"item_hover_bottom"},[_vm._v(_vm._s(item.name))])])}),0):(_vm.loginState && _vm.subplatFormActive === 3)?_c('div',[(_vm.loginState)?_c('div',{ref:"sliderBar_bottom",staticClass:"sliderBar_bottom"},_vm._l((_vm.silderBar_bottom),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionFn(item)),expression:"permissionFn(item)"},{name:"pms",rawName:"v-pms",value:(['飞行应用', item.name]),expression:"['飞行应用', item.name]"}],key:index,staticClass:"item item_icon_top",class:{
                    active: _vm.openLeftNavSilder && _vm.currentOptionIndex === index,
                  },on:{"click":function($event){return _vm.goMeg(item.id + 1, index)}}},[(item.icon)?_c('i',{staticClass:"item_uav",class:item.icon,style:({ fontSize: item.fontSize })}):_c('img',{style:({ opacity: '0.7', width: '24px', height: '24px' }),attrs:{"src":item.img}}),_c('div',{staticClass:"item_hover_bottom"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e()]):(_vm.loginState && _vm.subplatFormActive === 2)?_c('div',{ref:"sliderBar_bottom",staticClass:"sliderBar_bottom"},_vm._l((_vm.subplatFormFlightManagementList),function(item,index){return _c('div',{key:index,staticClass:"item item_icon_top",class:{
                  active: _vm.openLeftNavSilder && _vm.currentOptionIndex === index,
                },on:{"click":function($event){return _vm.goToControl(item.type, item.id, item, index)}}},[(item.icon)?_c('i',{class:item.icon,style:({ fontSize: item.fontSize })}):_c('img',{style:({ width: '24px', height: '24px' }),attrs:{"src":item.img}}),_c('div',{staticClass:"item_hover_bottom"},[_vm._v(_vm._s(item.name))])])}),0):(_vm.loginState && _vm.subplatFormActive === 4)?_c('div',_vm._l((_vm.application_3D),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionFn(item)),expression:"permissionFn(item)"}],key:item.id},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.name,"placement":"right"}},[_c('div',{class:{
                      item: true,
                      'item-icon': true,
                      'item-active': _vm.currentAppIndex === item.id,
                    },on:{"click":function($event){return _vm.application_3DFn(item, _vm.currentAppIndex == item.id)}}},[_c('img',{attrs:{"src":item.img}})])])],1)}),0):_vm._e()]):_vm._e()]),_c('div',{staticClass:"down-arrow-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.scrollTop !=
                (_vm.appProjectList.length - _vm.appShowCount) * _vm.appItemHeight &&
              _vm.appShowCount < _vm.appProjectList.length
            ),expression:"\n              scrollTop !=\n                (appProjectList.length - appShowCount) * appItemHeight &&\n              appShowCount < appProjectList.length\n            "}],staticClass:"down-arrow",on:{"click":_vm.downArrowClick}},[_c('div',{staticClass:"down-triangle"})])])]),(_vm.loginState)?_c('div',{ref:"sliderBar_bottom",staticClass:"sliderBar_bottom"}):_vm._e()]),_c('FlightPopup2',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}]})],1):_vm._e(),(_vm.leftMenuAction === 1 && !_vm.subplatformPermission)?_c('videoWall'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }