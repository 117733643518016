<template>
  <div class="content-head">
    <div class="content-head-logo">
      <img :src="headData.img" />
    </div>
    <div class="content-head-info">
      <div class="title">{{ headData.title }}</div>
      <div class="meta">{{ headData.meta }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentHead',
  props: ['headData'],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.content-head {
  padding: 37px 0 30px 39px;
  border-bottom: 1px solid rgba(44, 49, 59, 0.18);
  display: flex;
  //align-items: center;
  &-logo {
    img {
      width: 96px;
      height: 96px;
    }
    margin-right: 20px;
  }
  &-info {
    .title {
      font-size: 35px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #283755;
      margin-bottom: 4px;
      line-height: 49px;
    }
    .meta {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4c576c;
      line-height: 28px;
    }
  }
}
</style>
