<template>
  <div>
    <div v-if="leftMenuAction != 1"  class="silderBar" :style="{ width: width, top: isIframe ? '10px' : '' }">
      <div
        v-show="loginState && active !== -1"
        v-if="isShow"
        class="zhedie1"
        @click="changewidth"
      >
        <i
          id="change_icon"
          class="iconfont icon-guanbi1"
          :class="{ 'icon-guanbi1': isShow, 'icon-zhedie1': !isShow }"
        />
      </div>
      <div class="silderBar_top" :style="'width:' + width" />
      <div v-if="curNavId !== null && leftMenuAction === 5" class="total-bar">
        <span v-for="(item, i) in totalBarData" :key="i" class="total-item"
          >{{ item.title }}:
          <span class="total-bar__num">{{ item.value }}</span>
          {{ item.unit }}
        </span>
      </div>
      <!-- v-if="vip == '行业版'|| vip =='测试行业版'" -->
      <div ref="navRef" class="silderBar_nav">
        <div class="silderBar_box_top">
          <div class="up-arrow-wrap">
            <div
              v-show="scrollTop && appShowCount < appProjectList.length"
              class="up-arrow"
              @click="upArrowClick"
            >
              <div class="up-triangle" />
            </div>
          </div>
          <div
            ref="appRef"
            class="application-list-wrap"
            :class="{ preventClick: isPreventClick_ }"
            :style="{ height: appListHeight + 'px' }"
          >
            <!-- 行业应用 -->
            <div v-if="leftMenuAction === 4">
              <div v-for="(item, index) in appProjectList" :key="item.id">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="right"
                >
                  <div
                    v-show="permissionFn({ pid: 453 })"
                    :class="{
                      item: true,
                      item_icon: true,
                      'item-active': currentAppIndex === index,
                    }"
                    @click="appSelectEvent(index)"
                  >
                    <img crossorigin="anonymous" :src="item.icon" />
                  </div>
                </el-tooltip>
              </div>
            </div>
            <!-- 飞行应用 -->
            <div v-else-if="leftMenuAction === 2 && loginState">
              <div
                v-if="loginState"
                ref="sliderBar_bottom"
                class="sliderBar_bottom"
              >
                <div
                  v-for="(item, index) in silderBar_bottom"
                  v-show="permissionFn(item)"
                  :key="index"
                  v-pms="['飞行应用', item.name]"
                  class="item item_icon_top"
                  :class="{
                    active: openLeftNavSilder && currentOptionIndex === index,
                  }"
                  @click="goMeg(item.id + 1, index)"
                >
                  <i
                    v-if="item.icon"
                    class="item_uav"
                    :class="item.icon"
                    :style="{ fontSize: item.fontSize }"
                  />
                  <img
                    v-else
                    :src="item.img"
                    :style="{ opacity: '0.7', width: '24px', height: '24px' }"
                  />
                  <div class="item_hover_bottom">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <!-- 资源管理 -->
            <div v-else-if="leftMenuAction === 5">
              <div v-for="(item, index) in listNav" :key="item.id">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.propertyName"
                  placement="right"
                >
                  <div
                    :class="{
                      item: true,
                      'item-icon': true,
                      'item-active': currentAppIndex === index,
                    }"
                    @click="handleClick(item, index)"
                  >
                    <img
                      v-show="item.isShow"
                      crossorigin="anonymous"
                      :src="item.propertyUrl"
                    />
                  </div>
                </el-tooltip>
              </div>
            </div>
            <!-- 三维应用 -->
            <div v-else-if="leftMenuAction === 6">
              <div
                v-for="(item, index) in application_3D"
                v-show="permissionFn(item)"
                :key="item.id"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="right"
                >
                  <div
                    :class="{
                      item: true,
                      'item-icon': true,
                      'item-active': currentAppIndex === item.id,
                    }"
                    @click="application_3DFn(item, currentAppIndex == item.id)"
                  >
                    <img :src="item.img" />
                    <!-- <div v-show="!item.isShow" :class="item.icon"></div> -->
                  </div>
                </el-tooltip>
              </div>
            </div>
            <!-- 飞行管理 -->

            <div v-else-if="leftMenuAction === 3 && loginState">
              <div
                v-if="loginState"
                ref="sliderBar_bottom"
                class="sliderBar_bottom"
              >
                <div
                  v-for="(item, index) in applicationsList"
                  :key="index"
                  v-show="permissionFn(item)"
                  class="item item_icon_top"
                  :class="{
                    active: openLeftNavSilder && currentOptionIndex === index,
                  }"
                  @click="goToControl(item.type, item.id, item, index)"
                  @mouseover="item.isShow = false"
                  @mouseout="item.isShow = true"
                >
                  <!-- <i
                    v-show="item.isShow"
                    :class="item.icon"
                    :style="{ fontSize: item.fontSize }"
                  />
                  <img v-show="!item.isShow" :src="item.img" /> -->
                  <img :src="item.img" />
                  <div class="item_hover_bottom">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <!-- 子平台 -->
            <div v-else-if="subplatFormStatus">
              <!-- 任务管理 -->
              <div
                v-if="loginState && subplatFormActive === 1"
                ref="sliderBar_bottom"
                class="sliderBar_bottom"
              >
                <div
                  v-for="(item, index) in subplatFormTaskList"
                  :key="index"
                  class="item item_icon_top"
                  :class="{
                    active: openLeftNavSilder && currentOptionIndex === index,
                  }"
                  @click="goMeg(item.id + 1, index)"
                >
                  <img style="width: 24px; opacity: 0.7" :src="item.img" />
                  <div class="item_hover_bottom">{{ item.name }}</div>
                </div>
              </div>
              <!-- 飞行应用 -->
              <div v-else-if="loginState && subplatFormActive === 3">
                <div
                  v-if="loginState"
                  ref="sliderBar_bottom"
                  class="sliderBar_bottom"
                >
                  <div
                    v-for="(item, index) in silderBar_bottom"
                    v-show="permissionFn(item)"
                    :key="index"
                    v-pms="['飞行应用', item.name]"
                    class="item item_icon_top"
                    :class="{
                      active: openLeftNavSilder && currentOptionIndex === index,
                    }"
                    @click="goMeg(item.id + 1, index)"
                  >
                    <i
                      v-if="item.icon"
                      class="item_uav"
                      :class="item.icon"
                      :style="{ fontSize: item.fontSize }"
                    />
                    <img
                      v-else
                      :src="item.img"
                      :style="{ opacity: '0.7', width: '24px', height: '24px' }"
                    />
                    <div class="item_hover_bottom">{{ item.name }}</div>
                  </div>
                </div>
              </div>
              <!-- 飞行管理 -->
              <div
                v-else-if="loginState && subplatFormActive === 2"
                ref="sliderBar_bottom"
                class="sliderBar_bottom"
              >
                <div
                  v-for="(item, index) in subplatFormFlightManagementList"
                  :key="index"
                  class="item item_icon_top"
                  :class="{
                    active: openLeftNavSilder && currentOptionIndex === index,
                  }"
                  @click="goToControl(item.type, item.id, item, index)"
                >
                  <i
                    v-if="item.icon"
                    :class="item.icon"
                    :style="{ fontSize: item.fontSize }"
                  />
                  <img
                    v-else
                    :src="item.img"
                    :style="{ width: '24px', height: '24px' }"
                  />
                  <div class="item_hover_bottom">{{ item.name }}</div>
                </div>
              </div>
              <!-- 三维应用 -->
              <div v-else-if="loginState && subplatFormActive === 4">
                <div
                  v-for="item in application_3D"
                  v-show="permissionFn(item)"
                  :key="item.id"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="right"
                  >
                    <div
                      :class="{
                        item: true,
                        'item-icon': true,
                        'item-active': currentAppIndex === item.id,
                      }"
                      @click="
                        application_3DFn(item, currentAppIndex == item.id)
                      "
                    >
                      <img :src="item.img" />
                      <!-- <div v-show="!item.isShow" :class="item.icon"></div> -->
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="down-arrow-wrap">
            <div
              v-show="
                scrollTop !=
                  (appProjectList.length - appShowCount) * appItemHeight &&
                appShowCount < appProjectList.length
              "
              class="down-arrow"
              @click="downArrowClick"
            >
              <div class="down-triangle" />
            </div>
          </div>
        </div>

        <div v-if="loginState" ref="sliderBar_bottom" class="sliderBar_bottom">
          <!-- <div
              v-for="(item, index) in silderBar_bottom"
              :key="index"
              v-pms="[item.name]"
              class="item item_icon_top"
              :class="{active: openLeftNavSilder && currentOptionIndex === index}"
              @click="goMeg(index+1)"
          >
            <i :class="item.icon" :style="{fontSize: item.fontSize}" />
            <div
                class="
                 item_hover_bottom"
            >{{ item.name }}
            </div>
          </div> -->
        </div>
      </div>
      <FlightPopup2 v-show="isShow" />
    </div>
    <videoWall v-if="leftMenuAction === 1 && !subplatformPermission" />
  </div>
</template>

<script>
import videoWall from "@/views/videoWall";
import Popup from "../../header/methods/popup";
import { mapMutations, mapState } from "vuex";
import { Control_API, projectAPI } from "@/api";
import FlightPopup2 from "@/views/uavApplications/components/flight-popup2";
import imgUav from "@/assets/headerIcon/icon_uav.svg";
import imgEagleNest from "@/assets/0618Icons/eagleNest.png";
import imgEagleNestTask from "@/assets/image/icons/eagle_nest_task.png";
import eagle_nest_application from "@/assets/image/icons/eagle_nest_application.png";
import uav_application from "@/assets/image/icons/uav_application.png";
import uav_task from "@/assets/image/icons/uav_task.png";
import imgUAV from "@/assets/headerIcon/uav.png";
import imgFlyer from "@/assets/headerIcon/flyer.png";
import imgProject from "@/assets/headerIcon/project.png";
import imgTask from "@/assets/image/icons/uav.png";
import imgFlight from "@/assets/0618Icons/flight.webp";
import img1 from "@/assets/0618Icons/航线.png";
import img2 from "@/assets/0618Icons/空域管理.png";
import img3 from "@/assets/0618Icons/飞行日志.png";
import imgShow from "@/assets/headerIcon/show.png";
import imgTrain from "@/assets/headerIcon/train.png";
import imgService from "@/assets/headerIcon/service.png";
import { Tg } from "@/api";
import ProjectJson from "@/data/project.json";
import { permissionIds } from "@/utils/permission";
const minWidth = "58px"; // 收起时的宽度
const maxWidth = "439px"; // 展开时的宽度
const GeoJson = {
  // uav: UavJson,
  // flyer: FlyerJson,
  // service: serviceJson,
  // train: trainJson,
  // show: showJson
  uav: [],
  flyer: [],
  service: [],
  train: [],
  show: [],
  download: [],
  project: ProjectJson,
};
const data_srouce = {
  uav: null,
  flyer: null,
  service: null,
  train: null,
  show: null,
  project: null,
  download: null,
};
export default {
  name: "NavSlider",
  components: { FlightPopup2, videoWall },
  props: {},
  inject: ["change_active", "get_flight_info"],
  data() {
    return {
      subplatformPermission: localStorage.getItem("subplatform-permission"),
      vip: "",
      isList: false,
      subPlatforms: [
        {
          icon_top: "iconfont icon-hangye-gongan",
          name: "智慧公安",
          id: 10000,
          url: (token) => `http://39.103.170.213/javaweb/#/tm?token=${token}`,
        },
        {
          icon_top: "iconfont icon-hangye-yingji",
          name: "智慧应急",
          id: 80000,
          url: (token) =>
            "http://112.124.32.65/#/emergency" /* 'https://jianzai.mmcuav.cn/disater/#/order' */ /* `http://112.124.32.65/#/tm?token=${token}` */,
        },
        {
          icon_top: "iconfont icon-hangye-chengguan",
          children: [
            {
              name: "智慧网格",
              url: (token) => `http://webmspace.mmcuav.cn/#/tm?token=${token}`,
              id: 20000,
            },
            {
              name: "智慧查违",
              url: (token) =>
                `http://vio.henengsoft.com:8118/#/user/login?userCode=${token}`,
              id: 40005,
            },
          ],
        },
        {
          icon_top: "iconfont icon-hangye-dianli",
          name: "智慧电力",
          id: 40002,
          url: (token) =>
            `http://elec.rtk.mmcuav.cn/electric-education/#/tm?token=${token}`,
        },
        {
          icon_top: "iconfont icon-hangye-shiyou",
          name: "智慧石油",
          id: 40003,
          url: (token) =>
            `http://pipe.rtk.mmcuav.cn/electric-education/#/tm?token=${token}`,
        },
      ],
      // import imgEagleNestTask from "@/assets/image/icons/eagle_nest_task.png";
      // import eagle_nest_application from "@/assets/image/icons/eagle_nest_application.png";
      // import uav_application from "@/assets/image/icons/uav_application.png";
      // import uav_task from "@/assets/image/icons/uav_task.png";
      silderBar_bottom: [
        {
          id: 0,
          // icon: "iconfont icon-wurenji",
          img: uav_application,
          pid: 455,
          // icon: "iconfont icon-wurenji",
          name: this.$t('navibar.uavApplication'),
          fontSize: "20px",
        },

        {
          id: 12,
          name: this.$t('navibar.nestApplicaition'),
          fontSize: "16px",
          img: eagle_nest_application,
          pid: 554,
        },
      ],
      appProjectList: [], // 项目应用数据
      projectList: [], // 项目数据
      cloneProjectList: [], // 拷贝一份
      currentAppIndex: -1, // 当前选中应用下标
      currentOptionIndex: -1, // 当前操作下标
      appShowCount: 9, // 显示应用个数
      scrollTop: 0, // 距离顶部高度
      appItemHeight: 52, // 单个应用高度+下边距
      isElectron: false,
      loginState: false,
      isPath: true,
      appListHeight: 0, // 应用列表展示高度
      listNav: [
        //   {
        //     name: "无人机",
        //     icon: "iconfont  icon-wurenji",
        //     id: 3,
        //     pid: 469,
        //     value: "uav",
        //     isShow: false,
        //     popups: [],
        //     img: imgUAV,
        //   },
        //   {
        //     name: "飞手",
        //     pid: 473,
        //     icon: "iconfont  icon-feishou",
        //     id: 4,
        //     value: "flyer",
        //     isShow: false,
        //     popups: [],
        //     img: imgFlyer,
        //   },
        //   {
        //     name: "服务网点",
        //     pid: 471,
        //     icon: "iconfont  icon-fuwuwangdian",
        //     id: 5,
        //     value: "service",
        //     isShow: false,
        //     popups: [],
        //     img: imgService,
        //   },
        //   {
        //     name: "培训网点",
        //     pid: 475,
        //     icon: "iconfont  icon-peixunwangdian",
        //     id: 6,
        //     value: "train",
        //     isShow: false,
        //     popups: [],
        //     img: imgTrain,
        //   },
        //   {
        //     name: "展示网点",
        //     pid: 477,
        //     icon: "iconfont  icon-zhanshiwangdian",
        //     id: 7,
        //     value: "show",
        //     isShow: false,
        //     popups: [],
        //     img: imgShow,
        //   },
        //   {
        //     name: "运行项目",
        //     pid: 467,
        //     icon: "iconfont  icon-yunhangxiangmu",
        //     id: 8,
        //     value: "project",
        //     isShow: false,
        //     popups: [],
        //     img: imgProject,
        //   },
      ],

    //   BaseMap: "基础地图",
    // MultiplePhaseContrast: "多期对比",
    // RegionalAnalysis: "区域分析",
    // PanoramicDisplay : "全景展示"
      
      application_3D: [
        {
          name: this.$t('navibar.BaseMap'), //基础地图
          id: 116,
          pid: 568,
          value: "map",
          isShow: false,
          popups: [],
          img: require("@/assets/image/icons/jcdt.png"),
        },
        {
          name: this.$t('navibar.MultiplePhaseContrast'),  //"多期对比",
          pid: 569,
          icon: "iconfont  icon-zhanshiwangdian",
          id: 117,
          value: "applicationAnalysis",
          isShow: false,
          popups: [],
          img: require("@/assets/image/icons/yyfx.png"),
        },
        {
          name: this.$t('navibar.RegionalAnalysis'), //  "区域分析",
          pid: 570,
          icon: "iconfont  icon-zhanshiwangdian",
          id: 118,
          value: "regionAnalysis",
          isShow: false,
          popups: [],
          img: require("@/assets/image/icons/sjtj.png"),
        },
        {
          name: this.$t('navibar.PanoramicDisplay'), //'全景展示',
          pid: 570,
          icon: 'iconfont  icon-zhanshiwangdian',
          id: 120,
          value: 'regionAnalysis',
          isShow: false,
          popups: [],
          img: require('@/assets/image/icons/yyfx.png')
        }
      ],
      applicationsList: [
        // { id: 9, icon: "iconfont icon-yunhangxiangmu", name: "三维应用", fontSize: "20px", isShow: true, img: imgProject },
        {
          id: 1,
          pid: 463,
          type: "goMeg",
          icon: "iconfont icon-hangxian",
          name:  this.$t('navibar.RouteManagement'),//"航线管理",
          fontSize: "20px",
          isShow: true,
          img: img1,
        },
        {
          id: 11,
          pid: 546,
          type: "goMeg",
          icon: "iconfont icon-hangxian",
          name:  this.$t('navibar.AirspaceManagement'),//"空域管理",
          fontSize: "20px",
          isShow: true,
          img: img2,
        },
        {
          id: 10,
          pid: 550,
          type: "goMeg",
          icon: "iconfont icon-hangxian",
          name: this.$t('navibar.FlightLog'),//"飞行日志",
          fontSize: "20px",
          isShow: true,
          img: img3,
        },
      ],
      subplatFormTaskList: [
        {
          id: 9,
          name: "飞行任务",
          fontSize: "20px",
          isShow: true,
          img: uav_task,
        },
        {
          id: 13,
          name: "鹰巢任务",
          fontSize: "20px",
          isShow: true,
          img: imgEagleNestTask,
        },
      ],
      subplatFormFlightManagementList: [
        {
          id: 1,
          type: "goMeg",
          name: "航线管理",
          fontSize: "20px",
          isShow: true,
          img: img1,
        },
        {
          id: 11,
          type: "goMeg",
          name: "空域管理",
          fontSize: "20px",
          isShow: true,
          img: img2,
        },
        {
          id: 10,
          type: "goMeg",
          name: "飞行日志",
          fontSize: "20px",
          isShow: true,
          img: img3,
        },
        {
          id: 14,
          type: "goMeg",
          name: "预警记录",
          fontSize: "20px",
          isShow: true,
          img: require("@/assets/image/icons/early_warning.png"),
        },

        // {
        //   id: 9,
        //   type: 'goApplication',
        //   icon: 'iconfont icon-yunhangxiangmu',
        //   name: '三维应用',
        //   fontSize: '20px',
        //   isShow: true
        // }
      ],
      itemList: [],
      curNavId: "",
      totalBarData: [],
    };
  },
  provide() {
    return {
      getAppProjectList: () => this.appProjectList,
      getProjectDataList: () => this.projectList,
    };
  },
  computed: {
    ...mapState([
      "openLeftNavSilder",
      "active",
      "userInfo",
      "leftMenuAction",
      "isIframe",
    ]),
    ...mapState("subplatform", ["subplatFormStatus", "subplatFormActive"]),
    isShow: {
      get: function () {
        return this.openLeftNavSilder;
      },
      set: function (v) {
        this.SET_OPEN_LEFT_NAV_SILDER(v);
      },
    },
    isPreventClick_() {
      return this.$store.state.uavApplications.isPreventClick;
    },
    width() {
      return this.openLeftNavSilder ? maxWidth : minWidth;
    },
    subPlatformsByID() {
      const obj = {};
      (function loop(list) {
        list.forEach((item) => {
          if (item.children) {
            loop(item.children);
          } else {
            obj[item.id] = item;
          }
        });
      })(this.subPlatforms);
      return obj;
    },
  },
  // 生命周期 - 创建完成（访问当前this实例）
  async created() {
    this.getAppAndProject();
    if (this.$route.query.active) {
      this.SET_LEFT_MENU_ACTION(Number(this.$route.query.active));
    } else if (localStorage.getItem("subplatform-permission")) {
      if (this.$route.path === "/uavApplications") {
        // 默认选中无人机列表界面
        this.goMeg(1, 0);
        this.$store.commit("subplatform/SET_SUBPLAT_FORM_ACTIVE", 3);
      } else if (this.$route.params.active) {
        this.$store.commit(
          "subplatform/SET_SUBPLAT_FORM_ACTIVE",
          this.$route.params.active
        );
      } else {
        // 先显示飞行任务
        this.$store.commit("subplatform/SET_SUBPLAT_FORM_ACTIVE", 1);
        this.$store.commit("CHANGE_ACTYIVE", 10);
      }
      // 左侧模块-左侧菜单栏 0为子平台
      this.$store.commit("SET_LEFT_MENU_ACTION", 0);
      // 子平台显示隐藏
      this.$store.commit("subplatform/SET_SUBPLAT_FORM_STATUS", true);
    } else if (this.$route.path === "/uavApplications") {
      this.SET_LEFT_MENU_ACTION(2);
    } else if (this.$route.params.active) {
      this.SET_LEFT_MENU_ACTION(this.$route.params.active);
    } else {
      this.SET_LEFT_MENU_ACTION(1);
    }
    if (this.leftMenuAction === 1) {
      // this.appSelectEvent(0);
    } else if (this.leftMenuAction === 2) {
      this.goMeg(1);
    } else if (this.leftMenuAction === 5) {
      // this.handleClick(this.listNav[0], 0);
    }
    // this.change_active(0);
    // this.isShow = false;
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.click_billboard();
    this.getBrowserIsElctron();
    this.getPageBasicPropertyTypes();
    JSON.parse(window.localStorage.getItem("user_info"));
    // this.vip = data?.rolePermission[0]?.name;
    this.loginState = !!window.localStorage.getItem("tmj_token");
    this.$bus.$on("searchProjectByName", (text) => {
      if (text) {
        this.projectList = this.projectList.filter((v) =>
          v.projectName.includes(text)
        );
      } else {
        this.projectList = this.cloneProjectList;
      }
    });
    this.$nextTick(() => {
      const sliderDom = this.$refs.sliderBar_bottom;
      const navDom = this.$refs.navRef;
      const height = navDom.offsetHeight - sliderDom.offsetHeight - 87;
      this.appShowCount = ~~(height / this.appItemHeight);
      this.appListHeight = this.appShowCount * this.appItemHeight + 10;
    });
  },
  methods: {
    ...mapMutations([
      "SET_OPEN_LEFT_NAV_SILDER",
      "SET_CESIUM_STATE",
      "SET_LEFT_MENU_ACTION",
      "SET_SIDEBAR_STATUS",
    ]),
    // 资源类型列表
    async getPageBasicPropertyTypes() {
      const res = await projectAPI.getPageBasicPropertyTypes();
      if (res.code == 200) {
        if (res.data.items && res.data.items.length > 0) {
          this.listNav = res.data.items.map((item) => {
            return {
              ...item,
              isShow: true,
            };
          });
        }
      }
    },
    permissionFn(item) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === item.pid);
      } else {
        return false;
      }
    },
    formatSeconds(value) {
      let result = parseInt(value);
      const h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      const m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      const s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);
      result = `${h}:${m}:${s}`;
      return result;
    },
    click_billboard() {
      const viewer = window.viewer;

      const scene = viewer.scene;
      const _this = this;

      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

      handler.setInputAction(async (movement) => {
        const pickedLabel = viewer.scene.pick(movement.endPosition);
        // let pickedLabel = movement;
        var pick = new Cesium.Cartesian2(
          movement.endPosition.x,
          movement.endPosition.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          // let cartesian = viewer.scene.globe.pick(
          //   viewer.camera.getPickRay(pick),
          //   viewer.scene
          // );
          var cartesian = viewer.scene.pickPosition(movement.endPosition);
          // let cartesian = pick;
          if (pickedLabel.id) {
            // console.log("pickedLabel....", pickedLabel);
            try {
              const data = JSON.parse(pickedLabel.id._id);
              // let isRePopup = false;
              // console.log("data....", data);
              if (!_this.point_id) {
                _this.point_id = data.id;
                _this.isRePopup = true;
              } else if (_this.point_id != data.id) {
                _this.isRePopup = true;
                // _this.point_id = 0;
              }
              if (data.entityType == "header_point") {
                _this.listNav.forEach((item) => {
                  if (item.isShow) {
                    if (!_this.isShowPopup) {
                      _this.isShowPopup = true;
                      item.popups.forEach((v) => {
                        v.close();
                      });
                      if (_this.isRePopup) {
                        // if (window.localStorage.getItem("user_info")) {
                        //   console.log("item....", item);
                        //   if (item.id == 1) {
                        //     item.popups.push(
                        //       new PopupNew({
                        //         viewer: viewer,
                        //         geometry: cartesian,
                        //         data: data,
                        //         del_real: data.isShow,
                        //       })
                        //     );
                        //   } else {
                        //     item.popups.push(
                        //       new Popup({
                        //         viewer: viewer,
                        //         geometry: cartesian,
                        //         data: data,
                        //         del_real: data.isShow,
                        //       })
                        //     );
                        //   }
                        // } else {
                        item.popups.push(
                          new Popup({
                            viewer: viewer,
                            geometry: cartesian,
                            data: data,
                            del_real: data.isShow,
                          })
                        );
                        // }
                      }
                    } else {
                      setTimeout(() => {
                        _this.isShowPopup = false;
                      }, 100);
                    }
                  }
                });
              }
            } catch (error) {}
          }
        } else {
          this.point_id = 0;
          _this.listNav.forEach((item) => {
            if (item.popups && item.popups.length > 0) {
              item.popups.forEach((v) => {
                v.close();
              });
            }
          });
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    },

    getRunProjectListSimpleList() {
      // console.log('getRunProjectListSimple')
      return new Promise(async (resolve, reject) => {
        const res = await Tg.getRunProjectListSimple({ accountId: 1 });
        if (res.code === 200) {
          const data = res.data;
          resolve(data);
        }
      });
    },
    closeList() {
      this.listNav.forEach((item, index) => {
        if (data_srouce[item.value]) {
          data_srouce[item.value].show = false;
        }
      });
      this.curNavId = null;
    },
    application_3DFn(item, isShow) {
      // 内容展示
      if (this.currentAppIndex == item.id) {
        this.isShow = !this.isShow;
      } else {
        this.isShow = true;
      }
      // 选中状态
      this.currentAppIndex = item.id;
      // 展示列表
      this.$store.commit("CHANGE_ACTYIVE", item.id);
    },
    // tab栏点击
    async handleClick(e, index) {
      this.change_active(1, 119);
      this.currentAppIndex = index;
      this.isShow = true;
      this.$store.commit("uavApplications/setState", {
        key: "getPageBasicPropertyInfosById",
        value: e.id,
      });
      // 获取资源列表
      const res = await projectAPI.getPageBasicPropertyInfos({
        propertyTypeId: e.id,
        currentPage: 1,
      });
      if (res.code == 200) {
        this.totalBarData = [
          {
            title: this.$t('navibar.Total'), //"总共",
            value: res.data.totalCount,
            unit: e.propertyName == "飞手" ? this.$t('navibar.unit') : "",
          },
        ];
      }
    },

    goToControl(type, id, item, index) {
      if (type === "goMeg") {
        // 显示
        this.goMeg(id + 1, index);
      } else if (type === "goApplication") {
        this.goApplication(item, index);
      }
    },
    goMeg(id, index) {
      this.SET_SIDEBAR_STATUS(0);
      this.$bus.$emit("closeSideList");
      if (id === 13) {
        // 进入鹰巢
        this.change_active(13, id);
      } else {
        this.change_active(1, id);
      }
      // 项目列表
      // this.$bus.$emit("closeListNav")
      // console.log('icon', icon);
      if (this.width === minWidth) {
        document.getElementById("change_icon");
        this.isShow = true;
      }
      this.currentOptionIndex = index;
      // this.currentAppIndex = -1;
    },
    goApplication(e, index) {
      this.change_active(1, e.id);
      // 项目列表
      // this.$bus.$emit("closeListNav")
      // console.log('icon', icon);
      if (this.width === minWidth) {
        document.getElementById("change_icon");
        this.isShow = true;
      }
      this.currentOptionIndex = index;
      this.currentAppIndex = -1;
    },
    changewidth() {
      var icon = document.getElementById("change_icon");
      if (this.width === minWidth) {
        icon.classList.remove("icon-zhedie1");
        icon.classList.add("icon-zhedie");
        if (this.currentAppIndex !== (0 | -1)) {
          this.handleClick(this.listNav[this.currentAppIndex - 2], 0);
        }
        this.isShow = true;
        /* this.$bus.$emit("handleZhedie", this.show);
        this.$bus.$emit("handleZhedie2", this.show) */
      } else {
        icon.classList.add("icon-zhedie1");
        icon.classList.remove("icon-zhedie");
        this.isShow = false;
        /* this.$bus.$emit("handleZhedie", this.show);
        this.$bus.$emit("handleZhedie2", this.show) */
      }
    },
    shoWList() {
      this.isList = !this.isList;
    },
    async getSonToken(id) {
      const tmj_token = window.localStorage.getItem("tmj_token");
      const loginInfo = {
        token: tmj_token,
      };

      loginInfo.appId = id;
      const res = await Control_API.login({ loginInfo });
      if (res.msg) {
        if (res.msg.indexOf("过期") !== -1) {
          this.isPath = false;
          this.$message({
            type: "error",
            message: "账号已过期，请联系管理员",
            duration: 2000,
          });
        } else {
          this.isPath = true;
        }
      }
      if (res.data) {
        return (
          res?.data?.token || res?.data?.result || res?.data["mmc-identity"]
        );
      }
    },
    async goToSon(id) {
      let url;
      const token = await this.getSonToken(id);
      // console.log('token', token);
      switch (id) {
        case 10000:
          // 跳转警航
          url = this.subPlatformsByID[id].url(this.userInfo.token);
          break;

        default:
          url = this.subPlatformsByID[id].url(token);
          break;
      }
      if (this.isPath) {
        this.goToNewWindow(url);
      }
    },
    getBrowserIsElctron() {
      const userAgent = navigator.userAgent;
      const isElectron = userAgent.includes("Electron");
      this.isElectron = isElectron;
    },
    goToNewWindow(url) {
      if (this.isElectron) {
        const electron = window.electron;
        const ipcRenderer = electron?.ipcRenderer;
        ipcRenderer && ipcRenderer.send("add", url);
      } else {
        window.open(url, "_blank");
      }
    },
    async getAppAndProject() {
      const res = await projectAPI.getAppAndProject();
      if (res.data && res.data.length !== 0) {
        this.appProjectList = res.data.filter((v) => v.type !== 3);
        this.$forceUpdate();
      }
    },
    // 应用选中
    appSelectEvent(index) {
      this.goMeg(0);
      this.projectList = this.appProjectList[index].projectModels;
      this.cloneProjectList = this.appProjectList[index].projectModels;
      this.currentAppIndex = index;
    },
    // 上移
    upArrowClick() {
      const appRef = this.$refs.appRef;
      if (this.scrollTop >= this.appItemHeight) {
        this.scrollTop -= this.appItemHeight;
        appRef.scrollTo({ top: this.scrollTop });
      }
    },
    // 下移
    downArrowClick() {
      const appRef = this.$refs.appRef;
      if (
        (this.scrollTop + this.appItemHeight) / this.appItemHeight !=
        this.projectList.length - this.appShowCount
      ) {
        this.scrollTop += this.appItemHeight;
        appRef.scrollTo({ top: this.scrollTop });
      }
    },
  },
  watch: {
    subplatFormStatus: function (newval) {
      this.subplatformPermission = localStorage.getItem(
        "subplatform-permission"
      );
    },
    subplatFormActive: function (newval) {
      console.log(this.active, "subplatFormActive");
    },
    leftMenuAction: function (newval) {
      if (newval === 1) {
        this.appSelectEvent(0);
      } else if (newval === 2) {
        this.goMeg(1);
      } else if (newval === 5) {
        // this.handleClick(this.listNav[0], 0);
      }
      if (newval !== 5) {
        for (const key in data_srouce) {
          viewer.dataSources.remove(data_srouce[key]);
        }
        // this.goMeg(1);
      }
      if (newval === 3) {
      } else if (newval === 4) {
        // 初始化状态
        this.currentAppIndex = 0;
        this.getAppAndProject();
      }
      // 初始化高度
      this.$nextTick(() => {
        const sliderDom = this.$refs.sliderBar_bottom;
        const navDom = this.$refs.navRef;
        const height = navDom.offsetHeight - sliderDom.offsetHeight - 87;
        this.appShowCount = ~~(height / this.appItemHeight);
        this.appListHeight = this.appShowCount * this.appItemHeight + 10;
      });
      this.isShow = false;
    },
  },
  beforeDestroy() {
    for (const key in data_srouce) {
      viewer.dataSources.remove(data_srouce[key]);
    }
  },
};
</script>
<style scoped lang="scss">
.silderBar {
  display: flex;
  transition: width 0.25s;
  -moz-transition: width 0.25s;
  /* Firefox 4 */
  transition: width 0.25s;
  /* Safari and Chrome */
  -o-transition: width 0.25s;
  /* Opera */
  position: relative;
  height: calc(100% - 60px);
  // width: 96px;
  position: absolute;
  top: 54px;
  left: 4px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;

  // box-shadow: inset 0 1px 4px 0 #2e63dc,
  //   inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
  //   inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  // border-radius: 8px;
  border: 1px solid #3bc1e5;

  // display: flex;
  // // align-items: center;
  // justify-content: center;
  .zhedie1 {
    position: absolute;
    // right：0px;
    // left: 105px;
    right: 10px;
    top: 10px;
    background: #313642;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    color: #9ca7c4;
    text-align: center;
    line-height: 24px;
  }

  .zhedie1:hover {
    color: #ffffff;
    cursor: pointer;
  }

  .silderBar_top {
    position: absolute;
    height: 10px;
    top: 0px; // width: 100%;
    padding-top: 4px;
    transition: width 0.2s;
    background-image: url("~@/assets/home/6eff87ed-0912-41d1-9281-a88dd28ec2e5.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .silderBar_nav {
    margin-top: 22px;
    text-align: center;
    line-height: 48px;
    // width: 98px;
    display: flex;
    justify-content: center;
    z-index: 1;

    //flex: 1;
    .silderBar_box_top {
      padding-left: 8px;
      display: flex;
      flex-direction: column;

      //align-items: center;
      .application-list-wrap {
        height: 322px;
        overflow: hidden;
        scrollbar-width: none;

        /* firefox */
        -ms-overflow-style: none;

        /* IE 10+ */
        &::-webkit-scrollbar {
          // chrome safari
          display: none;
        }

        .item_icon {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          margin: 10px 0;
          border: 1px solid #23364b;
          // box-shadow: 0px 0px 6px 0px #23364b;
          border-radius: 4px;

          .iconfont {
            font-size: 20px;
            color: rgba(255, 255, 255, 0.7);
          }

          .item_hover {
            width: 60px;
            font-size: 12px;
            position: absolute;
            background: #45537d;
            border-radius: 10px;
            left: 70px;
            top: 0px;
            display: none;
            z-index: 9999;
          }

          img {
            width: 20px;
            height: 20px;
          }
        }

        .item-icon {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          margin: 10px 0;
          border: 1px solid #23364b;
          // box-shadow: 0px 0px 6px 0px #23364b;
          border-radius: 4px;

          .iconfont {
            font-size: 20px;
            color: rgba(255, 255, 255, 0.7);
          }

          img {
            width: 20px;
            height: 20px;
          }
        }

        .item {
          width: 40px;
          height: 40px;
          color: rgba(255, 255, 255, 0.7);
          font-size: 20px !important;
          cursor: pointer;
        }

        .item-active {
          background: #45537d;
          border-radius: 10px;
          color: #ffff;
        }
      }

      .up-arrow-wrap,
      .down-arrow-wrap {
        width: 40px;
        height: 13px !important;
      }

      .up-arrow,
      .down-arrow {
        width: 40px;
        height: 13px !important;
        border-radius: 5px;
        border: 1px solid #314271;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        animation: fadeIn 0.618s ease-in-out;
        animation-fill-mode: forwards;

        .up-triangle {
          width: 0;
          height: 0;
          border-bottom: 5px solid #50abff;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }

        .down-triangle {
          width: 0;
          height: 0;
          border-top: 5px solid #50abff;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }
    }
  }

  .sliderBar_bottom {
    position: absolute;
    left: 8px;
    // width: 26px;
    display: flex;
    flex-direction: column;
    align-content: center;
    // background: #2e63dc;
    /* bottom: 41px; */
    top: 41px;

    .item_icon_top {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      margin: 6px 0;

      .item_hover_bottom {
        position: absolute;
        left: 80px;
        top: 0;
        // width: 60px;
        background: #45537d;
        font-size: 12px;
        border-radius: 10px;
        white-space: nowrap;
        padding: 0 8px;
        display: none;
      }
    }

    .item {
      color: rgba(255, 255, 255, 0.7);
      width: 40px;
      height: 40px;
      cursor: pointer;

      /* &:hover,
      &.active {
        color: #ffff;
        cursor: pointer;
        background: #45537d;
        border-radius: 10px;
        z-index: 9999;
      } */
      &:hover,
      &.active {
        background: url("~@/assets/0618Icons/Rectangle-border1.png") no-repeat;
        background-size: contain;
      }

      &:hover {
        .item_hover_bottom {
          display: block;
        }
      }
    }
  }
}

.icon-static {
  display: block;

  &:hover {
    display: none;
  }
}

.icon-hover {
  display: none;

  &:hover {
    display: block;
  }
}

.item_icon_top,
.item_icon {
  &:hover {
    .iconfont {
      &::before {
        content: "";
        height: 24px;
        width: 24px;
        display: block;
        background-size: 100%;
      }
    }

    .icon-hangye-gongan {
      &::before {
        background: url("~@/assets/0618Icons/ga.webp");
      }
    }

    .icon-hangye-yingji {
      &::before {
        background: url("~@/assets/0618Icons/yj.webp");
      }
    }

    .icon-hangye-dianli {
      &::before {
        background: url("~@/assets/0618Icons/dl.webp");
      }
    }

    .icon-hangye-shiyou {
      &::before {
        background: url("~@/assets/0618Icons/sy.webp");
      }
    }

    .icon-hangye-chengguan {
      &::before {
        background: url("~@/assets/0618Icons/cg.webp");
      }
    }

    .icon-wurenji-xiangmuliebiao {
      &::before {
        background: url("~@/assets/0618Icons/project.webp");
      }
    }

    .icon-wurenji {
      &::before {
        /* background: url('~@/assets/0618Icons/task.webp'); */
        background: url("~@/assets/headerIcon/uav.png");
        /* import imgUAV from '@/assets/headerIcon/uav.png'; */
      }
    }

    .icon-hangxian {
      &::before {
        background: url("~@/assets/0618Icons/flight.webp");
      }
    }

    .icon-yunhangxiangmu {
      &::before {
        background: url("~@/assets/headerIcon/project.png");
      }
    }
  }

  .icon-wurenji-xiangmuliebiao {
    font-size: 19px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.total-bar {
  position: fixed;
  top: 56px;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 600px;
  line-height: 1;
  padding: 18px 0 11px;
  text-align: center;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #b3bbc5;
  letter-spacing: 0;
  font-weight: 400;

  .total-item {
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }

  .total-bar__num {
    font-size: 20px;
    color: #1eb9fa;
  }
}

/* @import url(); 引入css类 */
</style>
<style lang="scss">
.navsider-dropdown-menu {
  transform: translate(47px, -47px);
}
</style>
