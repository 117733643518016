<template>
  <div class="wih100 videoWallOsd" v-show="isInfoShow" :style="osdStyle">
    <div class="timeStr pa" ref="timeStr">
      {{ timeStr || "" }}
    </div>
    <!-- <div class="wsDataright pa cf">
      <div class="name">{{ $uav.hardId }}</div>
    </div> -->
    <div class="wsDataleft pa boottom10 left10" ref="wsDataleft">
      <div>
        {{$t('home.longitude')}}：{{ longitude || 0 }}
      </div>
      <div>{{$t('home.latitude')}}：{{ latitude || 0 }}</div>
      <div>
        {{$t('home.height')}}：
        {{ uavRealTimeData.gps.absoluteAlt ? uavRealTimeData.gps.absoluteAlt.toFixed(0) : "-"}}
      </div>
      <div>
        {{$t('home.speed')}}：
        {{ uavRealTimeData.velocityZ ? uavRealTimeData.velocityZ.toFixed(0) : "-"}}
      </div>
      <div>
        {{$t('home.direction')}}：
        {{uavRealTimeData.attitude? uavRealTimeData.attitude.yaw.toFixed(0): "-"}}
      </div>
    </div>
  </div>
</template>
<script>
import { newDate } from "@/utils/formatDate";
export default {
  data() {
    return {
      timeStr: "",
      timer: "",
      wsData: {},
    };
  },
  props: {
    osdStyle: {
      type: Object,
      default: () => {},
    },
    isInfoShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    longitude() {
      const locationCoordinate3D =
        this.$store.state.uavApplications.uavRealTimeData.locationCoordinate3D;
      if (locationCoordinate3D.longitude) {
        const longitude = parseFloat(locationCoordinate3D.longitude);
        if (!isNaN(longitude)) {
          return longitude.toFixed(3);
        }
      }
      return "-";
    },
    latitude() {
      const locationCoordinate3D =
        this.$store.state.uavApplications.uavRealTimeData.locationCoordinate3D;
      if (locationCoordinate3D.latitude) {
        const latitude = parseFloat(locationCoordinate3D.latitude);
        if (!isNaN(latitude)) {
          return latitude.toFixed(3);
        }
      }
      return "-";
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    // 实时数据
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timeStr = newDate();
    }, 1000);
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.videoWallOsd {
  position: absolute;
  left: 0;
  top: 0;
  .timeStr {
    top: 28px;
    font-size: 14px;
    color: #fff;
    right: 14px;
  }
  .wsDataleft {
    bottom: 45px;
    font-size: 15px;
    color: #fff;
    left: 3px;
  }
  .wsDataright {
    bottom: 40px;
    right: 10px;
  }
}
</style>
