<template>
  <div class="cpt-home-flight-popup">
    <div v-interact class="header">
      <span v-hover class="iconfont icon-guanbi" @click="change_active(0)" />
    </div>
    <div class="tabs">
      <span
        class="tab_box left"
        :class="{ active: this.$store.state.active == 1 }"
        @click="handle_tab_click(0)"
        >飞行任务</span
      >
      <span
        class="tab_box right"
        :class="{ active: this.$store.state.active == 2 }"
        @click="handle_tab_click(1)"
        >航线管理</span
      >
    </div>
    <FlightTaskList v-if="this.$store.state.active == 1" />
    <FlightLineList v-else ref="flightLineList" />
  </div>
</template>

<script>
import FlightLineList from './flight-line-list';
import FlightTaskList from './flight-task-list';

export default {
  inject: ['change_active'],
  components: {
    FlightLineList,
    FlightTaskList
  },
  data() {
    return {
      options: Object.freeze([
        { label: '待执行', value: 0 },
        { label: '执行中', value: 1 },
        { label: '已完成', value: 2 }
      ]),
      status: null
    };
  },

  methods: {
    handle_tab_click(e) {
      this.$store.state.active = e;
    },

    clear_airline_entitys() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.flightLineList.clear_airline_entitys();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-home-flight-popup {
  width: 687px;
  height: 556px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  position: absolute;
  top: 150px;
  left: 200px;
  border: 1px solid #3bc1e5;

  .header {
    height: 32px;
    .iconfont {
      font-size: 24px;
      color: #b3bbc5;
      float: right;
      margin: 5px 5px 0 0;
    }
  }
  .tabs {
    height: 32px;
    background: #111111;
    box-sizing: border-box;
    .active {
      background: #1e2026;
    }
    .tab_box {
      display: inline-block;
      width: 80px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #c6ccd4;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        background: #1e2026;
      }
    }
  }
  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;
    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;
      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }
    &::v-deep {
      .el-input {
        width: 455px;
        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }
  .search_box.task {
    &::v-deep {
      .el-input {
        width: 211px;
        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }
}
</style>
