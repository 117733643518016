<template>
  <div class="submergedAnalysisBox">
    <header v-interact>
      <div>淹没分析</div>
      <div class="cp" @click="$emit('close')">X</div>
    </header>
    <div>
      <div class="content tl pl5 pr5">
        <el-form
          ref="form-inline"
          :rules="rules"
          :inline="true"
          :model="form"
          class="demo-form-inline"
        >
          <el-form-item label="数据类型:">
            <el-radio v-model="form.radio" :label="1">模拟演示</el-radio>
            <el-radio v-model="form.radio" :label="2">实时检测</el-radio>
          </el-form-item>
          <div v-if="form.radio == 1">
            <el-form-item label="每次提升:" prop="height">
              <el-input
                v-model="form.height"
                class="w100"
                type="number"
                min="0"
                @keydown.native="channelInputLimit"
              ></el-input
              >m
            </el-form-item>
            <el-form-item label="每次提升:" prop="frequency">
              <el-input
                v-model="form.frequency"
                class="w100"
                min="0"
                type="number"
                @keydown.native="channelInputLimit"
              ></el-input
              >次/秒
            </el-form-item>
          </div>
          <div v-else="form.radio == 2">
            <el-form-item label="水位测点:" prop="waterType">
              <el-select v-model="form.waterType" placeholder="请选择水位测点" filterable>
                <el-option
                  v-for="item in submergedAnalysisData"
                  :key="item.id"
                  :label="item.waterType"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item class="mt6" label="是否循环:">
            <el-checkbox v-model="form.checked">备选项</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <div id="ecahrtAnalysis" class="w435 h250"></div>
      <div class="footer">
        <div class="btn" @click="resetImitate">
          <span>重新模拟</span>
        </div>
        <div v-if="imitateFlag" class="btn" @click="stopImitate">暂停模拟</div>
        <div v-else class="btn" @click="imitate">开始模拟</div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  props: {
    submergedAnalysisData: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      form: {
        frequency: '',
        height: '',
        checked: '',
        radio: 1,
        waterType: null
      },
      filename_options: [
        {
          id: 1,
          label: '0.5',
          value: 0.5
        }
      ],
      // 数据类型
      oneSecond: 1000,
      date: [],
      data: [],
      rules: {
        frequency: [
          {
            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
            required: true,
            message: '请输入每次提升米数',
            trigger: 'blur'
          }
        ],
        height: [
          {
            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
            required: true,
            message: '请输入每秒提升次数',
            trigger: 'blur'
          }
        ],
        waterType: [{ required: true, message: '请选择水位测点', trigger: 'blur' }]
      },
      // 模拟状态
      imitateFlag: false,
      time: null,
      height: 0,
      chart: null
    };
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
  methods: {
    channelInputLimit(e) {
      const key = e.key;
      // 不允许输入'e'和'.'
      if (key === 'e' || key === '.' || key === '-') {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    restrictNegativeInput2() {
      this.form.frequency = parseFloat(this.form.frequency) >= 0 ? this.form.frequency : '';
      this.$forceUpdate();
    },
    stopImitate() {
      this.imitateFlag = false;
      clearInterval(this.time);
    },
    resetImitate() {
      this.imitateFlag = false;
      clearInterval(this.time);
      this.height = 0;
      this.date = [];
      this.data = [];
      const chart = echarts.getInstanceByDom(document.getElementById('ecahrtAnalysis'));
      chart.setOption({
        xAxis: {
          data: []
        },
        series: [
          {
            data: []
          }
        ]
      });
      if (this.form.checked) {
        this.imitate();
      }
    },
    imitate() {
      this.$refs['form-inline'].validate((valid) => {
        if (valid) {
          this.imitateFlag = true;
          this.time = setInterval(this.updateData, 1000);
        } else {
          // this.$message.error()
          return false;
        }
      });
    },
    renderChart() {
      const option = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        grid: {
          left: '1%',
          containLabel: true
        },
        title: {
          left: 'center',
          text: '当前水面高度',
          textStyle: {
            // 文字颜色
            color: '#fff',
            // 字体风格,'normal','italic','oblique'
            fontStyle: 'normal',
            // 字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
            fontWeight: 'bold',
            // 字体系列
            fontFamily: 'sans-serif',
            // 字体大小
            fontSize: 18
          }
        },

        toolbox: {},
        //
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.date,
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          }
        },
        legend: {
          show: false
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            show: true,
            formatter: function (value) {
              var texts = value;
              if (texts.length > 15) {
                // 具体多少字就看自己了
                texts = texts.substr(0, 15) + '...';
              }
              return texts;
            }
          }
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            name: '当前水面高度',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#50abff'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#50abff'
                },
                {
                  offset: 1,
                  color: '#0b56a2'
                }
              ])
            },
            data: this.data
          }
        ]
      };

      this.chart = echarts.init(document.getElementById('ecahrtAnalysis'));
      this.chart.setOption(option);
    },
    updateData() {
      const { frequency, height, waterType } = this.form;
      const num = Number(frequency);
      const ht = Number(height);
      console.log(this.form.radio, num, 'this.form.radio');
      if (this.form.radio == 1) {
        this.height += num * ht;
      } else {
        if (Number(waterType)) {
          this.height += waterType;
        }
      }
      this.oneSecond += 1000;
      let base = +new Date(1968, 9, 3);
      const now = new Date((base += this.oneSecond));
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.date.push([hours, minutes, seconds].join(':'));

      this.data.push(this.height);

      const chart = echarts.getInstanceByDom(document.getElementById('ecahrtAnalysis'));
      chart.setOption({
        xAxis: {
          data: this.date,
          axisLable: {
            interval: 0
          }
        },
        series: [
          {
            data: this.data
          }
        ]
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.submergedAnalysisBox {
  position: fixed;
  right: 429px;
  top: 105px;
  width: 450px;
  height: 490px;
  background: url('../../img/bg.png');
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-size: 100%;
  padding: 8px;
  header {
    display: flex;
    padding: 5px 8px;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    color: #fff;
    // margin-bottom: 20px;
  }
  .content {
    color: #a0a7af;
    .content_type {
      display: flex;
      justify-content: flex-start;
      margin-left: 11px;
    }
  }
  .footer {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 36px;
    justify-content: space-evenly;
    .btn {
      width: 96px;
      cursor: pointer;
      height: 32px;
      background: #50abff;
      border-radius: 2px;
      color: #fff;
      text-align: center;
      line-height: 32px;
    }
  }
}
::v-deep {
  .el-form-item__label {
    color: #a0a7af;
  }
  .el-form-item {
    margin-right: 5px;
  }
  .el-radio {
    color: #fff;
  }
  .el-checkbox {
    color: #fff;
  }
  .el-slider__button {
    width: 14px;
    height: 23px;
    border: 3px solid #fff;
    background-color: #409eff;
    border-radius: 0px;
    transition: 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
}
</style>
