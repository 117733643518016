<template>
  <div class="cpt-home-flight-popup">
    <!-- <div class="header"
         v-interact>
      <span v-hover
            class="iconfont icon-guanbi"
            @click="change_active(0)"></span>
    </div> -->
    <!-- <div class="tabs">
      <span class="tab_box left"
            :class="{ active: this.$store.state.active == 0 }"
            @click="handle_tab_click(0)">飞行任务</span>
      <span class="tab_box right"
            :class="{ active: this.$store.state.active == 1 }"
            @click="handle_tab_click(1)">航线管理</span>
    </div> -->
    <div class="title">
      <!-- <span v-if="active == 1">{{
        `${projectData ? projectData.projectName + '的' : ''}无人机`
      }}</span> -->
      <span v-if="active == 1 || active == 3">{{$t('navibar.uavList')}}</span>
      <span v-if="active == 2">{{$t('navibar.RouteManagement')}}</span>
      <span v-if="active == 0">{{$t('navibar.ProjectList')}}</span>
      <span v-if="active == 4">{{$t('navibar.PilotList')}}</span>
      <span v-if="active == 5">{{$t('navibar.ServiceNetworkList')}}</span>
      <span v-if="active == 6">{{$t('navibar.TrainingNetworkList')}}</span>
      <span v-if="active == 7">{{$t('navibar.DisplayNetworkList')}}</span>
      <span v-if="active == 8">{{$t('navibar.RunningProjectList')}}</span>
      <span v-if="active == 9">{{$t('navibar.ThreeDApplications')}}</span> 
      <!-- <span v-if="active == 10">标注</span>
      <span v-if="active == 11">标签</span> -->
      <!-- 子平台 -->
      <span v-if="active == 10">{{$t('navibar.uavMission')}}</span>
      <span v-if="active == 14">{{$t('navibar.AerieTask')}}</span>
      <span v-if="active == 11">{{$t('navibar.FlightLog')}}</span>
      <span v-if="active == 12">{{$t('navibar.AirspaceManagement')}}</span>
      <span v-if="active == 13">{{$t('navibar.EagleNestList')}}</span>
      <span v-if="active == 15">{{$t('navibar.WarningRecord')}}</span>
    </div>
    <div class="table-content">
      <FlightUavControl v-if="active == 1" />
      <FlightLineList v-if="active == 2" ref="flightLineList" />
      <FlightProjectList v-else-if="active == 0" />
      <FlightUavList v-else-if="[3, 4, 5, 6, 7, 8].includes(active)" />
      <ThreeDimensional v-else-if="active === 9" />
      <!-- 子平台 -->
      <FlightTaskList v-else-if="active === 10" :target="0" />
      <FlightTaskList v-else-if="active === 14" :target="1" />
      <FlightLog v-else-if="active === 11" />
      <AirspaceManagement v-else-if="active === 12" />
      <FlightEagleNest v-else-if="active === 13" />
      <ThreeDimensionalv2 v-if="active === 116" />
      <ThreeDimensionalv3 v-if="active === 117" />
      <ThreeDimensionalv4 v-if="active === 118" />
      <PageBasicPropertyInfos v-if="active === 119" />
      <PanoramicDisplay v-if="active === 120"/>
      <!--预警记录-->
      <early-warning v-if="active === 15" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PanoramicDisplay from './panoramicDisplay';
import FlightLineList from './flight-line-list';
import FlightTaskList from './flight-task-list';
import FlightProjectList from '@/components/home/project-list';
import FlightUavControl from './flight-uav-control';
import FlightUavList from './flight-uav-list';
import ThreeDimensional from './three-dimensionalv2';
import ThreeDimensionalv2 from './three-dimensionalv2';
import ThreeDimensionalv3 from './three-dimensionalv2';
import ThreeDimensionalv4 from './three-dimensionalv2';
import PageBasicPropertyInfos from './PageBasicPropertyInfos';

import FlightEagleNest from '@/components/eagle-nest/flight-eagle-nest';
import FlightLog from '@/components/subplat-form/flight-log';
import AirspaceManagement from '@/components/subplat-form/airspace-management';
import EarlyWarning from './early-warning/index.vue';
export default {
  inject: ['change_active'],
  components: {
    FlightLineList,
    FlightProjectList,
    FlightTaskList,
    FlightUavControl,
    FlightUavList,
    ThreeDimensional,
    FlightLog,
    AirspaceManagement,
    FlightEagleNest,
    ThreeDimensionalv2,
    ThreeDimensionalv3,
    ThreeDimensionalv4,
    PageBasicPropertyInfos,
    EarlyWarning,
    PanoramicDisplay
  },
  data() {
    return {
      options: Object.freeze([
        { label: this.$t('dict.PendingExecution'), value: 0 }, //'待执行'
        { label: this.$t('dict.InExecution'), value: 1 }, //'执行中'
        { label: this.$t('dict.Completed'), value: 2 }, //'已完成'
      ]),
      status: null
    };
  },
  computed: {
    ...mapState(['active', 'projectData']),
    ...mapState('subplatform', ['subplatFormStatus'])
  },
  methods: {
    ...mapMutations(['CHANGE_ACTYIVE']),
    handle_tab_click(e) {
      this.CHANGE_ACTYIVE(e);
    },

    clear_airline_entitys() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.flightLineList.clear_airline_entitys();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-home-flight-popup {
  position: absolute;
  bottom: 48px;
  right: 8px;
  top: 30px;
  width: 367px;

  //height: 88%;
  .title {
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 600;
  }

  .table-content {
    background: #0a0b0d;
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    top: 30px;
    width: 100%;
  }

  .header {
    height: 32px;

    .iconfont {
      font-size: 24px;
      color: #b3bbc5;
      float: right;
      margin: 5px 5px 0 0;
    }
  }

  .tabs {
    height: 32px;
    background: #111111;
    box-sizing: border-box;

    .active {
      background: #1e2026;
    }

    .tab_box {
      display: inline-block;
      width: 80px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #c6ccd4;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      line-height: 32px;
      cursor: pointer;

      &:hover {
        background: #1e2026;
      }
    }
  }

  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;

    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;

      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    &::v-deep {
      .el-input {
        width: 455px;

        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }

  .search_box.task {
    &::v-deep {
      .el-input {
        width: 211px;

        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }
}
</style>
