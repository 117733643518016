<template>
  <div>
    <div class="silderBar" :style="'width:' + width">
      <div v-if="loginState && active !== -1" class="zhedie1" @click="changewidth">
        <i
          id="change_icon"
          class="iconfont icon-zhedie1"
          :class="{ 'icon-zhedie': isShow, 'icon-zhedie1': !isShow }"
        />
      </div>
      <div class="silderBar_top" :style="'width:' + width" />

      <!-- v-if="vip == '行业版'|| vip =='测试行业版'" -->
      <div ref="navRef" class="silderBar_nav">
        <div v-if="loginState" class="silderBar_box_top">
          <div class="up-arrow-wrap">
            <div
              v-show="scrollTop && appShowCount < appProjectList.length"
              class="up-arrow"
              @click="upArrowClick"
            >
              <div class="up-triangle" />
            </div>
          </div>
          <div ref="appRef" class="application-list-wrap" :style="{ height: appListHeight + 'px' }">
            <div v-for="(item, index) in appProjectList" :key="item.id">
              <el-tooltip class="item" effect="dark" :content="item.name" placement="right">
                <div
                  :class="{
                    item: true,
                    item_icon: true,
                    'item-active': currentAppIndex === index
                  }"
                  @click="appSelectEvent(index)"
                >
                  <img :src="item.icon" />
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="down-arrow-wrap">
            <div
              v-show="
                scrollTop != (appProjectList.length - appShowCount) * appItemHeight &&
                appShowCount < appProjectList.length
              "
              class="down-arrow"
              @click="downArrowClick"
            >
              <div class="down-triangle" />
            </div>
          </div>
        </div>

        <div v-if="loginState" ref="sliderBar_bottom" class="sliderBar_bottom">
          <div
            v-for="(item, index) in silderBar_bottom"
            :key="index"
            v-pms="[item.name]"
            class="item item_icon_top"
            :class="{
              active: openLeftNavSilder && currentOptionIndex === index
            }"
            @click="goMeg(index + 1)"
          >
            <i :class="item.icon" :style="{ fontSize: item.fontSize }" />
            <div class="item_hover_bottom">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <FlightPopup2 v-if="isShow" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { Control_API, projectAPI } from '@/api';
import FlightPopup2 from '@/views/uavApplications/components/flight-popup2';

const minWidth = '58px'; // 收起时的宽度
const maxWidth = '439px'; // 展开时的宽度

export default {
  name: 'NavSlider',
  components: { FlightPopup2 },
  props: {},
  inject: ['change_active'],
  data() {
    return {
      vip: '',
      isList: false,
      subPlatforms: [
        {
          icon_top: 'iconfont icon-hangye-gongan',
          name: '智慧公安',
          id: 10000,
          url: (token) => `http://39.103.170.213/javaweb/#/tm?token=${token}`
        },
        {
          icon_top: 'iconfont icon-hangye-yingji',
          name: '智慧应急',
          id: 80000,
          url: (token) =>
            'http://112.124.32.65/#/emergency' /* 'https://jianzai.mmcuav.cn/disater/#/order' */ /* `http://112.124.32.65/#/tm?token=${token}` */
        },
        {
          icon_top: 'iconfont icon-hangye-chengguan',
          children: [
            {
              name: '智慧网格',
              url: (token) => `http://webmspace.mmcuav.cn/#/tm?token=${token}`,
              id: 20000
            },
            {
              name: '智慧查违',
              url: (token) => `http://vio.henengsoft.com:8118/#/user/login?userCode=${token}`,
              id: 40005
            }
          ]
        },
        {
          icon_top: 'iconfont icon-hangye-dianli',
          name: '智慧电力',
          id: 40002,
          url: (token) => `http://elec.rtk.mmcuav.cn/electric-education/#/tm?token=${token}`
        },
        {
          icon_top: 'iconfont icon-hangye-shiyou',
          name: '智慧石油',
          id: 40003,
          url: (token) => `http://pipe.rtk.mmcuav.cn/electric-education/#/tm?token=${token}`
        }
      ],
      silderBar_bottom: [
        // { icon: 'iconfont icon-wurenji-xiangmuliebiao', name: '项目列表', fontSize: '24px' },
        {
          icon: 'iconfont icon-wurenji-renwu',
          name: '飞行任务',
          fontSize: '20px'
        },
        { icon: 'iconfont icon-hangxian', name: '航线管理', fontSize: '16px' }
      ],
      appProjectList: [], // 项目应用数据
      projectList: [], // 项目数据
      cloneProjectList: [], // 拷贝一份
      currentAppIndex: -1, // 当前选中应用下标
      currentOptionIndex: -1, // 当前操作下标
      appShowCount: 9, // 显示应用个数
      scrollTop: 0, // 距离顶部高度
      appItemHeight: 52, // 单个应用高度+下边距
      isElectron: false,
      loginState: false,
      isPath: true,
      appListHeight: 0 // 应用列表展示高度
    };
  },
  provide() {
    return {
      getAppProjectList: () => this.appProjectList,
      getProjectDataList: () => this.projectList
    };
  },
  computed: {
    ...mapState(['openLeftNavSilder', 'active', 'userInfo']),
    isShow: {
      get: function () {
        return this.openLeftNavSilder;
      },
      set: function (v) {
        this.SET_OPEN_LEFT_NAV_SILDER(v);
      }
    },
    width() {
      return this.openLeftNavSilder ? maxWidth : minWidth;
    },
    subPlatformsByID() {
      const obj = {};
      (function loop(list) {
        list.forEach((item) => {
          if (item.children) {
            loop(item.children);
          } else {
            obj[item.id] = item;
          }
        });
      })(this.subPlatforms);
      return obj;
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    this.getAppAndProject();
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.getBrowserIsElctron();
    JSON.parse(window.localStorage.getItem('user_info'));
    // this.vip = data?.rolePermission[0]?.name;
    this.loginState = !!window.localStorage.getItem('tmj_token');
    this.$bus.$on('searchProjectByName', (text) => {
      if (text) {
        this.projectList = this.projectList.filter((v) => v.projectName == text);
      } else {
        this.projectList = this.cloneProjectList;
      }
    });
    this.$nextTick(() => {
      const sliderDom = this.$refs.sliderBar_bottom;
      const navDom = this.$refs.navRef;
      const height = navDom.offsetHeight - sliderDom.offsetHeight - 87;
      this.appShowCount = ~~(height / this.appItemHeight);
      this.appListHeight = this.appShowCount * this.appItemHeight + 10;
    });
  },
  methods: {
    ...mapMutations(['SET_OPEN_LEFT_NAV_SILDER']),
    goMeg(index) {
      this.change_active(1, index);
      // 项目列表
      // this.$bus.$emit("closeListNav")
      // console.log('icon', icon);
      if (this.width === minWidth) {
        document.getElementById('change_icon');
        this.isShow = true;
      }
      this.currentOptionIndex = index - 1;
      this.currentAppIndex = -1;
    },
    changewidth() {
      var icon = document.getElementById('change_icon');
      if (this.width === minWidth) {
        icon.classList.remove('icon-zhedie1');
        icon.classList.add('icon-zhedie');
        this.isShow = true;
        /* this.$bus.$emit("handleZhedie", this.show);
        this.$bus.$emit("handleZhedie2", this.show) */
      } else {
        icon.classList.add('icon-zhedie1');
        icon.classList.remove('icon-zhedie');
        this.isShow = false;
        /* this.$bus.$emit("handleZhedie", this.show);
        this.$bus.$emit("handleZhedie2", this.show) */
      }
    },
    shoWList() {
      this.isList = !this.isList;
    },
    async getSonToken(id) {
      const tmj_token = window.localStorage.getItem('tmj_token');
      const loginInfo = {
        token: tmj_token
      };

      loginInfo.appId = id;
      const res = await Control_API.login({ loginInfo });
      if (res.msg) {
        if (res.msg.indexOf('过期') !== -1) {
          this.isPath = false;
          this.$message({
            type: 'error',
            message: '账号已过期，请联系管理员',
            duration: 2000
          });
        } else {
          this.isPath = true;
        }
      }
      if (res.data) {
        return res?.data?.token || res?.data?.result || res?.data['mmc-identity'];
      }
    },
    async goToSon(id) {
      let url;
      const token = await this.getSonToken(id);
      console.log('token', token);
      switch (id) {
        case 10000:
          // 跳转警航
          url = this.subPlatformsByID[id].url(this.userInfo.token);
          break;

        default:
          url = this.subPlatformsByID[id].url(token);
          break;
      }
      if (this.isPath) {
        this.goToNewWindow(url);
      }
    },
    getBrowserIsElctron() {
      const userAgent = navigator.userAgent;
      const isElectron = userAgent.includes('Electron');
      this.isElectron = isElectron;
    },
    goToNewWindow(url) {
      if (this.isElectron) {
        const electron = window.electron;
        const ipcRenderer = electron?.ipcRenderer;
        ipcRenderer && ipcRenderer.send('add', url);
      } else {
        window.open(url, '_blank');
      }
    },
    async getAppAndProject() {
      const res = await projectAPI.getAppAndProject();
      if (res.data && res.data.length !== 0) {
        this.appProjectList = res.data.filter((v) => v.type !== 3);
      }
    },
    // 应用选中
    appSelectEvent(index) {
      this.goMeg(0);
      this.projectList = this.appProjectList[index].projectModels;
      this.cloneProjectList = this.appProjectList[index].projectModels;
      this.currentAppIndex = index;
    },
    // 上移
    upArrowClick() {
      const appRef = this.$refs.appRef;
      if (this.scrollTop >= this.appItemHeight) {
        this.scrollTop -= this.appItemHeight;
        appRef.scrollTo({ top: this.scrollTop });
      }
    },
    // 下移
    downArrowClick() {
      const appRef = this.$refs.appRef;
      if (
        (this.scrollTop + this.appItemHeight) / this.appItemHeight !=
        this.projectList.length - this.appShowCount
      ) {
        this.scrollTop += this.appItemHeight;
        appRef.scrollTo({ top: this.scrollTop });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.silderBar {
  display: flex;
  transition: width 0.25s;
  -moz-transition: width 0.25s; /* Firefox 4 */
  transition: width 0.25s; /* Safari and Chrome */
  -o-transition: width 0.25s; /* Opera */
  position: relative;
  height: calc(100% - 60px);
  // width: 96px;
  position: absolute;
  top: 54px;
  left: 4px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;

  // box-shadow: inset 0 1px 4px 0 #2e63dc,
  //   inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
  //   inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  // border-radius: 8px;
  border: 1px solid #3bc1e5;

  // display: flex;
  // // align-items: center;
  // justify-content: center;
  .zhedie1 {
    position: absolute;
    // right：0px;
    // left: 105px;
    right: -30px;
    background: #313642;
    border-radius: 2px;
    width: 24px;
    height: 24px;
    color: #9ca7c4;
    text-align: center;
    line-height: 24px;
  }
  .zhedie1:hover {
    color: #ffffff;
    cursor: pointer;
  }
  .silderBar_top {
    position: absolute;
    height: 10px;
    top: 0px; // width: 100%;
    padding-top: 4px;
    transition: width 0.2s;
    background-image: url('~@/assets/home/6eff87ed-0912-41d1-9281-a88dd28ec2e5.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .silderBar_nav {
    margin-top: 22px;
    text-align: center;
    line-height: 48px;
    // width: 98px;
    display: flex;
    justify-content: center;
    z-index: 1;
    //flex: 1;
    .silderBar_box_top {
      padding-left: 8px;
      display: flex;
      flex-direction: column;
      //align-items: center;
      .application-list-wrap {
        height: 322px;
        overflow: hidden;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          // chrome safari
          display: none;
        }
        .item_icon {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          margin: 10px 0;
          border: 1px solid #23364b;
          // box-shadow: 0px 0px 6px 0px #23364b;
          border-radius: 4px;

          .iconfont {
            font-size: 20px;
            color: rgba(255, 255, 255, 0.7);
          }

          .item_hover {
            width: 60px;
            font-size: 12px;
            position: absolute;
            background: #45537d;
            border-radius: 10px;
            left: 70px;
            top: 0px;
            display: none;
            z-index: 9999;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        .item {
          width: 40px;
          height: 40px;
          color: rgba(255, 255, 255, 0.7);
          font-size: 20px !important;
          cursor: pointer;
        }
        .item-active {
          background: #45537d;
          border-radius: 10px;
          color: #ffff;
        }
      }
      .up-arrow-wrap,
      .down-arrow-wrap {
        width: 40px;
        height: 13px !important;
      }
      .up-arrow,
      .down-arrow {
        width: 40px;
        height: 13px !important;
        border-radius: 5px;
        border: 1px solid #314271;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        animation: fadeIn 0.618s ease-in-out;
        animation-fill-mode: forwards;
        .up-triangle {
          width: 0;
          height: 0;
          border-bottom: 5px solid #50abff;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
        .down-triangle {
          width: 0;
          height: 0;
          border-top: 5px solid #50abff;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }
    }
  }

  .sliderBar_bottom {
    position: absolute;
    left: 8px;
    // width: 26px;
    display: flex;
    flex-direction: column;
    align-content: center;
    // background: #2e63dc;
    bottom: 41px;
    .item_icon_top {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      margin: 6px 0;
      .item_hover_bottom {
        position: absolute;
        left: 80px;
        top: 0;
        width: 60px;
        background: #45537d;
        font-size: 12px;
        border-radius: 10px;
        display: none;
      }
    }
    .item {
      color: rgba(255, 255, 255, 0.7);
      width: 40px;
      height: 40px;
      cursor: pointer;

      &:hover,
      &.active {
        color: #ffff;
        cursor: pointer;
        background: #45537d;
        border-radius: 10px;
        z-index: 9999;
      }

      &:hover {
        .item_hover_bottom {
          display: block;
        }
      }
    }
  }
}

.icon-static {
  display: block;

  &:hover {
    display: none;
  }
}

.icon-hover {
  display: none;

  &:hover {
    display: block;
  }
}

.item_icon_top,
.item_icon {
  &:hover {
    .iconfont {
      &::before {
        content: '';
        height: 24px;
        width: 24px;
        display: block;
        background-size: 100%;
      }
    }

    .icon-hangye-gongan {
      &::before {
        background: url('~@/assets/0618Icons/ga.webp');
      }
    }
    .icon-hangye-yingji {
      &::before {
        background: url('~@/assets/0618Icons/yj.webp');
      }
    }
    .icon-hangye-dianli {
      &::before {
        background: url('~@/assets/0618Icons/dl.webp');
      }
    }
    .icon-hangye-shiyou {
      &::before {
        background: url('~@/assets/0618Icons/sy.webp');
      }
    }
    .icon-hangye-chengguan {
      &::before {
        background: url('~@/assets/0618Icons/cg.webp');
      }
    }

    .icon-wurenji-xiangmuliebiao {
      &::before {
        background: url('~@/assets/0618Icons/project.webp');
      }
    }

    .icon-wurenji-renwu {
      &::before {
        background: url('~@/assets/0618Icons/task.webp');
      }
    }

    .icon-hangxian {
      &::before {
        background: url('~@/assets/0618Icons/flight.webp');
      }
    }
  }

  .icon-wurenji-xiangmuliebiao {
    font-size: 19px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* @import url(); 引入css类 */
</style>
<style lang="scss">
.navsider-dropdown-menu {
  transform: translate(47px, -47px);
}
</style>
