<template>
  <div class="flight-task-list">
    <el-tabs v-if="target === 1" v-model="taskType" @tab-click="handleClick">
      <el-tab-pane :label="$t('taskMangement.常态任务')" name="0" />
      <el-tab-pane :label="$t('taskMangement.定时任务')" name="1" />
      <el-tab-pane :label="$t('taskMangement.周期任务')" name="2" />
    </el-tabs>
    <div class="search_box task">
      <!-- <div class="btn_create"
           @click="goTaskCreate"
           v-hover
           v-pms="['飞行任务', '创建']">

        <span>{{$t('operation.Create')}}</span>
      </div> -->
      <el-button v-hover v-pms="['飞行任务', '创建']" @click="goTaskCreate">
        {{$t('taskMangement.创建任务')}}
      </el-button>
      <el-select
        v-model="status"
        style="font-size: 12px"
        :placeholder="$t('taskMangement.任务状态')"
        clearable=""
        @change="selectSearch"
      >
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
      </el-select>
      <el-input
        v-model="taskText"
        :placeholder="$t('flightApplication.TaskName')"
        prefix-icon="el-icon-search"
        @change="goSearch"
      />

      <!-- <div v-hover
           class="btn_search"
           @click="goSearch"></div> -->
      <!-- 查询 -->
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">{{$t('flightApplication.TaskName')}}</div>
        <!-- <div class="tb-hd">航线名称</div> -->
        <div class="tb-hd">{{$t('taskMangement.任务状态')}}</div>
        <div class="tb-hd">{{$t('taskMangement.责任单位')}}</div>
        <!-- <div class="tb-hd">责任单位</div> -->
        <!-- <div class="tb-hd">创建时间</div> -->
        <div class="tb-hd">{{$t('home.uav')}}</div>
        <div class="tb-hd" style="flex: none; width: 103px !important">
          {{$t('operation.Operation')}}
        </div>
      </div>
      <div class="tb-bd-box">
        <div v-for="item in list" :key="item.id" class="tb-tr">
          <div class="td">
            {{ item.taskName || $t('home.no')}}
          </div>
          <!-- <div class="td">
            {{ item.routeName || $t('home.no') }}
          </div> -->
          <!-- :style="{ color: item.taskStatus == 2 ? '#FA8100 ' : '#C6CCD4' }" -->
          <div class="td" :style="{ color: taskTypeColor(item) }">
            {{ taskTypeName(item) || $t('home.no') }}
          </div>
          <div class="td">
            {{ item.orgName || $t('home.no') }}
          </div>
          <!-- <div class="td">
            {{ item.organizationName || $t('home.no') }}
          </div> -->
          <!-- <div class="td">
            {{ item.createWhen || $t('home.no') }}
          </div> -->
          <div
            class="td"
            :alt="item.uavName"
            :style="{
              opacity: item.deviceStatus == 1 ? '1' : '0.5',
              color: item.deviceStatus == 1 ? '#a4ff64' : '',
            }"
          >
            {{ item.uavName || $t('home.no') }}
          </div>
          <div class="td" style="flex: none; width: 103px !important">
            <el-tooltip v-hover v-pms="['飞行任务', '详情']"
              effect="dark"
              :content="$t('operation.Detail')"
              placement="top"
            >
              <img
                src="~@/assets/image/icons/details.png"
                style="color: #a16869; width: 26px; height: 26px"
                @click="detailsItem(item)"
              />
            </el-tooltip>
            <el-tooltip
              v-if="item.approveStatus === 1 && item.userId === user_info.id"
              v-hover
              v-pms="['飞行任务', '编辑']"
              effect="dark"
              :content="$t('operation.Edit')"
              placement="top"
            >
              <div class="iconfont icon-bianji" @click="goEdit(item)" />
            </el-tooltip>

            <el-tooltip
              v-else-if="
                item.approveStatus === 3 && item.userId === user_info.id
              "
              v-hover
              v-pms="['飞行任务', '重新发起']"
              effect="dark"
              :content="$t('taskMangement.重新发起')"
              placement="top"
            >
              <img
                src="~@/assets/image/icons/reload.png"
                style="color: #a16869; width: 26px; height: 26px"
                @click="reloadItem(item)"
              />
            </el-tooltip>
            <!-- <el-tooltip v-hover v-pms="['飞行任务', '详情']" effect="dark" :content="$t('operation.Detail')" placement="top">
              <div v-if="item.taskStatus == 2 || item.taskStatus == 3" class="iconfont icon-chakan" :style="{
                cursor:
                  item.taskStatus == 2 || item.taskStatus == 3
                    ? 'pointer'
                    : ['not-allowed'],
                opacity:
                  item.taskStatus == 2 || item.taskStatus == 3 ? '1' : '0.5',
              }" @click="goControlBar(item)" />
            </el-tooltip> -->
            <!-- <el-tooltip
              v-hover
              v-pms="['飞行任务', '执行']"
              effect="dark"
              content="执行"
              placement="top"
            >
              <div
                v-if="item.taskStatus == 1"
                class="iconfont icon-zhihang"
                :style="{
                  cursor: item.taskStatus == 1 ? 'pointer' : ['not-allowed'],
                  opacity: item.taskStatus == 1 ? '1' : '0.5',
                }"
                @click="goExecute(item)"
              />
            </el-tooltip> -->

            <el-tooltip
              v-if="!item.approveButton && item.approveStatus === 1"
              v-pms="['飞行任务', '审批']"
              effect="dark"
              :content="$t('taskMangement.审批')"
              placement="top"
            >
              <img
                v-hover
                src="~@/assets/image/icons/examine.png"
                style="color: #a16869; width: 26px; height: 26px"
                :class="{ disable: item.taskStatus != 1 }"
                @click="examineItem(item)"
              />
            </el-tooltip>
            <el-tooltip
              v-if="
                item.userId === user_info.id &&
                (item.approveStatus === 1 ||
                  item.approveStatus === 2 ||
                  item.approveStatus === 3)
              "
              v-pms="['飞行任务', '删除']"
              effect="dark"
              :content="$t('operation.Delete')"
              placement="top"
            >
              <div
                v-hover
                class="iconfont icon-shanchu"
                style="color: #a16869"
                :class="{ disable: item.taskStatus != 1 }"
                @click="deleteItem(item)"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-count="current"
        :page-size="page.size"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { flightTaskAPI } from "@/api";
import { mapMutations, mapState } from "vuex";

export default {
  inject: [
    "change_active",
    "c_dashBoard",
    "get_flight_info",
    "send_to_task_tooptip",
    "get_flight_state",
  ],
  props: {
    target: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      options: Object.freeze([
        { label: this.$t('dict.PendingApproval'), value: 10 },
        { label: this.$t('dict.Rejected'), value: 11 },
        { label: this.$t('dict.PendingExecution'), value: 0 },
        { label: this.$t('dict.InExecution'), value: 1 },
        { label: this.$t('dict.Completed'), value: 2 },
      ]),
      status: "",
      taskText: "",
      list: [],
      pageTotal: 0, // 总条数
      current: 0, // 总页数
      page: {
        num: 1, // 页码
        size: 15, // 页面条数
        status: null, // 飞行状态
        taskName: null, // 任务名称
      },
      typeGroup: [
        {
          id: 0,
          name:  this.$t('dict.PendingExecution'),
          color: "#FA8100",
        },
        {
          id: 1,
          name:  this.$t('dict.InExecution'),
          color: "#A4FF64",
        },
        {
          id: 2,
          name:  this.$t('dict.Completed'),
          color: "#C6CCD4",
        },
      ],
      typeOfRatify: [
        {
          id: 0,
          name:  this.$t('dict.NoApprovalRequired'),
          color: "#C6CCD4",
        },
        {
          id: 1,
          name:  this.$t('dict.PendingApproval'),
          color: "#ff2727",
        },
        {
          id: 2,
          name:  this.$t('dict.Completed'),
          color: "#C6CCD4",
        },
        {
          id: 3,
          name:  this.$t('dict.Rejected'),
          color: "#FB4A2D",
        },
      ],
      taskType: "0",
      user_info: JSON.parse(window.localStorage.getItem("user_info")),
    };
  },
  computed: {
    ...mapState(["projectData"]),
  },
  watch: {
    target: function () {
      this.getFlightTaskList(1);
    },
  },
  methods: {
    ...mapMutations(["SET_UAVSTATE", "SET_SIDEBAR_STATUS"]),
    handleClick() {
      this.getFlightTaskList(1);
    },
    selectSearch() {
      this.reloadList();
    },
    goTaskCreate() {
      console.log(this.target,'this.target');
      console.log(this.taskType,'this.taskType');
      // this.change_active(4)
      if (this.target === 0) {
        this.SET_SIDEBAR_STATUS(1);
      } else if (this.target === 1) {
        if (this.taskType === "0") {
          this.SET_SIDEBAR_STATUS(100);
        } else if (this.taskType === "1") {
          this.SET_SIDEBAR_STATUS(101);
        } else {
          this.SET_SIDEBAR_STATUS(102);
        }
      }
      this.$bus.$emit("openSideList");
      // this.$bus.$emit('closeListNav');
    },

    taskTypeColor(item) {
      // 先判断审批状态 如果通过或者不用审批 直接取拿执行状态就行 反之拿审批状态
      if (item.approveStatus === 1 || item.approveStatus === 3) {
        this.type = item.approveStatus;
        for (const obj of this.typeOfRatify) {
          if (obj.id == item.approveStatus) {
            return obj.color;
          }
        }
      } else {
        this.type = item.taskStatus;
        for (const obj of this.typeGroup) {
          if (obj.id == item.taskStatus) {
            return obj.color;
          }
        }
      }
    },
    taskTypeName(item) {
      // 先判断审批状态 如果通过或者不用审批 直接取拿执行状态就行 反之拿审批状态
      if (item.approveStatus === 1 || item.approveStatus === 3) {
        this.type = item.approveStatus;
        for (const obj of this.typeOfRatify) {
          if (obj.id == item.approveStatus) {
            return obj.name;
          }
        }
      } else {
        this.type = item.taskStatus;
        for (const obj of this.typeGroup) {
          if (obj.id == item.taskStatus) {
            return obj.name;
          }
        }
      }
    },

    deleteItem(item) {
      flightTaskAPI.deleteTask(item.id).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: this.$t('flightApplication.删除成功'),
            duration: 1000,
          });
          this.reloadList();
        } else {
          this.$message({
            type: "error",
            message: resp.msg,
            duration: 1000,
          });
        }
      });
    },
    examineItem(item) {
      if (item.approveStatus === 1) {
        item.clickStatus = "examine";
        this.get_flight_state(true);
        this.get_flight_info(item);
        // this.change_active(6);
        if (this.target === 0) {
          this.SET_SIDEBAR_STATUS(2);
        } else if (this.target === 1) {
          if (this.taskType === "0") {
            this.SET_SIDEBAR_STATUS(200);
          } else if (this.taskType === "1") {
            this.SET_SIDEBAR_STATUS(201);
          } else {
            this.SET_SIDEBAR_STATUS(202);
          }
        }
        this.$bus.$emit("openSideList");
      } else {
        this.$message({
          type: "warning",
          message: this.$t('taskMangement.请检查任务状态是否为待审批'),
          duration: 1000,
        });
      }
    },
    reloadItem(item) {
      if (item.approveStatus === 3) {
        item.clickStatus = "reload";
        const reloadTask = item.taskOperators.find((e) => e.functionType === 1);
        item.operateDescribe = reloadTask.operateDescribe;
        this.get_flight_state(true);
        this.get_flight_info(item);
        // this.change_active(6);
        if (this.target === 0) {
          this.SET_SIDEBAR_STATUS(2);
        } else if (this.target === 1) {
          if (this.taskType === "0") {
            this.SET_SIDEBAR_STATUS(200);
          } else if (this.taskType === "1") {
            this.SET_SIDEBAR_STATUS(201);
          } else {
            this.SET_SIDEBAR_STATUS(202);
          }
        }
        this.$bus.$emit("openSideList");
      } else {
        this.$message({
          type: "warning",
          message: this.$t('taskMangement.请检查任务状态是否为待审批'),
          duration: 1000,
        });
      }
    },
    detailsItem(item) {
      item.clickStatus = "details";
      this.get_flight_state(true);
      this.get_flight_info(item);
      // this.change_active(6);
      if (this.target === 0) {
        this.SET_SIDEBAR_STATUS(2);
      } else if (this.target === 1) {
        if (this.taskType === "0") {
          this.SET_SIDEBAR_STATUS(200);
        } else if (this.taskType === "1") {
          this.SET_SIDEBAR_STATUS(201);
        } else {
          this.SET_SIDEBAR_STATUS(202);
        }
      }
      this.$bus.$emit("openSideList");
    },

    reloadList() {
      setTimeout(() => {
        this.getFlightTaskList(this.page.num);
      }, 500);
    },

    // 跳转至编辑页
    goEdit(item) {
      if (item.taskStatus === 0) {
        item.clickStatus = "edit";
        this.get_flight_state(true);
        this.get_flight_info(item);
        // this.change_active(6);
        if (this.target === 0) {
          this.SET_SIDEBAR_STATUS(2);
        } else if (this.target === 1) {
          if (this.taskType === "0") {
            this.SET_SIDEBAR_STATUS(200);
          } else if (this.taskType === "1") {
            this.SET_SIDEBAR_STATUS(201);
          } else {
            this.SET_SIDEBAR_STATUS(202);
          }
        }
        this.$bus.$emit("openSideList");
        // this.$bus.$emit('closeListNav');
      } else {
        this.$message({
          type: "warning",
          message: this.$t('taskMangement.请检查任务状态是否为待执行'),
          duration: 1000,
        });
      }
    },
    statusFilter(e) {
      // 转换状态
      if (e === 10) {
        return 1;
      } else if (e === 11) {
        return 3;
      }
    },
    // 获取任务列表
    getFlightTaskList(num) {
      const param = {
        current: num,
        size: this.page.size,
        search: this.taskText,
        projectId:
          this.projectData?.id ||
          JSON.parse(localStorage.getItem("subplatformInfo")).projectId,
        target: this.target,
        type: this.target ? Number(this.taskType) : undefined,
      };
      if (this.status === "") {
        // 未选择
      } else if (this.status === 0) {
        param.taskStatus = this.status;
        // param.approveStatus = 2
        // param.taskStatusList = [this.status]
        param.approveStatusList = [0, 2];
      } else if (this.status && this.status > 2) {
        // param.approveStatus = this.statusFilter(this.status)
        param.approveStatusList = [this.statusFilter(this.status)];
      } else {
        param.taskStatus = this.status;
        param.approveStatusList = [0, 2];
        // param.taskStatusList = [this.status]
      }
      const formData = new FormData();
      formData.append("current", param.current);
      formData.append("size", param.size);
      formData.append("taskStatus", param.status);
      formData.append("search", param.search);
      if (param.projectId !== undefined) {
        formData.append("projectId", param.projectId);
      }
      flightTaskAPI.tssTaskPage(param).then((resp) => {
        this.list = resp.data.records;
        this.current = resp.data.pages;
        // this.current = resp.data.total;
      });
    },

    // 点击页码
    handleCurrentChange(val) {
      this.page.num = val;
      this.getFlightTaskList(val);
    },
    // 搜索任务
    goSearch() {
      this.getFlightTaskList(1);
    },

    // 查看详情
    goControlBar(item) {
      if (item.taskStatus == 2 || item.taskStatus == 3) {
        this.change_active(0);
        this.c_dashBoard.isShow = true;
        this.get_flight_info(item);
      }
    },

    // 执行
    goExecute(item) {
      // this.$store.state.deviceName1 = item.deviceName;
      // this.$store.state.routeName1 = item.routeName;
      // this.$store.state.taskName1 = item.taskName;
      if (item.taskStatus == 1) {
        this.change_active(7);
        //* **判断状态 */
        if (item.deviceStatus == 1) {
          this.SET_UAVSTATE(true); // 在线
          this.send_to_task_tooptip(item);
        } else {
          this.SET_UAVSTATE(false); // 不在线
          this.send_to_task_tooptip("");
        }
      }
    },
  },
  mounted() {
    this.getFlightTaskList(this.page.num);
    const that = this;
    this.$bus.$on("refreshList", () => {
      that.getFlightTaskList(this.page.num);
    });
    this.$bus.$on("reloadTaskList", () => {
      that.reloadList();
    });
  },
};
</script>

<style lang="scss" scoped>
.flight-task-list {
  @import "~@/styles/list-common.scss";
  height: 100%;
  .tb-box {
    height: calc(100% - 102px);
  }
  ::v-deep {
    .el-tabs {
      .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
      }
      .el-tabs__active-bar {
        background-color: #a4ff64;
      }
      .el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      .el-tabs__item {
        color: #fff;
        &:hover {
          color: #a4ff64;
        }
        &.is-active {
          color: #a4ff64;
        }
      }
    }
  }
  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;

    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;

      .iconfont {
        font-size: 14px;
        margin-right: 3px;
      }
    }

    &::v-deep {
      .el-input {
        // width: 155px;
        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }

      .el-input__inner {
        color: #fff;
      }
    }
  }

  .search_box.task {
    &::v-deep {
      .el-select {
        // border: 1px solid #233a58;
        // border-radius: 10px;
        margin: 0px 5px;
        width: 101px;
        flex-shrink: 0;

        .el-input {
          // width: 101px;
          .el-input__icon {
            line-height: 32px;
            color: #1eb9fa;
          }
        }
      }

      .el-input {
        border: 1px solid #233a58;
        border-radius: 10px;
      }
    }
  }

  .pagination {
    width: 100%;
    height: 30px;
    position: absolute;
    right: 6px;
    bottom: -34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.disable {
    cursor: not-allowed;
  }

  .forbidden {
    color: red;
  }

  #taskType_bg1 {
    color: white;
  }

  #taskType_bg2 {
    color: orange;
  }

  #taskType_bg3 {
    color: grey;
  }
}
</style>
