<script>
import { executionStatusDictionary } from '@/utils/dictionary';

export default {
  name: 'Index',
  props: {
    msgTaskDetail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      taskInfo: [
        {
          label: '消息时间',
          value: '',
          key: 'createTime'
        },
        {
          label: '任务名称',
          value: '',
          key: 'taskName'
        },
        {
          label: '任务类型',
          value: '',
          key: 'processName'
        },
        {
          label: '航线名称',
          value: '',
          key: 'routeName'
        },
        {
          label: '任务状态',
          value: '',
          key: 'type',
          optionList: executionStatusDictionary
        },
        {
          label: '责任机构',
          value: '',
          key: 'taskOrgName'
        },
        {
          label: '任务创建时间',
          value: '',
          key: 'taskAddtime'
        },
        {
          label: '任务描述',
          value: '',
          key: 'taskRemark'
        }
      ],
      applyInfo: [
        {
          label: '审批状态',
          value: '',
          key: 'isOperate',
          optionList: [
            {
              label: '通过',
              value: 2
            },
            {
              label: '不通过',
              value: 3
            }
          ]
        },
        {
          label: '驳回原因',
          value: '',
          key: 'operateDescribe'
        }
      ]
    };
  },
  computed: {
    covertTaskInfo() {
      return this.taskInfo.map((v) => ({
        ...v,
        value: Object.keys(this.msgTaskDetail).some((i) => i === v.key)
          ? v.optionList
            ? v.optionList.find((j) => j.value === this.msgTaskDetail[v.key])?.label || ''
            : this.msgTaskDetail[v.key] || ' '
          : ''
      }));
    },
    covertApplyInfo() {
      return this.applyInfo
        .map((v) => ({
          ...v,
          color:
            v.key !== 'operateDescribe'
              ? this.currentNodeInfo?.isOperate === 3
                ? '#FB4A2D'
                : '#A4FF64'
              : undefined,
          value: Object.keys(this.currentNodeInfo).some((i) => i === v.key)
            ? v.optionList
              ? v.optionList.find((j) => j.value === this.currentNodeInfo[v.key])?.label
              : this.currentNodeInfo[v.key] || ''
            : ''
        }))
        .filter(
          (v) =>
            this.currentNodeInfo?.isOperate === 3 ||
            (this.currentNodeInfo?.isOperate === 2 && v.key !== 'operateDescribe')
        );
    },
    currentNodeInfo() {
      return (
        this.msgTaskDetail?.taskOperators?.find((v) => v.labelId === this.msgTaskDetail?.labelId) ||
        {}
      );
    }
  },
  methods: {
    //  审批
    applyClick() {
      this.$emit('applyClick');
    }
  }
};
</script>

<template>
  <div class="task-info">
    <div class="content-form">
      <div v-for="(v, index) in covertTaskInfo" :key="index" class="content-form-item">
        <div class="item-label">{{ v.label }}</div>
        <div class="item-value" :class="{ 'item-value-last': index === taskInfo.length - 1 }">
          {{ v.value }}
        </div>
      </div>
      <div v-if="[2, 3].includes(currentNodeInfo.isOperate)" class="apply-info">
        <div class="apply-info-title">审批结果：</div>
        <div v-for="(v, index) in covertApplyInfo" :key="index" class="apply-info-item">
          <div class="item-label">{{ v.label }}</div>
          <div
            class="item-value"
            :style="{ color: v.color }"
            :class="{ 'item-value-last': index === applyInfo.length - 1 }"
          >
            {{ v.value }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentNodeInfo.isOperate === 1 && currentNodeInfo.functionType === 1"
      class="apply-operate"
    >
      <el-button @click="applyClick">审批</el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.task-info {
  .content-form {
    background: #1e2026;
    padding: 10px;
    &-item,
    .apply-info-item {
      display: flex;
      margin-top: 8px;
      .item-label {
        width: 100px;
        margin-top: 8px;
        font-size: 12px;
        color: #b3bbc5;
        line-height: 16px;
      }
      .item-value {
        flex: 1;
        background: #16181b;
        border-radius: 8px;
        border: 1px solid #233a58;
        padding: 6px 10px;
        font-size: 14px;
        color: #d8dde8;
        text-align: center;
        &-last {
          min-height: 67px;
          word-break: break-all;
          overflow: visible;
        }
      }
    }
    .apply-info {
      margin-top: 16px;
      &-title {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
  .apply-operate {
    margin-top: 10px;
    text-align: center;
    .el-button {
      background-color: #1890ff;
    }
  }
}
</style>
