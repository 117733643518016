<script>
import MessageModal from '@/components/common/message-modal/index.vue';
import * as echarts from 'echarts';
import moment from 'moment';
import { getRandomInt } from '@/utils';
export default {
  name: 'Index',
  components: {
    MessageModal
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    currentMarkInfo: {
      type: Object,
      default: () => undefined
    }
  },
  mounted() {
    this.initEchartsArea();
  },
  methods: {
    initEchartsArea() {
      const currentHour = moment().hour();
      const hoursBeforeCurrentHour = [
        currentHour < 10 ? `0${currentHour}:00` : `${currentHour}:00`
      ];
      for (let i = 1; i <= currentHour; i++) {
        const hour = moment().subtract(i, 'hours').hour();
        hoursBeforeCurrentHour.push(hour < 10 ? `0${hour}:00` : `${hour}:00`);
      }
      const chartDom = document.getElementById('water-echarts');
      const myChart = echarts.init(chartDom);
      const option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: hoursBeforeCurrentHour.reverse(),
          axisLine: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          name: '水位（m）',
          axisLine: {
            show: true
          }
        },
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: 0,
            filterMode: 'filter'
          }
        ],
        series: [
          {
            data: new Array(hoursBeforeCurrentHour.length).fill(0).map((v) => getRandomInt(10, 1)),
            type: 'line',
            areaStyle: {
              color: '#50ABFF'
            }
          }
        ]
      };
      myChart.setOption(option);
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <div class="water-history-modal">
    <message-modal :show="show" title="水位预警" @close="close">
      <div class="water-echarts-wrap">
        <div class="echarts-content">
          <div class="echarts-tooltip">
            <span>水位测点：{{ currentMarkInfo.waterType }}</span>
            <span>标注名称：{{ currentMarkInfo.markName }}</span>
          </div>
          <div id="water-echarts" class="water-echarts"></div>
        </div>
      </div>
    </message-modal>
  </div>
</template>

<style scoped lang="scss">
.water-history-modal {
  .water-echarts-wrap {
    background-color: #2c313b;
    padding: 8px;
    .echarts-content {
      background-color: #0a0b0d;
      overflow: hidden;
      .echarts-tooltip {
        font-size: 14px;
        color: #b3bbc5;
        line-height: 19px;
        margin-top: 20px;
        text-align: center;
        & span:first-child {
          margin-right: 20px;
        }
      }
      .water-echarts {
        height: 400px;
      }
    }
  }
}
</style>
