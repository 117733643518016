<template>
  <div class="download-center" @click.stop>
    <div class="download-center-menu">
      <div class="menu-head">产品</div>
      <div class="menu-content">
        <div
          v-for="(item, index) in menuList"
          :key="index"
          class="menu-item"
          :class="{ 'menu-item-active': menuActive === index }"
          @click="menuItemClick(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div v-if="menuActive" class="download-center-app">
      <ContentHead :head-data="headData" />
      <AppMeta />
      <!--      <SespMeta  v-else/>-->
    </div>
    <div v-else class="download-center-sesp">
      <NewSespMeta />
    </div>
  </div>
</template>

<script>
import ContentHead from './content-head';
import SespMeta from './sesp-meta';
import AppMeta from './app-meta';
import NewSespMeta from './new-sesp-meta';
export default {
  name: 'DownloadCenter',
  components: {
    ContentHead,
    SespMeta,
    AppMeta,
    NewSespMeta
  },
  data() {
    return {
      menuActive: 0,
      menuList: [
        {
          id: 1,
          name: 'SESP-U1无人机仿真软件'
        },
        {
          id: 2,
          name: '四端小程序'
        }
      ],
      headData: {
        img: require('@/assets/home/sesp_u1_logo.png'),
        title: 'SESP-U1',
        meta: '无人机仿真软件'
      }
    };
  },
  methods: {
    menuItemClick(index) {
      switch (index) {
        case 0:
          this.headData = {
            img: require('@/assets/home/sesp_u1_logo.png'),
            title: 'SESP-U1',
            meta: '无人机仿真软件'
          };
          break;
        case 1:
          this.headData = {
            img: require('@/assets/home/min_app_logo.png'),
            title: '四端小程序',
            meta: '云享飞、云飞手、云仓、无人机城'
          };
          break;
      }
      this.menuActive = index;
    }
  }
};
</script>

<style scoped lang="scss">
.download-center {
  width: 100%;
  height: calc(100vh - 48px);
  position: absolute;
  top: 48px;
  left: 0;
  z-index: 99;
  background-color: #f2f5f8;
  padding: 48px 147px 54px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &-menu {
    min-width: 250px;
    height: 100%;
    background-color: #fff;
    margin-right: 30px;
    .menu-head {
      padding: 28px 0 32px 20px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2c313b;
    }
    .menu-content {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2c313b;
      .menu-item {
        height: 32px;
        background: #edeff4;
        line-height: 32px;
        padding-left: 29px;
        cursor: pointer;
        &-active {
          background: linear-gradient(270deg, #008fff 0%, #0254da 100%);
          color: #ffffff;
        }
      }
    }
  }
  &-sesp,
  &-app {
    flex: 1;
    min-width: 1000px;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
  }
  &-app {
    padding: 0 80px;
    box-sizing: border-box;
  }
}
::-webkit-scrollbar {
  background: transparent;
}
::-webkit-scrollbar-track {
  box-shadow: none;
  background: transparent;
}
</style>
