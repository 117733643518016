<template>
  <div class="analysis">
    <div class="title">智能分析</div>
    <div class="pt4 pb5">
      <div class="tc cp" @click="linkageFn">
        <div :class="{ Select_Bg: linkage }" class="w50 h42">
          <img v-if="!linkage" class="mt8" src="./img/fp.png" alt="" />
          <img v-else class="mt8" src="./img/FPON.png" alt="" />
        </div>
        <span class="childTitle">分屏联动</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      linkage: false
    };
  },
  methods: {
    ...mapMutations('subplatform', ['SET_TOTAL_MAPS']),
    resetMapRadio() {
      this.$store.commit('uavApplications/setState', {
        key: 'mapRadio',
        value: 1
      });
    },
    linkageFn() {
      this.linkage = !this.linkage;
      this.$store.commit('subplatform/SET_LINK_AGE', this.linkage);
      if (!this.linkage) {
        this.SET_TOTAL_MAPS(1);
        this.resetMapRadio();
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('subplatform/SET_LINK_AGE', false);
    this.SET_TOTAL_MAPS(1);
    this.resetMapRadio();
  }
};
</script>
<style lang="scss" scoped>
.analysis {
  position: fixed;
  top: 136px;
  right: 4px;
  width: 56px;
  height: 111px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border: 1px solid rgba(52, 223, 255, 1);
  border-radius: 8px;
  // border-image: linear-gradient(180deg, rgba(52, 223, 255, 1), rgba(24, 113, 214, 0)) 1 1;
  backdrop-filter: blur(4px);
  padding: 2px;
  box-sizing: border-box;
  text-align: center;

  .title {
    color: #d4f8ff;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #d4f8ff;
    line-height: 20px;
    border-bottom: 1px solid #000;
  }

  .childTitle {
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #d4f8ff;
    line-height: 20px;
  }

  .bgIMG {
    width: 50px;
    height: 50px;
  }

  .Select_Bg {
    background-image: url('./img/Select_Bg.png');
    background-repeat: no-repeat;
    background-position: 5px 0px;
  }
}
</style>
