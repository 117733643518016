<template>
  <div v-if="isShow" ref="sideBox" class="side-box">
    <!-- <div id="btnBox" ref="btnBox" class="btn-box" @click="showList"> -->
    <!-- <span class="icon iconfont">&#xe68e;</span> -->
    <!-- <span
        class="icon"
        :class="{
          'el-icon-arrow-right': isShow,
          'el-icon-arrow-left': !isShow,
        }"
      ></span> -->
    <!-- <span>显示列表</span> -->
    <!-- </div> -->
    <div id="listBox" ref="listBox" class="list-box">
      <div class="header">
        <div v-if="navIndex">
          <span>{{ navIndex ? navName : '' }}列表</span>
        </div>
        <!-- <div
          class="btn"
          @click="showList"
        >
          <span class="icon iconfont">&#xe6cc;</span>
          <span>隐藏列表</span>
        </div> -->
      </div>

      <div class="body">
        <!-- 导航列表 -->
        <div v-if="navIndex" class="tb-box">
          <div v-if="navIndex == 1" class="tb-hd-box">
            <div class="tb-hd">{{$t('header.serialNumber')}}</div>
            <div class="tb-hd">{{$t('flightApplication.uavName')}}</div>
            <div class="tb-hd">健康状态</div>
            <div class="tb-hd">联网状态</div>
            <div v-if="isLogin" class="tb-hd">{{$t('operation.Operation')}}</div>
          </div>
          <div v-if="navIndex == 2" class="tb-hd-box">
            <div class="tb-hd">{{$t('header.serialNumber')}}</div>
            <div class="tb-hd">飞手名称</div>
            <div class="tb-hd">手机号码</div>
          </div>
          <div v-if="navIndex == 3 || navIndex == 4 || navIndex == 5" class="tb-hd-box">
            <div class="tb-hd">{{$t('header.serialNumber')}}</div>
            <div class="tb-hd">网点名称</div>
            <div class="tb-hd">业务描述</div>
            <div class="tb-hd">{{$t('operation.Operation')}}</div>
          </div>
          <div v-if="navIndex == 6" class="tb-hd-box">
            <div class="tb-hd">{{$t('header.serialNumber')}}</div>
            <div class="tb-hd">{{$t('industryApplication.projectName')}}</div>
            <div class="tb-hd">{{$t('operation.Operation')}}</div>
          </div>
          <div class="tb-bd-box" style="height: 800px">
            <div v-for="(item, index) in rightPointsList" :key="index" class="tb-tr">
              <div class="td">{{ index + 1 || $t('home.no') }}</div>
              <div class="td">{{ item.carNo || item.name || $t('home.no') }}</div>
              <div v-if="navIndex !== 6" class="td">
                {{
                  navIndex == 2
                    ? item.phone_num
                    : false || navIndex == 1
                    ? item.healthStatus
                    : false || $t('home.no')
                }}
              </div>
              <div v-if="navIndex == 1" class="td">
                {{ item.online }}
              </div>
              <div v-if="navIndex !== 2 && navIndex !== 6 && isLogin" class="td">
                <el-tooltip v-hover effect="dark" :content="$t('operation.Detail')" placement="top">
                  <div class="iconfont icon-chakan" @click="goDetails(item)" />
                </el-tooltip>
              </div>
              <div v-if="navIndex == 6" class="td">
                <a style="color: #336696" @click="goDetails(item)">{{$t('operation.Check')}} </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 窗口列表 -->
    <div v-if="isShow" class="tl-box">
      <div v-if="sidebarStatus == 1" v-interact class="task-create-box">
        <!-- <div>创建任务</div> -->
        <FlightTaskCreate :target="0" :type="0" />
      </div>
      <div v-if="sidebarStatus == 100" v-interact class="task-create-box">
        <!-- <div>创建任务</div> -->
        <FlightTaskCreate :target="1" :type="0" />
      </div>
      <div v-if="sidebarStatus == 101" v-interact class="task-create-box">
        <!-- <div>创建任务</div> -->
        <FlightTaskCreate :target="1" :type="1" />
      </div>
      <div v-if="sidebarStatus == 102" v-interact class="task-create-box">
        <!-- <div>创建任务</div> -->
        <FlightTaskCreate :target="1" :type="2" />
      </div>
      <div v-if="sidebarStatus == 2" v-interact class="task-edit-box">
        <!-- <div>编辑任务</div> -->
        <FlightTaskEdit :target="0" :type="0" />
      </div>
      <div v-if="sidebarStatus == 200" v-interact class="task-edit-box">
        <!-- <div>编辑任务</div> -->
        <FlightTaskEdit :target="1" :type="0" />
      </div>
      <div v-if="sidebarStatus == 201" v-interact class="task-edit-box">
        <!-- <div>编辑任务</div> -->
        <FlightTaskEdit :target="1" :type="1" />
      </div>
      <div v-if="sidebarStatus == 202" v-interact class="task-edit-box">
        <!-- <div>编辑任务</div> -->
        <FlightTaskEdit :target="1" :type="2" />
      </div>

      <div v-if="sidebarStatus == 3" v-interact class="line-create-box">
        <!-- <div>创建航线</div> -->
        <AirwayEditPopup />
      </div>
      <div v-if="sidebarStatus == 4" v-interact class="line-edit-box">
        <!-- <div>编辑航线</div> -->
        <AirwayEditChange />
      </div>
      <div v-if="sidebarStatus == 5" v-interact class="line-edit-box">
        <!-- <div>创建项目</div> -->
        <CreateProject :edit-data="editProjectData" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Tg, projectAPI } from '@/api';
import FlightTaskCreate from '@/views/uavApplications/components/flight-popup2/flight-task-create';
import FlightTaskEdit from '@/views/uavApplications/components/flight-popup2/flight-task-edit';
import AirwayEditPopup from '../airway-edit2/airway-popup';
import AirwayEditChange from '../airway-edit2/airway-change';
import CreateProject from '../project-list/create-project';
export default {
  name: 'SideList',
  components: {
    FlightTaskCreate,
    FlightTaskEdit,
    AirwayEditPopup,
    AirwayEditChange,
    CreateProject
  },
  data() {
    return {
      isShow: false,
      navIndex: null,
      navName: '',
      sideList: [],
      rightPointsList: [],
      isLogin: false,
      navId: ''
    };
  },
  inject: ['change_active', 'get_sidelist_item'],
  computed: {
    ...mapState(['sidebarStatus', 'editProjectData', 'leftMenuAction']),
    ...mapState('subplatform', ['subplatFormStatus', 'subplatFormActive'])
  },
  watch: {
    sidebarStatus(val) {
    },
    isShow(val) {
      this.$bus.$emit('handleControlBar', val);
      if (val) {
        this.$refs.listBox.style.width = '439px';
        this.$refs.sideBox.style.width = '439px';
        this.$refs.listBox.style.height = 'calc(100% - 4px)';
        this.$refs.sideBox.style.height = 'calc(100% - 52px)';
        this.$refs.btnBox.style.right = '344px';
        this.$refs.btnBox.style.borderRadius = '50%';
      } else {
        this.$refs.listBox.style.width = '112px';
        this.$refs.sideBox.style.width = '112px';
        this.$refs.listBox.style.height = '0px';
        this.$refs.sideBox.style.height = '';
        this.$refs.btnBox.style.right = '-10px';
        this.$refs.btnBox.style.borderRadius = '40%';
        this.SET_OPEN_RIGHT_NAV_SILDER(false);
      }
    },
    leftMenuAction(val) {
      if (val !== 3) this.isShow = false;
    }
  },

  mounted() {
    this.isLogin = !!window.localStorage.getItem('tmj_token');
    this.getItemList();
    this.searchList();
    this.showAllData();
    this.$bus.$on('openSideList', () => {
      this.isShow = true;
      this.navIndex = null;
    });
    this.$bus.$on('closeSideList', () => {
      this.isShow = false;
    });
  },
  methods: {
    ...mapMutations(['SET_OPEN_RIGHT_NAV_SILDER']),
    // 详情点击
    async goDetails(item) {
      console.log(this.navId);
      if (this.navId == 1) {
        // 展示无人机
        this.change_active(10);
        this.$nextTick(() => {
          this.$bus.$emit('uavDataDetails', item);
        });
      } else if (this.navId === 6) {
        this.change_active(13);
        const res = await projectAPI.getRunProject(item.id);
        if (res.code === 200) {
          this.$nextTick(() => {
            this.$bus.$emit('runProjectDetails', res.data);
          });
        }
      } else {
        // 展示网点详细
        // this.get_sidelist_item(item)
        this.change_active(11);
        console.log('==============', item);
        this.$nextTick(() => {
          this.$bus.$emit('networkDataLils', item);
        });
      }
    },
    showAllData() {
      this.$bus.$on('showSideList', () => {
        this.rightPointsList = this.sideList;
      });
    },
    searchList() {
      this.$bus.$on('handleSearchItemList', (obj) => {
        // console.log("handleSearchItemList..............", obj);
        let selectPiontsArr = [];
        obj.features.forEach((val) => {
          const positions = val.geometry.coordinates;
          // console.log(positions)
          this.sideList.forEach((element) => {
            if (element.lon == positions[0] && element.lat == positions[1]) {
              selectPiontsArr.push(element);
            }
          });
        });
        // console.log("selectPiontsArr...", selectPiontsArr)
        this.rightPointsList = selectPiontsArr;
        selectPiontsArr = [];
      });
    },
    getItemList() {
      this.$bus.$on('handleItemList', (list, show, e) => {
        this.navId = e.id;
        this.sideList = list;
        this.rightPointsList = this.sideList;
        this.navIndex = show;
        this.navName = e.name;
      });
    },
    showList() {
      if (this.navIndex || this.sidebarStatus) {
        // this.isShow = !this.isShow;
        // this.SET_OPEN_RIGHT_NAV_SILDER(this.isShow);
      } else {
        this.$message.warning('请选择需要展示的列表');
      }

      // this.getAiList()
      // this.getUavList()
    },

    async getAiList() {
      // console.log("获取Ai列表......")
      const res = await Tg.getAiIconListFromAdmin({
        currentPage: 1,
        pageSize: 10
      });
      conesole.log('Ai列表.......', res);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/list-common.scss';

.side-box {
  position: absolute;
  top: 52px;
  right: 6px;
  // bottom: 4px;
  width: 112px;
  -moz-transition: width 0.25s;
  /* Firefox 4 */
  transition: width 0.25s;
  /* Safari and Chrome */
  -o-transition: width 0.25s;

  /* Opera */
  .btn-box {
    /* float: right; */
    position: fixed;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border-radius: 40%;
    display: flex;
    justify-content: space-evenly;
    width: 32px;
    height: 32px;
    top: 50%;
    right: -10px;
    -moz-transition: right 0.25s;
    /* Firefox 4 */
    transition: right 0.25s;
    /* Safari and Chrome */
    -o-transition: right 0.25s;
    /* Opera */
    transform: translateY(-50%);
    border: 1px solid #34dfff;
    box-sizing: border-box;

    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .list-box {
    width: 0px;
    // height: calc(100% - 4px);
    top: 50px;
    left: 2px;
    overflow: hidden;
    /* border: 1px solid #34dfff; */
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border-radius: 8px;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid #23364b;
      width: 439px;

      // background: rgba(19, 21, 24, 0.7);
      div {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #ebf5ff;
        letter-spacing: 0;
        font-weight: 400;
        margin: 0 8px;

        span {
          margin: 0 6px;
          line-height: 32px;
          height: 32px;
        }
      }

      .btn {
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .body {
      margin-top: 30px;
      height: 100%;
    }
  }
}
.tl-box {
  width: 400px;
  /* height: 500px; */
  top: 15vh;
  left: 50vw;
  /* overflow: hidden; */
  border: 1px solid #34dfff;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;
  position: fixed;
  top: 100px;
  right: 100px;
  .task-create-box {
    height: calc(100% - 36px);
  }

  .task-edit-box {
    height: calc(100% - 36px);
  }

  .line-create-box {
    height: calc(100% - 36px);
  }

  .line-edit-box {
    height: calc(100% - 36px);
  }
}
</style>
