<!--  -->
<template>
  <el-collapse-transition>
    <div class="ailist">
      <!-- <el-checkbox-group v-model="checkList"
    size="small"

    boder> -->
      <el-checkbox
        v-for="(item, index) in AiList"
        :key="index"
        class="item_ailist"
        @change="(val, data) => change(val, item.name)"
        >{{ item.name }}</el-checkbox
      >
    </div>
  </el-collapse-transition>
</template>

<script>
export default {
  name: '',
  // inject: ["is_active_ailist"],
  data() {
    return {
      // isAILIST:,
      AiList: [
        {
          name: '人脸识别'
        },
        {
          name: '车牌识别'
        },
        {
          name: '人流识别'
        },
        {
          name: '车流识别'
        },
        {
          name: '垃圾识别'
        },
        {
          name: '违章识别'
        },
        {
          name: '烟雾识别'
        },
        {
          name: '占道经营'
        },
        {
          name: '火焰识别'
        },
        {
          name: '漂浮物识别'
        },
        {
          name: '裸土识别'
        },
        {
          name: '摊位识别'
        },
        {
          name: '沿街晾挂'
        },
        {
          name: '店外经营'
        },
        {
          name: '船只检测'
        }
      ],

      isIndeterminate: '',
      checkAll: '',
      checkList: ['选中且禁用', '复选框 A']
    };
  },
  computed: {},
  // 生命周期 - 创建完成（访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {},

  methods: {
    change(x, y) {}
  }
};
</script>
<style lang="scss" scoped>
// .transition-box {
//   margin-bottom: 10px;
//   width: 200px;
//   height: 100px;
//   border-radius: 4px;
//   background-color: #409eff;
//   text-align: center;
//   color: #fff;
//   padding: 40px 20px;
//   box-sizing: border-box;
//   margin-right: 20px;
// }

/* @import url(); 引入css类 */
.ailist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 114px;
  // height: 472px;
  background: red;
  position: relative;
  left: calc(100% - 200px);
  top: 30%;
  background: #1e2026;
  border: 1px solid #50abff;
  border-radius: 4px;

  .item_ailist {
    // margin: 0 auto;
    margin-left: 10px;
  }
}
</style>
