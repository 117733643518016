<template>
  <div class="ball_box">
    <!--    避障功能-->
    <div class="ball">
      <img
        class="img"
        src="~./assets/lunpan.png"
        style="width: 100%"
        :style="{
          transform: `rotate(${props_obj.yaw}deg)`
        }"
      />
      <div
        v-for="item in triggerList"
        :key="item.angle"
        class="spot-box"
        :style="{ transform: `rotate(${item.angle}deg)` }"
      >
        <div class="spot" :style="{ bottom: `${item.spotPos}%` }" />
      </div>
    </div>
    <div class="pointer">
      <img src="~./assets/zhi.png" style="width: 100%; height: 50%" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    uavData: {
      type: Object,
      default: () => {}
    },
    obstacle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    /**
     * 触发方向列表
     */
    triggerList() {
      if (!this.obstacle) {
        console.log('if(!this.obstacle)');
        return [];
      }

      /* if(this.uav.control.device.deviceHardId !== this.obstacle.deviceHardId){
        console.log('if(this.uav.control.device.deviceHardId !== this.obstacle.deviceHardId)')
        return [];
      } */

      let list = this.obstacle.distances.map((distance, i) => {
        return {
          angle: i * 10, // 角度
          distance: distance, // 距离
          spotPos: distance / this.$store.state.fckernel.obstacle.obsDistance
        };
      });

      list = list.filter((item) => {
        // 判断每个10度是否出现障碍物
        return item.distance < this.obstacle.obsDistance * 100;
      });
      return list;
    },
    props_obj() {
      const { uavData } = this;
      if (uavData && uavData.attitude && uavData.locationCoordinate3D) {
        return {
          ...uavData.attitude,
          ...uavData.locationCoordinate3D,
          flyDistance: uavData.flyDistance,
          flyTime: uavData.flyTime / 1000,
          velocityX: uavData.velocityX,
          velocityY: uavData.velocityY,
          velocityZ: uavData.velocityZ
        };
      }
      return {};
    }
  },
  mounted() {
    // 模拟数据
    /* let data = {
      deviceHardId: 'MMC021F1022',
      distances: [],
      enable: false,
      obsDistance: 15
    }
    for(let i = 0; i < 36; i++){
      data.distances.push(i * 100);
    }
    this.$store.commit('fckernel/SET_OBSTACLE', data); */
  },
  methods: {}
};
</script>
<style scoped lang="scss">
@keyframes twinkle {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.ball_box {
  position: relative;
  box-sizing: border-box;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: space-between;
  .ball {
    height: 100%;
    width: 150px;
    //background-color: #fff;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    position: relative;

    .img {
      height: 100%;
      //width: 160px;
      position: inherit;
      transition: 1s;
    }

    .img4 {
      height: 100%;
      position: absolute;
    }
    .keducon {
      height: 80%;
      width: 80%;
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      left: 10%;
      top: 10%;
    }
    .kedu {
      position: absolute;
      width: 125%;
      height: 125%;
      left: -10%;
      top: -12%;
      z-index: 2;
    }

    .img2 {
      position: absolute;
      left: calc(49%);
      width: 5px;
      height: 10px;
    }
    .img1 {
      height: 20px;
      width: 72px;
      position: absolute;
      z-index: 3;
      top: calc(50% - 20px);
      left: calc(50% - 36px);
    }

    .spot-box {
      position: absolute;
      height: calc(50% - 31px);
      width: 0px;
      left: 50%;
      top: 31px;
      transform-origin: bottom;

      .spot {
        position: absolute;
        height: 8px;
        width: 8px;
        left: 50%;
        // top: 50%;
        transform: translate(-50%, -50%);
        background-color: #ff3636;
        box-shadow: 0 0 2px 2px rgba(255, 0, 0, 0.34);
        border-radius: 50%;
        animation: twinkle 1s infinite;
      }
    }
  }
  .position {
    height: 100%;
    width: 58.1%;
    margin-left: 4.6%;
    background: #296d97;
    box-shadow: 0 2px 8px 1px rgba(11, 48, 77, 0.7);
    border-radius: 10px;

    .first {
      display: flex;
      height: 50%;
      align-items: center;

      svg {
        margin-left: 2rem /* 32/16 */;
      }

      .text {
        margin-left: 2rem /* 32/16 */;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #d9ebff;
        letter-spacing: 0;
        text-align: left;
        font-weight: 400;
      }
    }

    .divider {
      border-top: 1px solid #0e4465;
      height: 1px;
      width: 100%;
    }
  }
}
.pointer {
  width: 16px;
  height: 116px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: trasnlate(-50%, -50%);
  // -webkit-transform-origin:20%, 40% !important;
  animation: zhuan 5s ease infinite;
}
@keyframes zhuan {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
