export default {
  // 预览word
  configOptions: {
    toolbar: true,
    width: '800px',
    height: '600px'
  },
  reportOptions: [
    {
      value: 'name',
      label: '标注名称'
    },
    {
      value: 'level',
      label: '等级'
    }
  ],
  options: [
    {
      value: 0,
      label: '全部'
    },
    {
      value: 1,
      label: '一级'
    },
    {
      value: 2,
      label: '二级'
    },
    {
      value: 3,
      label: '三级'
    },
    {
      value: 4,
      label: '四级'
    },
    {
      value: 5,
      label: '五级'
    }
  ],
  table: {
    POINT: ['基本信息', '样式设置', '弹出设置'],
    LINESTRING: ['基本信息', '样式设置', '弹出设置'],
    POLYGON: ['基本信息', '样式设置', '弹出设置']
  },
  entitieType: {
    POINT: 'point',
    LINESTRING: 'polyline',
    POLYGON: 'polygon'
  },
  rules: {
    code: [{ required: true, message: '请输入编号', trigger: 'blur' }],
    markName: [{ required: true, message: '请输入标注名称', trigger: 'blur' }]
  },
  img: require('@/assets/three-dimensional/acquiescent.png'),
  dialogImg: require('@/assets/three-dimensional/acquiescent.png')
};
