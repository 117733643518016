<script>
import G6 from '@antv/g6';
export default {
  name: 'Index',
  props: {
    msgTaskDetail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      commonStartOrEndNode: {
        type: 'start-or-end',
        size: [500, 300],
        status: '',
        name: ''
      },
      commonDiamondNode: {
        type: 'diamondSelf',
        size: [500, 500],
        status: '',
        name: ''
      },
      commonRectNode: {
        type: 'rectSelf',
        size: [500, 300],
        status: '',
        name: ''
      }
    };
  },
  computed: {
    convertFlowData() {
      return this.msgTaskDetail?.taskOperators?.reduce(
        (pre, cur, index, arr) => {
          if (cur.functionType === 0) {
            pre.nodes.push({
              ...this.commonStartOrEndNode,
              name: cur.operatorName,
              id: `flow-node-${index}`,
              status: cur.labelName,
              flowItem: cur,
              fill: this.getFillColor(cur.isOperate),
              stroke: this.getStrokeColor(cur.isOperate)
            });
          } else if (cur.functionType === 1) {
            pre.nodes.push({
              ...this.commonDiamondNode,
              name: cur.operatorName,
              id: `flow-node-${index}`,
              status: cur.labelName,
              flowItem: cur,
              fill: this.getFillColor(cur.isOperate),
              stroke: this.getStrokeColor(cur.isOperate)
            });
          } else {
            pre.nodes.push({
              ...this.commonRectNode,
              name: cur.operatorName,
              id: `flow-node-${index}`,
              status: cur.labelName,
              flowItem: cur,
              fill: this.getFillColor(cur.isOperate),
              stroke: this.getStrokeColor(cur.isOperate)
            });
          }
          if (index !== arr.length - 1) {
            pre.edges.push({
              source: `flow-node-${index}`,
              target: `flow-node-${index + 1}`,
              style: {
                fill: this.getLineColor(cur.isOperate),
                stroke: this.getLineColor(cur.isOperate),
                endArrow: true,
                lineWidth: 15,
                offset: 50,
                radius: 10
              },
              targetAnchor: 2,
              sourceAnchor: 1
            });
          }
          if (cur.isOperate === 3) {
            pre.edges.push({
              source: `flow-node-${index}`,
              target: 'flow-node-0',
              style: {
                stroke: 'red',
                endArrow: true,
                lineWidth: 15,
                offset: 50,
                radius: 10
              },
              targetAnchor: 0,
              sourceAnchor: 0,
              type: 'polyline',
              color: 'red',
              // controlPoints: [
              //   {
              //     x: 600,
              //     y: 220
              //   }
              // ],
              label: '驳回',
              labelCfg: {
                refX: 20, // 文本在 x 方向偏移量
                refY: 10, // 文本在 y 方向偏移量
                style: {
                  fill: 'red',
                  fontSize: 50
                }
              }
            });
          }
          return pre;
        },
        { nodes: [], edges: [] }
      );
    }
  },
  watch: {
    msgTaskDetail(value) {
      if (value) {
        this.initFlow();
      }
    }
  },
  methods: {
    initFlow() {
      const that = this;
      // 注册节点。类型为开始或者结束类型
      G6.registerNode(
        'start-or-end',
        {
          drawShape(cfg, group) {
            const width = cfg?.size?.[0];
            const height = cfg?.size?.[1];
            const stroke = cfg?.stroke;
            const fill = cfg?.fill;
            const rect = group.addShape('rect', {
              attrs: {
                x: -width / 2,
                y: -height / 2,
                width,
                height,
                radius: height / 2,
                stroke,
                fill,
                lineWidth: 1
              },
              name: 'start-or-end'
            });
            group.addShape('text', {
              attrs: {
                text: cfg.status,
                fill: '#fff',
                fontSize: 55,
                textAlign: 'center',
                lineHeight: 50
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'text-shape'
            });
            group.addShape('text', {
              attrs: {
                text: cfg.name,
                fill: '#fff',
                fontSize: 45,
                textAlign: 'center',
                y: 70
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'text-shape'
            });
            return rect;
          },
          getAnchorPoints() {
            return [
              [1, 0.5], // 右侧中间
              [0.5, 1]
            ];
          }
        },
        'single-node'
      );
      G6.registerNode(
        'rectSelf',
        {
          drawShape(cfg, group) {
            const width = cfg?.size?.[0];
            const height = cfg?.size?.[1];
            const stroke = cfg?.stroke;
            const fill = cfg?.fill;
            const rect = group.addShape('rect', {
              attrs: {
                x: -width / 2,
                y: -height / 2,
                width,
                height,
                radius: 10,
                stroke,
                fill,
                lineWidth: 1
              },
              name: 'rectSelf'
            });
            group.addShape('text', {
              attrs: {
                text: cfg.status,
                fill: '#fff',
                fontSize: 55,
                textAlign: 'center',
                lineHeight: 50
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'text-shape'
            });
            group.addShape('text', {
              attrs: {
                text: cfg.name,
                fill: '#fff',
                fontSize: 45,
                textAlign: 'center',
                y: 70
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'text-shape'
            });
            return rect;
          }
        },
        'single-node'
      );
      G6.registerNode(
        'diamondSelf',
        {
          drawShape(cfg, group) {
            const width = cfg?.size?.[0];
            const height = cfg?.size?.[1];
            const rect = group?.addShape('path', {
              attrs: {
                path: that.getPath(cfg),
                fill: cfg.fill,
                stroke: cfg.stroke
              },
              name: 'polygon-shape'
            });
            group.addShape('text', {
              attrs: {
                text: cfg.status,
                fill: '#fff',
                fontSize: 55,
                textAlign: 'center',
                lineHeight: 50
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'dom-shape'
            });
            group.addShape('text', {
              attrs: {
                text: cfg.name,
                fill: '#fff',
                fontSize: 45,
                textAlign: 'center',
                y: 70
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'text-shape'
            });
            // group.addShape("dom", {
            //   attrs: {
            //     width,
            //     height,
            //     x: -width / 2,
            //     y: -height / 2,
            //     html: `
            //             <div id="${cfg.id}" class="flow-node" style="width: ${cfg.size[0]}px; height: ${cfg.size[1]}px;display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 25px;box-sizing: border-box;cursor:pointer">
            //               <div style="color: #fff;font-size: 30px">${cfg.status}</div>
            //               <div style="color: #fff;font-size: 25px">${cfg.name}</div>
            //             </div>
            //               `,
            //   },
            //   // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
            //   name: "dom-shape",
            // });
            return rect;
          },
          getAnchorPoints() {
            return [
              [1, 0.5],
              [0.5, 1],
              [0.5, 0]
            ];
          }
        },
        'single-node'
      );
      const width = 700;
      const height = this.convertFlowData.nodes.length > 3 ? 700 : 500;
      const graph = new G6.Graph({
        container: 'task-flow',
        width,
        height,
        layout: {
          type: 'dagre',
          nodesepFunc: (d) => {
            if (d.id === '3') {
              return 500;
            }
            return 50;
          },
          ranksep: 70,
          controlPoints: false
        },
        defaultNode: {
          type: 'sql'
        },
        defaultEdge: {
          type: 'line',
          endArrow: true
        },
        nodeStateStyles: {
          selected: {
            stroke: '#d9d9d9',
            fill: '#5394ef'
          }
        },
        // 流程节点描述
        modes: {
          default: [
            'drag-canvas',
            'zoom-canvas'
            // 'click-select',
            // {
            //   type: 'tooltip',
            //   formatText(model) {
            //     const cfg = model.conf;
            //     const text = [];
            //     cfg.forEach((row) => {
            //       text.push(row.label + ':' + row.value + '<br>');
            //     });
            //     return text.join('\n');
            //   },
            //   offset: 30
            // }
          ]
        },
        fitView: true
      });
      graph.data(this.convertFlowData);
      graph.render();
      graph.on('node:click', (evt) => {
        const nodeItem = this.convertFlowData.nodes.find((v) => v.id === evt.item?._cfg?.id);
        if (nodeItem.type !== 'start-or-end') {
          this.$emit('processNodeModalShowClick', nodeItem.flowItem);
        }
      });
    },
    // 返回菱形的路径
    getPath(cfg) {
      const size = cfg.size || [40, 40]; // 如果没有 size 时的默认大小
      const width = size[0];
      const height = size[1];
      //  / 1 \
      // 4     2
      //  \ 3 /
      const path = [
        ['M', 0, 0 - height / 2], // 上部顶点
        ['L', width / 2, 0], // 右侧顶点
        ['L', 0, height / 2], // 下部顶点
        ['L', -width / 2, 0], // 左侧顶点
        ['Z'] // 封闭
      ];
      return path;
    },

    // 区分执行跟未执行的颜色
    getFillColor(isOperate) {
      switch (isOperate) {
        case 0:
          return '#0E290E';
        case 1:
          return '#9F9F9F';
        case 2:
          return '#0E290E';
        case 3:
          return '#0E290E';
        default:
      }
    },
    // eslint-disable-next-line consistent-return
    getStrokeColor(isOperate) {
      switch (isOperate) {
        case 0:
          return '#44D078';
        case 1:
          return '#C9C9C9';
        case 2:
          return '#44D078';
        case 3:
          return '#44D078';
        default:
      }
    },

    // 获取连线颜色
    getLineColor(isOperate) {
      switch (isOperate) {
        case 0:
          return '#52C2E9';
        case 1:
          return '#C2C8D5';
        case 2:
          return '#52C2E9';
        case 3:
          return '#C2C8D5';
        default:
      }
    }
  }
};
</script>

<template>
  <div class="task-flow-wrap">
    <div id="task-flow" ref="taskFlow" class="task-flow" />
    <div class="flow-logotype">
      <div class="logotype-item">
        <div class="logotype-item-icon rect-active" />
        <div class="logotype-item-label">已操作</div>
      </div>
      <div class="logotype-item">
        <div class="logotype-item-icon rect-default" />
        <div class="logotype-item-label">未操作</div>
      </div>
      <div class="logotype-item">
        <div class="logotype-item-icon arrow-active">
          <div class="line" />
          <div class="arrow" />
        </div>
        <div class="logotype-item-label">通过</div>
      </div>
      <div class="logotype-item">
        <div class="logotype-item-icon arrow-default">
          <div class="line" />
          <div class="arrow" />
        </div>
        <div class="logotype-item-label">未开始</div>
      </div>
      <div class="logotype-item">
        <div class="logotype-item-icon arrow-error">
          <div class="line" />
          <div class="arrow" />
        </div>
        <div class="logotype-item-label">驳回</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.task-flow-wrap {
  position: relative;
  .task-flow {
    background-color: #0a0b0d;
  }
  .flow-logotype {
    position: absolute;
    top: 10px;
    left: 10px;
    .logotype-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: #fff;
    }
    .rect-active,
    .rect-default {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
    .rect-active {
      background-color: #44d078;
    }
    .rect-default {
      background-color: #c9c9c9;
    }
    .arrow-active,
    .arrow-default,
    .arrow-error {
      display: flex;
      align-items: center;
      margin-right: 5px;
      .line {
        width: 5px;
        height: 2px;
        background-color: #52c2e9;
      }
      .arrow {
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-left: 5px solid #52c2e9;
      }
    }
    .arrow-default {
      .line {
        background-color: #c2c8d5;
      }
      .arrow {
        border-left-color: #c2c8d5;
      }
    }
    .arrow-error {
      .line {
        background-color: red;
      }
      .arrow {
        border-left-color: red;
      }
    }
  }
}
</style>
