<template>
  <div class="app-meta">
    <div class="app-meta-card">
      <div v-for="(item, index) in cardInfoList" :key="index" class="card-item">
        <img src="@/assets/home/app_meta_bg.png" />
        <div class="card-item-info">
          <div class="img">
            <img :src="item.img" />
          </div>
          <div class="title">
            {{ item.title }}
          </div>
          <div class="meta">
            {{ item.meta }}
          </div>
        </div>
      </div>
    </div>
    <div class="app-meta-download">
      <div class="app-meta-zip">
        <img src="@/assets/home/zip.png" />
        <span class="meta"> 四端小程序资料包</span>
      </div>
      <div class="app-meta-btn">
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="downloadClick('https://pan.baidu.com/s/1XjnNS4IpkAbeLmZsemzllw?pwd=vc6b')"
          >点击下载</el-button
        >
      </div>
    </div>
    <!--    <div class="app-meta-code">-->
    <!--      <div class="code-item" v-for="(item,index) in shareCodeInfoList" :key="index">-->
    <!--        <div class="code-item-img">-->
    <!--          <img :src="item.img">-->
    <!--        </div>-->
    <!--        <div class="code-item-title">{{item.title}}</div>-->
    <!--        <div class="code-item-option" @click="downloadClick(item)">-->
    <!--&lt;!&ndash;          <el-button  icon="el-icon-download" size="mini">下载</el-button>&ndash;&gt;-->
    <!--          <img src="@/assets/home/download-icon.png"/>-->
    <!--          <div>下载</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
export default {
  data() {
    return {
      cardInfoList: [
        {
          img: require('@/assets/home/share_fly_code.png'),
          title: '云享飞（用服务）',
          meta: '150+无人机应用场景解决方案，飞行作业进度实时查看，助力政企类客户在电力、建筑等行业数字可视化发展。'
        },
        {
          img: require('@/assets/home/could_share_flyer.png'),
          title: '云飞手（飞手赚钱）',
          meta: '支持一键下单采购无人机硬件产品，实时同步生产进度，具备完整的产业链制造能力及全球生态产品圈，支持OEM/ODM定制。'
        },
        {
          img: require('@/assets/home/could_store_code.png'),
          title: '云仓（租设备）',
          meta: '多品类无人机设备，支持一键在线租用，产品库存足且发货快，租赁价格超优惠，更可支持闲置产品合作上架，赚取佣金。'
        },
        {
          img: require('@/assets/home/mmc_store_code.png'),
          title: '无人机城（买设备）',
          meta: '海量无人机服务订单接单平台，订单任务状态实时同步，任务结算周期短，在线提现秒到账。'
        }
      ],
      shareCodeInfoList: [
        {
          img: require('@/assets/home/share_fly_code.png'),
          title: '云享飞小程序二维码'
        },
        {
          img: require('@/assets/home/could_share_flyer.png'),
          title: '云飞手小程序二维码'
        },
        {
          img: require('@/assets/home/could_store_code.png'),
          title: '云仓小程序二维码'
        },
        {
          img: require('@/assets/home/mmc_store_code.png'),
          title: '无人机城小程序二维码'
        }
      ]
    };
  },
  methods: {
    downloadClick(url) {
      // saveAs(url, '四端小程序资料包');
      // window.location = url
      window.open(url);
    }
  }
};
</script>

<style scoped lang="scss">
.app-meta {
  margin-bottom: 124px;
  &-card {
    margin-top: 55px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .card-item {
      flex: 1;
      height: 409px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      &-info {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50px;
        bottom: 40px;
        .img {
          img {
            width: 100px;
            height: 100px;
          }
        }
        .title {
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          line-height: 25px;
          margin: 19px 0 21px 0;
        }
        .meta {
          margin: 0 auto;
          width: 70%;
          font-size: 15px;
          font-weight: 400;
          text-align: left;
          word-break: break-all;
        }
      }
    }
  }
  &-download {
    padding: 56px 0 38px 0;
    .app-meta-zip {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50px;
        height: 38px;
        margin-right: 30px;
      }
      span {
        font-size: 18px;
        color: #2c313b;
      }
    }
    .app-meta-btn {
      text-align: center;
      margin-top: 36px;
      button {
        width: 478px;
        height: 44px;
        background: #1890ff;
        border-radius: 4px;
        border: 1px solid #3bc1e5;
      }
    }
  }
  //&-code{
  //  margin-top: 26px;
  //  display: flex;
  //  align-items: center;
  //  .code-item{
  //    flex: 1;
  //    display: flex;
  //    flex-direction: column;
  //    align-items: center;
  //    &-img{
  //      img{
  //        width: 120px;
  //        height: 120px;
  //      }
  //    }
  //    &-title{
  //      margin: 20px 0;
  //      font-size: 13px;
  //      font-weight: 400;
  //      color: #000000;
  //      line-height: 21px;
  //    }
  //    &-option{
  //      display: flex;
  //      align-items: center;
  //      justify-content: center;
  //      cursor: pointer;
  //      width: 52px;
  //      height: 21px;
  //      background: #EEF5FF;
  //      border-radius: 3px;
  //      border: 1px solid #1461E2;
  //      font-size: 12px;
  //      font-weight: 400;
  //      color: #1561E2;
  //      img{
  //        width: 10px;
  //        height: 10px;
  //        margin-right: 5px;
  //      }
  //    }
  //  }
  //}
}
</style>
