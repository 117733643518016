<template>
  <div class="throttle-gauge">
    <PositionBall class="ball" />
    <ThrottleValue style="position: absolute; left: -70px; top: -70px" />
    <img class="img1" src="./assets/ball2.png" />
  </div>
</template>

<script>
import PositionBall from './ball';
import ThrottleValue from './throttleValue';

export default {
  name: 'ThrottleGauge',
  components: {
    PositionBall,
    ThrottleValue
  },
  props: {}
};
</script>

<style scoped>
.throttle-gauge {
  position: relative;
}

.img1 {
  position: absolute;
  left: -11px;
  top: 34px;
  width: 8px;
  height: 9px;
  z-index: 9999;
  transform: rotate(110deg);
}
.img2 {
  position: absolute;
  left: -7px;
  top: 36px;
  width: 8px;
  height: 9px;
  z-index: 9999;
  transform: rotate(-67deg);
}
</style>
