<template>
  <div class="uav-control">
    <el-tree
      :data="data"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :render-content="renderContent"
      :props="{ label: 'name', children: 'childs' }"
    />

    <!-- <el-dialog
      :visible.sync="dialogTableVisible"
      custom-class="flight-log"
      width="1073px"
      :modal="false"
      :close-on-click-modal="false"
      :append-to-body="false"
    > -->
    <div v-if="dialogTableVisible" class="flight-log">
      <div v-interact class="title">
        <span class="iconfont icon-guanbi1 close" @click="dialogTableVisible = false" />
      </div>
      <div class="search">
        <div class="btn-serach">
          <div class="btn-item" :class="{ active: search.type === 1 }" @click="changeType(1)" >
            {{$t('flightApplication.本月')}}
          </div>
          <div class="btn-item" :class="{ active: search.type === 2 }" @click="changeType(2)">
            {{$t('flightApplication.本周')}}
          </div>
          <div class="btn-item" :class="{ active: search.type === 3 }" @click="changeType(3)">
            {{$t('flightApplication.今日')}}
          </div>
        </div>
        <div class="date-serach">
          {{$t('flightApplication.日期')}}
          <el-date-picker
            v-model="searchDate"
            class="button-date"
            type="datetimerange"
            format="yyyy-MM-dd HH:MM:SS"
            value-format="yyyy-MM-dd HH:MM:SS"
            :range-separator="$t('header.To')"
            :start-placeholder="$t('header.startDate')"
            :end-placeholder="$t('header.endDate')"
          />
        </div>
        <div class="sumbit-box" @click="getFlightLog">{{$t('header.query')}}</div>
      </div>
      <div class="title-box">
        <div class="item-box">
          <div class="title">{{$t('flightApplication.总任务数量')}}</div>
          <div class="content">
            <div class="font">{{ titleData.taskCount }}</div>
            <div class="title">{{$t('navibar.unit')}}</div>
          </div>
        </div>
        <div class="item-box">
          <div class="title">{{$t('flightApplication.总飞行架次')}}</div>
          <div class="content">
            <div class="font">{{ titleData.sortieCounts }}</div>
            <div class="title">{{$t('flightApplication.次')}}</div>
          </div>
        </div>
        <div class="item-box">
          <div class="title">{{$t('flightApplication.总飞行里程')}}</div>
          <div class="content">
            <div class="font">{{ (titleData.sumFlightDistance / 1000).toFixed(2) || 0 }}</div>
            <div class="title">km</div>
          </div>
        </div>
        <div class="item-box">
          <div class="title">{{$t('flightApplication.总飞行时长')}}</div>
          <div class="content">
            <div class="font">{{ getTime(titleData.sumFlightTime)}}</div>
            <div class="title" />
          </div>
        </div>
        <div class="item-box">
          <div class="title">{{$t('flightApplication.总照片数量')}}</div>
          <div class="content">
            <div class="font">{{ titleData.imageCount }}</div>
            <div class="title">{{$t('flightApplication.张')}}</div>
          </div>
        </div>
      </div>

      <el-table
        :data="tableData"
        width="1073px"
        :cell-style="cellStyleFilter"
        :row-style="rowStyleFilter"
        :header-row-style="{
          'background-color':
            '#1E2026 linear-gradient(180deg, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0) 100%);',
          color: '#B3BBC5',
        }"
        :header-cell-style="{
          textAlign: 'center',
        }"
        :max-height="550"
        border
      >
        <el-table-column :label="$t('header.serialNumber')" width="50" type="index" />
        <el-table-column :label="`${$t('flightApplication.飞行架次号')}（${$t('flightApplication.次')}）`" property="flightSortieName"  width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.flightSortieName || $t('industryApplication.noData')}}</div>
          </template></el-table-column
        >
        <el-table-column property="flightDate" :label="$t('home.flightTime')" width="150"
          ><template slot-scope="scope">
            <div>{{ scope.row.flightDate || $t('industryApplication.noData') }}</div>
          </template></el-table-column
        >
        <el-table-column property="flightTime" :label="$t('flightApplication.飞行时长')" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.flightTime || $t('industryApplication.noData') }}</div>
          </template></el-table-column
        >
        <el-table-column property="flightDistance" :label="`${$t('home.flightMileage')}(km)`"  width="100">
          <template slot-scope="scope">
            <div>
              {{ (scope.row.flightDistance / 1000).toFixed(2) || $t('industryApplication.noData') }}
            </div>
          </template></el-table-column
        >
        <el-table-column property="taskName" :label="$t('flightApplication.所属任务')" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.taskName || $t('industryApplication.noData') }}</div>
          </template></el-table-column
        >
        <el-table-column property="taskTypeName" :label="$t('flightApplication.TaskType')" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.taskTypeName || $t('industryApplication.noData') }}</div>
          </template></el-table-column
        >
        <el-table-column property="orgName" :label="$t('flightApplication.ResponsibleAgency')" width="100">
          <template slot-scope="scope">
            <div>{{ scope.row.orgName || $t('industryApplication.noData') }}</div>
          </template></el-table-column
        >
        <el-table-column prop="action" :label="$t('operation.Operation')" width="200">
          <template slot-scope="scope">
            <div class="action">
              <img
                v-if="permissionFn(551)"
                :title="$t('flightApplication.图片')"
                class="icon"
                src="~@/assets/home/subplatForm/icon/image.png"
                alt=""
                @click="showDialog('image', scope.row)"
              />
              <!--              &lt;!&ndash; <img-->
              <!--                @click="showDialog('video', scope.row)"-->
              <!--                title="历史视频"-->
              <!--                class="icon"-->
              <!--                v-if="permissionFn(552)"-->
              <!--                src="~@/assets/home/subplatForm/icon/video.png"-->
              <!--                alt=""-->
              <!--              /> &ndash;&gt;-->
              <img
                v-if="permissionFn(553)"
                :title="$t('flightApplication.记录回放')"
                class="icon"
                src="~@/assets/home/subplatForm/icon/flight-line.png"
                alt=""
                @click="recordReplays(scope.row)"
              />
              <el-tooltip effect="dark" :content="$t('flightApplication.飞行报告')" placement="top">
                <img
                  v-hover
                  src="~@/assets/image/icons/export.png"
                  alt="#"
                  class="icon"
                  @click="exportFlightReport(scope.row)"
                />
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flight-line-list">
        <div class="pagination">
          <el-pagination
            background
            layout="total,prev, pager, next"
            :total="totalPage"
            :current-page.sync="search.page"
            :page-size="search.pageSize"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- </el-dialog> -->

    <HistoricalPictures
      v-if="picturesVisible"
      :pictures-visible="picturesVisible"
      :state-row="stateRow"
      :show-status="showStatus"
      @closePictures="closePictures"
    />

    <RecordReplays
      v-if="showRecordReplays"
      :record-data="recordData"
      @closeDemand="showRecordReplays = false"
    />
  </div>
</template>

<script>
import { Control_API, AirLine, Tg } from "@/api";
import HistoricalPictures from "./historical-pictures";
import RecordReplays from "./recordReplays";
import { mapMutations } from "vuex";
let positions = [];
import { permissionIds } from "@/utils/permission";
import { saveAs } from "file-saver";

export default {
  components: { HistoricalPictures, RecordReplays },
  data() {
    return {
      data: [],
      recordData: [],
      timeHandle: null,
      dialogTableVisible: false,
      tableData: [],
      searchDate: [],
      // 页码
      search: {
        name: null,
        type: 1,
        page: 1,
        pageSize: 10,
      },
      totalPage: null,
      // 头部显示数据
      titleData: {
        imageCount: null,
        taskCount: null,
        sumFlightDistance: null,
        sumFlightTime: null,
        sortieCounts: null,
      },
      // 组件显示隐藏
      picturesVisible: false,
      // 单行数据
      stateRow: null,
      // dialog显示的是图片还是视频 1:图片，2:视频
      showStatus: 1,
      showRecordReplays: false,
      uavHardId: null,
      uavLog: undefined,
    };
  },
  methods: {
    ...mapMutations(["SET_OPEN_LEFT_NAV_SILDER"]),
    closePictures() {
      this.picturesVisible = false;
      this.showStatus = 1;
    },
    permissionFn(pid) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    recordReplays(row) {
      this.recordData = row;
      this.showRecordReplays = true;
    },
    showDialog(status, row) {
      this.stateRow = row;
      if (status === "image") {
        console.log(1, row);
        this.showStatus = 1;
      } else {
        console.log(2, row);
        this.showStatus = 2;
      }
      this.picturesVisible = true;
    },
    // 清除航线
    clear_airline_entitys() {
      const viewer = window.viewer;
      airline_entitys.forEach((item) => {
        viewer.entities.remove(item);
      });

      if (handler) {
        handler && handler.destroy() && (handler = null);
        handler = null;
      }
    },
    // 两点距离
    create_label(before, after) {
      const viewer = window.viewer;
      const before_wgs84 = Utils.transformCartesian2WGS84(before);
      const after_wgs84 = Utils.transformCartesian2WGS84(after);

      const center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      const cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      const alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      const position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt,
      });

      const text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: "bold 30px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#fff"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      });
      airline_entitys.push(label_entity);
    },
    async showFlightLine(item) {
      // 预览
      this.$bus.$emit("closeSideList");
      // this.$bus.$emit('closeListNav');
      this.SET_SIDEBAR_STATUS("");
      this.SET_CESIUM_STATE(true);
      this.SET_OPEN_RIGHT_NAV_SILDER(false);
      const param = { id: item.id };
      await AirLine.routeDetail(param).then((resp) => {
        this.pointArr = JSON.parse(resp.data.content);
      });
      const polyline = this.pointArr;
      const viewer = window.viewer;
      this.clear_airline_entitys();

      let pointPositions = [];
      const label_arr = [];
      const _this = this;
      polyline.forEach((item, index) => {
        pointPositions.push(item.longitude, item.latitude, item.altitude);
        label_arr.push(
          Cesium.Cartesian3.fromDegrees(
            item.longitude,
            item.latitude,
            item.altitude
          )
        );
        point_entity = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(
            item.longitude,
            item.latitude,
            item.altitude
          ),
          name: "show_airline_point",
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            color: Cesium.Color.fromCssColorString("#1890FF"),
            // fillColor: Cesium.Color.RED,
            outlineWidth: 2,
            outlineColor: Cesium.Color.fromCssColorString("#FFF"),
            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          },
          label: {
            // text: String(item.altitude) + 'm',
            text: String(index + 1),
            scale: 0.5,

            font: "bold 24px Microsoft YaHei",
            // fillColor: Cesium.Color.BLUE,
            fillColor: Cesium.Color.fromCssColorString("#fff"),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            outlineWidth: 0,
            // showBackground: true,
            // backgroundColor: Cesium.Color.fromCssColorString("#1890FF")
            // pixelOffset: new Cesium.Cartesian2(25, -10),
            // backgroundPadding: new Cesium.Cartesian2(10, 10)
          },
          id: JSON.stringify({
            ...item,
            type: "flight_point",
            index: index,
          }),
        });
        airline_entitys.push(point_entity);
        if (label_arr.length > 1 && !this.isEditting) {
          const before = label_arr[label_arr.length - 2];
          const after = label_arr[label_arr.length - 1];
          _this.create_label(before, after);
        }
      });

      pointPositions =
        Cesium.Cartesian3.fromDegreesArrayHeights(pointPositions);

      const redLine = viewer.entities.add({
        name: "Red line on terrain",
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return pointPositions;
          }, false),
          width: 4,
          // clampToGround: true,
          // zIndex: -99,
          material: Cesium.Color.fromCssColorString("#1890FF"),
          // material: ({
          //   // material: Cesium.Color.fromCssColorString('#1890FF'),
          //   color: Cesium.Color.RED,
          //   outlineWidth: 2,
          //   outlineColor: Cesium.Color.fromCssColorString('#FFF')
          // })
          // // clampToGround: true,
          // material: new Cesium.PolylineOutlineMaterialProperty({
          //   color: Cesium.Color.fromCssColorString('#1890FF'),

          //   // outlineWidth: 2,
          //   // outlineColor: Cesium.Color.fromCssColorString('#FFF'),
          // }),
        },
      });

      positions = pointPositions;

      viewer.flyTo(redLine);
      airline_entitys.push(redLine);
    },
    // 页码
    handleCurrentChange(val) {
      this.getFlightLog(this.uavHardId);
    },
    findList(list, uavListName, childrenName) {
      try {
        list.forEach((element) => {
          if (
            element[uavListName] &&
            element[uavListName].length &&
            element.type !== uavListName
          ) {
            element[uavListName].forEach((e) => (e.type = uavListName));
            element[childrenName] = [
              ...element[uavListName],
              ...element[childrenName],
            ];
          }
          if (this.$uav && element[uavListName]) {
            // 实时更新无人机数据，currentOperator 接管人参数
            const newVal = element[uavListName].find(
              (e) => e.id === this.$uav.id
            );
            if (
              newVal &&
              newVal.currentOperator !== this.$uav.currentOperator
            ) {
              this.$store.commit("uavApplications/setState", {
                key: "uav",
                value: {
                  ...this.$uav,
                  currentOperator: newVal.currentOperator,
                },
              });
            }
          }
          if (element[childrenName] && element[childrenName].length) {
            this.findList(element[childrenName], "uavs", "childs");
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getUavList() {
      const res = await Control_API.getUavTree();
      if (res.code === 200) {
        this.data = res.data;
        this.findList(this.data, "uavs", "childs");
      }
    },
    async getFlightLog() {
      this.search.type = this.searchDate ? 1 : this.search.type;
      const res = await Control_API.getFlightLog({
        deviceHardId: this.uavHardId,
        ...this.search,
        startTime: (this.searchDate && this.searchDate[0]) || undefined,
        endTime: (this.searchDate && this.searchDate[1]) || undefined,
      });
      if (res.code === 200) {
        const data = res.data.page;
        data.records.map((item) => {
          item.flightTime = this.getTime(item.flightTime);
        });
        this.tableData = data.records;
        this.totalPage = res.data.page.total;
        this.titleData = {
          imageCount: res.data.imageCount,
          taskCount: res.data.taskCount,
          sumFlightDistance: res.data.sumFlightDistance,
          sumFlightTime: res.data.sumFlightTime,
          sortieCounts: res.data.sortieCounts,
        };
      }
    },
    renderContent(h, { node, data, store }) {
      // console.log(node.label,node, data, store);
      if (
        !(data.children && data.children.length) &&
        data.type &&
        data.type === "uavs"
      ) {
        return (
          <span
            class="custom-tree-node"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "200px",
              alignItems: "center",
              color: node.data.online ? "#00FF00" : "#808080",
            }}
          >
            <span class="uav-title">
              <span class="uav-title__icon"></span>
              <span class="uav-title__text" title={node.label}>
                {node.label}
              </span>
            </span>
            <span class="ctrl-list">
              <div
                class={`ctrl-icon icon--control`}
                on-click={() => this.onOperate(data)}
                title="日志"
              ></div>
              {/* <div
                class={`ctrl-icon icon--take-over-control`}
                on-click={() => this.takeOverTheDrone(data)}
                title="接管"
              ></div>*/}
              {/* <div class={`ctrl-icon icon--lib ${(node.data.online || this.openTest) ? '' : 'disable'}`}
                on-click={() => this.onViewLibrary(node, data)}>
              </div> */}
              {/* <div
                class={`ctrl-icon icon--video`}
                disabled={node.data.online ? false : true}
                on-click={() => this.onPlay(data)}
                title="视频"
              ></div>*/}
            </span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            {/* <span>{`${node.label} ${node.data.online}/${node.data.online + node.data.offline} `}</span> */}
            <span>{`${node.label}`}</span>
            <span style="color: #409EFF;padding-left:4px;">{`${node.data.online}`}</span>
            <span>{`/${node.data.online + node.data.offline} `}</span>
          </span>
        );
      }
    },
    getTime(time) {
      const h = parseInt(time / 3600);
      // h = h < 10 ? '0' + h : h
      let m = parseInt(time / 60) % 60;
      m = m < 10 ? "0" + m : m;
      let s = time % 60;
      s = s < 10 ? "0" + s : s;
      return h + ":" + m + ":" + s;
    },
    async onOperate(data) {
      if (!data.hardId) return;
      this.uavHardId = data.hardId;
      this.$store.commit("uavApplications/setState", {
        key: "uavLog",
        value: data,
      });
      this.uavLog = data;
      await this.getFlightLog();
      this.dialogTableVisible = true;
    },
    handleClose() {},
    cellStyleFilter({ column, columnIndex }) {
      if (columnIndex == 0) {
        return "textAlign:center;";
      } else {
        return "textAlign:center;";
      }
    },
    rowStyleFilter({ row, rowIndex }) {
      if (rowIndex % 2) {
        return { "background-color": "#0A0B0D", color: "#fff" };
      } else {
        return { "background-color": "#14151A", color: "#fff" };
      }
    },
    async changeType(type) {
      this.search.type = this.search.type === type ? null : type;
      this.searchDate = undefined;
      await this.getFlightLog();
    },
    //   导出成果
    exportFlightReport(row) {
      Tg.getExportToWordForSortie({
        id: row.flightSortieName,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/msword" });
        saveAs(blob, `${this.uavLog.name}-${row.flightSortieName}`);
      });
    },
  },
  async created() {
    this.getUavList();
    // this.timeHandle = setInterval(async () => {
    //   this.getUavList();
    // }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timeHandle);
    this.$store.dispatch("uavApplications/destroy");
  },
};
</script>

<style lang="scss" scoped>
  .flight-line-list {
    @import "~@/styles/list-common.scss";
  }
  .uav-control {
    height: 100%;
    ::v-deep {
      .el-tree {
        box-sizing: content-box;
        height: 100%;
        overflow: auto;
        margin-right: 10px;
        color: #c6ccd4;
        font-size: 12px;
        background: none !important;
        .el-tree-node:after {
          border-top: none;
        }
        .el-tree-node {
          position: relative;
          padding-left: 16px;
          box-sizing: border-box;
          background: none;
          /*设置横向滚动条*/
          min-width: 100% !important;
          display: block !important;
          float: left;
          .el-tree-node__content {
            background: none;
            border: 1px solid transparent;
            padding-left: 0 !important;
            padding-top: 5px;
            &:hover .custom-tree-node {
              border: 1px solid #c6ccd4;
              background: none;
            }
          }
        }
        .is-current {
          .el-tree-node {
            .el-tree-node__content {
              &.custom-tree-node {
                border: 1px solid transparent;
                padding-left: 0 !important;
              }
              &:hover .custom-tree-node {
                border: 1px solid #c6ccd4;
                background: none;
              }
            }
          }
          .el-tree-node__content .custom-tree-node {
            border: 1px solid #c6ccd4;
          }
        }
        //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
        .el-tree-node__expand-icon.is-leaf {
          display: none;
        }
        .el-tree-node__children {
          padding-left: 16px;
        }

        .el-tree-node :last-child:before {
          height: 38px;
        }

        .el-tree-node:before {
          border-left: none;
        }

        .el-tree-node:after {
          border-top: none;
        }

        .el-tree-node:before {
          content: "";
          left: -4px;
          position: absolute;
          right: auto;
          border-width: 1px;
        }

        .el-tree-node:after {
          content: "";
          left: -4px;
          position: absolute;
          right: auto;
          border-width: 1px;
        }

        .el-tree-node:before {
          border-left: 1px solid #2c343c;
          bottom: 0px;
          height: 100%;
          top: -26px;
          width: 1px;
        }

        .el-tree-node:after {
          border-top: 1px solid #2c343c;
          height: 20px;
          top: 12px;
          width: 18px;
        }
        .el-tree-node__content > .el-tree-node__expand-icon {
          padding: 0;
        }
        .el-tree-node__expand-icon.expanded {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        .el-icon-caret-right:before {
          content: "\e723";
          font-size: 16px;
        }
        .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
          content: "\e722";
          font-size: 16px;
        }
      }
      .uav-title {
        display: flex;
        gap: 2px;
        align-items: center;
        overflow: hidden;

        .uav-title__icon {
          width: 16px;
          height: 16px;
          background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/uav.png");
          background-size: 100% 100%;
          flex-shrink: 0;
        }

        .uav-title__text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ctrl-list {
        display: flex;
        gap: 2px;

        .ctrl-icon {
          width: 24px;
          height: 24px;

          &.icon--control {
            background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/control.png");
            background-size: 100% 100%;
          }
          &.icon--take-over-control {
            background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/take-over-control.png");
            background-size: 100% 100%;
          }
          &.icon--lib {
            background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/lib.png");
            background-size: 100% 100%;
          }
          &.icon--video {
            background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/video.png");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .flight-log {
    position: fixed;
    top: 100px;
    left: 450px;
    width: 1073px;
    padding: 10px;
    border: 1px solid #73a6d1;
    background-color: #2c313b;
    .title {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 0 10px;
      height: 30px;
      color: #73a6d1;
      .close {
        cursor: pointer;
        font-size: 24px;
      }
    }
    .search {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .btn-serach {
        display: flex;
        align-items: center;
        .btn-item {
          background-color: #16181b;
          width: 104px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          color: #ebf5ff;
          cursor: pointer;
          &.active {
            background-color: #50abff;
          }
        }
      }
      .date-serach {
        margin: 0 10px;
        color: #b3bbc5;
      }
      .sumbit-box {
        width: 103px;
        height: 32px;
        line-height: 32px;
        color: #ebf5ff;
        background-color: #50abff;
        cursor: pointer;
        text-align: center;
        border-radius: 5px;
      }
    }
    .title-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 18px;
      .item-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 74px;
        background: linear-gradient(
          360deg,
          rgba(69, 86, 119, 0.1) 0%,
          rgba(69, 86, 119, 0.4) 100%
        );
        border-radius: 4px;
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e3eeff;
          line-height: 20px;
        }
        .content {
          display: flex;
          align-items: center;
          font-size: 22px;
          font-family: AppleSystemUIFont;
          color: #e8f2ff;
          line-height: 26px;
          .font {
            margin-right: 5px;
          }
        }
      }
    }
    .pagination {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 10px;
      position: unset;
    }
  }
  ::v-deep {
    .el-dialog__wrapper {
      top: unset;
    }
    .el-dialog.flight-log {
      margin-top: 0 !important;
      margin-left: 400px;
      /* background: rgba(0, 0, 0, 0.95); */
      background: #1e2026
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.05) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      box-shadow: inset 1px 1px 0px 0px #000000;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: transparent;
    }
    .el-table {
      border: 1px solid #000000;
      td,
      th {
        border: 1px solid #000000;
      }
      tr th.el-table__cell {
        border: 1px solid #000000;
        background: #1e2026
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.05) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        box-shadow: inset 1px 1px 0px 0px #000000;
      }
      .action {
        .icon {
          cursor: pointer;
        }
      }
    }
    .el-table__empty-block {
      background-color: #000;
    }
  }
</style>
