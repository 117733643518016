<template>
  <el-dialog
    :title="title"
    class="hwb-dialog"
    :top="'0px'"
    :visible.sync="dialogVisible"
    :append-to-body="false"
    :modal="false"
    :style="'left:' + left + 'px' + ';top:' + top + 'px'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="width + 'px'"
  >
    <div class="guanbi" @click="close">{{ closeTitle }}</div>
    <img class="jiantou" src="" />
    <slot />
  </el-dialog>
</template>

<script>
export default {
  props: {
    closeTitle: {
      type: String,
      default: '关闭'
    },
    width: {
      // 控制宽度
      type: Number,
      default: 400
    },
    title: {
      // 标题
      type: String,
      default: '请传入一个标题放在这里'
    },
    top: {
      type: Number,
      default: 200
    },
    left: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    close() {
      this.$emit('on-close');
    }
  }
};
</script>

<style lang="scss" scoped>
.hwb-dialog {
  right: unset;
  bottom: unset;
  color: #fff;
  ::v-deep {
    .el-dialog {
      border: 0px !important;
      /* background: transparent; */
      background-color: #2c313b;
      position: relative;
      .el-dialog__header {
        width: 100%;
        height: 34px;
        padding: 0;
        box-sizing: border-box;
        box-shadow: inset 0px 0px 10px 0px #00142f;
        border: 1px solid #73a6d1;
        backdrop-filter: blur(2px);
        background: #2c313b;
        width: 100%;
        height: 32px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 1px 0px 0px rgba(255, 255, 255, 0.05),
          inset 0px -1px 0px 0px #000000;
        border-radius: 4px 4px 0px 0px;
        .el-dialog__title {
          box-sizing: border-box;
          padding-left: 47px;
          font-family: YouSheBiaoTiHei;
          display: block;
          width: 100%;
          height: 34px;
          line-height: 32px;
          font-size: 22px;
          font-weight: bold;
          text-align: left;
          backdrop-filter: blur(2px);
          background: linear-gradient(180deg, #ffffff 0%, #ffffff 36%, #54acff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .el-dialog__headerbtn {
          display: none;
        }
      }
      .guanbi {
        position: absolute;
        top: -30px;
        right: 16px;
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        cursor: pointer;
      }
      .jiantou {
        position: absolute;
        top: -27px;
        left: 8px;
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
      }
      .el-dialog__body {
        background: rgba(40, 53, 122, 0.1);
        border: 1px solid #73a6d1;
        box-shadow: 0px 0px 5px #73a6d1;
        border-top: 0;
        backdrop-filter: blur(2px);
      }
    }
  }
}
</style>
