<template>
  <div class="box">
    <div class="box-wrap w200 f12 ">
      <div class="close" @click="closeClick">X</div>
      <div class="content cf ">
        <div class=" mt10 tc fw700" style="color:#d8a235">资源详情</div>
        <div class="ml10 mt3">
          资源类型: <span class="bfe">{{ data.propertyTypeName }}</span>
        </div>
        <div class="ml10 mt3">
          资源名称: <span class="bfe">{{ data.propertyName }}</span>
        </div>
        <div class="ml10 mt3">
          经度: <span class="bfe">{{ data.lng }}</span>
        </div>
        <div class="ml10 mt3">
          纬度: <span class="bfe">{{ data.lat }}</span>
        </div>
        <div class="ml10 mt3">
          资源地址: <span class="bfe">{{ data.place||'暂无' }}</span>
        </div>
        <div class="ml10 mt3">
          备注: <span class="bfe">{{ data.remark||'暂无' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'DynamicLabel',
  props: {
    data: {
      type: Object,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      videoPostion: true,
      show: true
    };
  },
  mounted() {
  },
  destroyed() {
    window.this = null;
  },
  methods: {
    closeClick() {
      if (this.closeEvent) {
        this.closeEvent();
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.bfe{
  color:#42bfe5
}
.box {
  width: 200px;
  position: absolute;
  left: 0;
  ::v-deep {
    .pine {
      position: absolute;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      line-height: 120px;
      text-indent: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40%;
        height: 60px;
        box-sizing: border-box;
        // border-bottom: 1px solid #38e1ff;

        // filter: drop-shadow(1px 0px 2px #03abb4);
      }
    }

    .address_info {
      width: 170px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //用省略号显示
      white-space: nowrap
    }

    .pine1 {
      position: absolute;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      line-height: 120px;
      text-indent: 5px;

      &::before {
        content: "";
        position: absolute;
        left: 56px;
        bottom: -83px;
        width: 40%;
        height: 60px;
        box-sizing: border-box;
        // border-bottom: 1px solid #38e1ff;
        transform-origin: bottom center;
        transform: rotateZ(135deg) scale(1.5);
        animation: slash 0.5s;
        filter: drop-shadow(1px 0px 2px #03abb4);
      }
    }

    .type-28 {
      width: 140% !important;
    }

    .box-wrap {
      position: absolute;
      left: -8%;
      top: 140px;
      border-radius: 6px;
      background: rgba(0,0,0,0.7);
      backdrop-filter: blur(4px);

      .close {
        position: absolute;
        color: #fff;
        top: 1px;
        right: 10px;
        text-shadow: 2px 2px 2px #022122;
        cursor: pointer;
        animation: fontColor 1s;
      }

      .area {
        position: absolute;
        top: 20px;
        right: 0;
        width: 95%;
        height: 30px;

        &-title {
          text-align: center;
          line-height: 30px;
        }

        .fontColor {
          font-size: 16px;
          font-weight: 800;
          color: #ffffff;
          text-shadow: 1px 1px 5px #002520d2;
          animation: fontColor 1s;
        }
      }

      .textColor {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        text-shadow: 1px 1px 5px #002520d2;
        animation: fontColor 1s;
      }

      .yellowColor {
        font-size: 14px;
        font-weight: 600;
        color: #f09e28;
        text-shadow: 1px 1px 5px #002520d2;
        animation: fontColor 1s;
      }
    }
  }
}
</style>
