<!--
 * @Author: kai 17660422924@163.com
 * @Date: 2024-03-02 09:13:35
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-04-03 15:30:52
 * @FilePath: \mmc-vue-tianmu\src\components\common\message-modal\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>
export default {
  name: "Index",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 650,
    },
    title: {
      type: String,
      default: "",
    },
    positionLeft: {
      type: Number,
      default: 0,
    },
    positionTop: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<template>
  <div
    v-if="show"
    class="message-modal animate__animated animate__fadeIn"
    :style="{
      width: `${width}px`,
      left: positionLeft ? `${positionLeft}px` : `calc(50% - ${width / 2}px)`,
      top: positionTop ? `${positionTop}px` : '100px',
    }"
  >
    <div v-interact class="message-modal-header">
      <div v-if="title" class="title">{{ title }}</div>
      <slot v-else name="title" />
      <img
        src="@/assets/image/icons/close.png"
        class="icon-close"
        @click="close"
      />
    </div>
    <slot />
  </div>
</template>

<style scoped lang="scss">
.message-modal {
  background: rgba(19, 21, 24);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 8px;
  min-height: 100px;
  position: fixed;
  top: 100px;
  z-index: 999;
  transition: left 0.638s ease-in-out;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
    border-bottom: 1px solid #000;
    position: relative;
    .title {
      font-size: 17px;
      color: #ebf5ff;
      line-height: 19px;
      margin-left: 8px;
      font-weight: bold;
    }
    .icon-close {
      color: #fff;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
}
</style>
