// 无人机实时数据各链路融合
import { mapState } from 'vuex';

export default {
  computed: {
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    // mqtt实时数据
    mqttDataSet() {
      return this.$store.state.MMCMQTT?.dataSet?.[this.$uav?.hardId];
    },
    // 地面站实时数据
    gsDataSet() {
      return this.$store.state.MMCGroundStation?.dataSet?.[this.$uav?.hardId];
    }
  },
  watch: {
    mqttDataSet(newVal) {
      if (this.$uav?.network === 1) {
        if (newVal) {
          this.$store.dispatch('uavApplications/updateByMQTT', newVal);
        }
      }
    },
    gsDataSet(newVal) {
      if (this.$uav?.network == 0) {
        if (newVal) {
          this.$store.dispatch('uavApplications/updateByMQTT', newVal);
        }
      }
    }
  },
  async created() {
    const { VUE_APP_BASE_WS_URL, VUE_APP_MQTT_URL } = process.env;
    // 模拟飞机数据上报
    // this.$store.dispatch("uavApplications/test", { open: true });

    // 启动mqtt服务
    try {
      await this.$store.dispatch('MMCMQTT/init', {
        url: VUE_APP_MQTT_URL
      });
    } catch (e) {
      console.log('mqtt失败', e);
    }
    console.log('mqtt连接成功');

    // 由于ws连接到地面站是使用广播形式获取无人机数据的,所以在组件创建时先连接ws,后续选择无人机时,通过广播数据,筛选出选择的无人机信息出来
    try {
      const user_info = JSON.parse(window.localStorage.getItem('user_info'));
      const username = user_info.userName;
      const token = window.localStorage.getItem('tmj_token');
      const appId = user_info.appid;
      await this.$store.dispatch('MMCGroundStation/init', {
        url: VUE_APP_BASE_WS_URL,
        userInfo: {
          type: 100,
          systemCode: 'mmc',
          state: 1,
          username,
          token,
          appId
        }
      });
    } catch (e) {
      console.log('地面站连接失败', e);
    }
    console.log('地面站连接成功11');
  }
};
