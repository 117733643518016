<template>
  <div class="RegionalBox" :style="_style">
    <div v-interact class="head">
      <div class="left">{{ type == 1 ? '区域分析' : '淹沒分析' }}</div>
      <div class="right">
        <span v-hover class="iconfont icon-guanbi" @click="close" />
      </div>
    </div>
    <div class="body">
      <div v-if="type == 1" class="checkout">
        <div class="label">统计图示：</div>
        <el-radio-group v-model="chartType">
          <el-radio :label="1">饼状图</el-radio>
          <el-radio :label="2">柱状图</el-radio>
        </el-radio-group>
      </div>
      <div class="draw">
        <div class="label">请选择绘制工具，在地图上选择查询范围：</div>
        <div class="region tl dib tc">
          <el-tooltip content="点击按钮开始绘制空域,双击右键完成绘制" placement="top" effect="dark">
            <div class="dib cp" @click="drawClick">
              <div class="draw" />
              <div class="cf tc">多边形</div>
            </div>
          </el-tooltip>
        </div>
        <el-tooltip
          content="点击按钮开始绘制空域，第一个点为起点第二个点为终点，以两个点为半径生成一个圆形区域"
          placement="top"
          effect="dark"
        >
          <div class="dib ml20 cp" @click="drawCircleClick">
            <div class="dib f22 ml20 vam iconfont icon-fuwuwangdian cf" />
            <div class="cf tc">圆形</div>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="foot">
      <div class="btn" @click="onClickSumbit">{{$t('operation.Confirm')}}</div>
      <div class="btn" @click="close">{{$t('operation.Cancel')}}</div>
    </div>
  </div>
</template>
<script>
let viewer;
let entities = [];
let positions = [];
let centerPositionEntity = null;
let radiusDistance = null;
// 绘制的空域
let polygonEntity = null;
import { Utils } from '@/utils/cesium';
import { ThreeDimensionalApi } from '@/api';

export default {
  props: {
    _style: {
      type: Object,
      default: () => {
        {
        }
      }
    },
    type: {
      type: Number,
      default: () => {
        1;
      }
    }
  },
  data() {
    return {
      chartType: 1
    };
  },
  mounted() {
    viewer = window.viewer;
  },
  beforeDestroy() {
    this.clearMarker();
    this.removeInputAction();
    this.createAfterPolyline();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    // 创建矩形 end
    creatPoint(position) {
      // 添加点位
      const pointEntity = viewer.entities.add({
        position: position,
        point: {
          pixelSize: 20,
          color: Cesium.Color.RED,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2
        },
        label: {
          text: (positions.length + 1).toString(),
          scale: 0.5,
          font: 'bold 24px Microsoft YaHei',
          fillColor: Cesium.Color.WHITE,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: false,
          outlineWidth: 0
        }
      });

      entities.push(pointEntity);
      positions.push(position);
    },
    createPolyline(position) {
      // 添加线
      if (positions.length > 1) {
        var lineEntity = viewer.entities.add({
          polyline: {
            positions: [positions[positions.length - 2], position],
            width: 2,
            material: Cesium.Color.RED
          }
        });
        entities.push(lineEntity);
        // 计算两个点之间的距离
        const distance = Cesium.Cartesian3.distance(position, positions[positions.length - 2]);
        radiusDistance = distance;
        // 计算线的中心位置
        const center = Cesium.Cartesian3.add(
          positions[positions.length - 2],
          position,
          new Cesium.Cartesian3()
        );
        const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
        // 创建 Label 标签来显示距离
        var labelEntity = viewer.entities.add({
          position: newcenter,
          label: {
            text: distance.toFixed(2) + 'm',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            pixelOffset: new Cesium.Cartesian2(0, -20)
          }
        });
        entities.push(labelEntity);
      }
    },
    createCircle(text) {
      const color = '#ff0000';
      const radius = Cesium.Cartesian3.distance(positions[0], positions[1]);
      const center = positions[0];
      try {
        polygonEntity = viewer.entities.add({
          position: center,
          label: {
            text: text || '',
            scaleByDistance: new Cesium.NearFarScalar(800, 2.0, 900, 0.6),
            scale: 0.8,
            verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
            pixelOffset: new Cesium.Cartesian2(20, 20) // 文本偏移量
          },
          ellipse: {
            semiMinorAxis: radius,
            semiMajorAxis: radius,
            // material: Cesium.Color.fromCssColorString(color),
            material: Cesium.Color.RED.withAlpha(0.5),
            outline: true,
            outlineColor: Cesium.Color.WHITE
          }
        });
        entities.push(polygonEntity);
      } catch (error) {
        console.log(error);
      }
    },
    // 双击右键绘制矩形
    handleRightDoubleClick() {
      if (positions.length < 3) {
        return this.$message.warning('请最少选择三个点');
      }
      const _this = this;
      // 取消事件监听
      this.removeInputAction();
      const color = '#ff0000';
      // 添加连线，形成闭合区域,
      if (positions.length > 2) {
        polygonEntity = viewer.entities.add({
          polygon: {
            hierarchy: positions,
            material: Cesium.Color.fromCssColorString(color),
            material: Cesium.Color.RED.withAlpha(0.5)
          }
        });
        const centerPosition = Cesium.BoundingSphere.fromPoints(positions).center;
        centerPositionEntity = viewer.entities.add({
          position: centerPosition,
          label: {
            text: '',
            scaleByDistance: new Cesium.NearFarScalar(800, 2.0, 900, 0.6),
            scale: 0.8,
            verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
            pixelOffset: new Cesium.Cartesian2() // 文本偏移量
          }
        });
        this.createAfterPolyline();
        entities.push(polygonEntity);
        entities.push(centerPositionEntity);
        let points = [];
        positions.map((item) => {
          const { lng, lat } = Utils.transformCartesian2WGS84(item);
          points.push([lng, lat].join(','));
        });
        points = points.join(' ');
      }
      // 创建矩形最后一条边
      this.createAfterPolyline();
    },
    // 取消事件监听
    removeInputAction() {
      viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_CLICK,
        this.leftClickHandler
      );
      viewer.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.RIGHT_CLICK,
        this.rightClickHandler
      );
    },
    // 创建圆形 start
    drawCircleClick() {
      this.markType = 'CIRCLE';
      this.removeInputAction();
      this.clearMarker();
      this.$message.success('请点击选择第一个点');
      const _this = this;
      this.leftClickHandler = viewer.screenSpaceEventHandler.setInputAction(function (event) {
        const position = viewer.scene.pickPosition(event.position);
        if (position) {
          _this.creatPoint(position);
        }
        _this.$message.success('请选择第二个点');
        if (positions.length == 2) {
          _this.createPolyline(position);
          // 生成圆形
          _this.createCircle('');
          // 清除之前的点击位置和圆形实体
          viewer.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_CLICK,
            _this.leftClickHandler
          );
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    // 清除数据
    clearMarker() {
      if (entities) {
        for (let i = 0; i < entities.length; i++) {
          const element = entities[i];
          viewer.entities.remove(element);
        }
        entities = [];
        positions = [];
        // 绘制的空域
        polygonEntity = null;
        radiusDistance = null;
      }
    },
    // 创建矩形 start
    drawClick() {
      this.markType = 'POLYGON';
      this.clearMarker();
      const _this = this;
      // 点击事件处理函数
      this.leftClickHandler = viewer.screenSpaceEventHandler.setInputAction(function (e) {
        // 获取点击位置的地理坐标
        const position = viewer.scene.pickPosition(e.position);
        if (position) {
          _this.creatPoint(position);
          _this.createPolyline(position);
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      var rightClickTimer; // 计时器用于判断双击事件
      var isRightDoubleClick = false; // 标记是否为右键双击事件
      this.rightClickHandler = viewer.screenSpaceEventHandler.setInputAction(function (click) {
        // 判断是否为右击事件
        if (click.button === Cesium.ScreenSpaceEventType.RIGHT_BUTTON) {
          if (rightClickTimer) {
            // 如果计时器已经存在，则表示已经进行了一次右击
            // 此次右击为双击事件
            clearTimeout(rightClickTimer);
            isRightDoubleClick = true;
            _this.handleRightDoubleClick();
          } else {
            // 否则，启动计时器等待一段时间判断是否为双击事件
            rightClickTimer = setTimeout(function () {
              if (!isRightDoubleClick) {
                // 如果计时器结束后仍然没有触发双击事件，则为右击事件
              }
              // 重置计时器和标记
              rightClickTimer = undefined;
              isRightDoubleClick = false;
            }, 300); // 调整延时时间以适应您的需求
          }
        }
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    },
    async onClickSumbit() {
      if (entities.length && positions.length && this.markType) {
        let points = [];
        if (this.markType === 'POLYGON') {
          const point = [...positions, positions[0]];
          point.map((item) => {
            const { lng, lat } = Utils.transformCartesian2WGS84(item);
            points.push([lng, lat].join(' '));
          });
          points = points.join(',');
          points = `((${points}))`;
          this.pointDownloadStr = points;
        } else {
          const { lng, lat } = Utils.transformCartesian2WGS84(positions[0]);
          points = [lng, lat].join(',');
          points = `<(${points}),${radiusDistance / 100000}>`;
          this.pointDownloadStr = points;
        }
        try {
          const data = new FormData();
          data.append('markLine', points);
          data.append('markType', this.markType);
          const res = await ThreeDimensionalApi[
            this.type == 1 ? 'getMarkChart' : 'getMarkWaterChart'
          ](data);
          if (res.code === 200) {
            if (res.data && res.data.length === 0) {
              return this.$message.warning('此地区无带有标签的标注');
            }
            if (this.type == 1) {
              this.$nextTick((e) => {
                this.$emit('showEcharts', {
                  data: res.data,
                  chartType: this.chartType
                });
                // if (this.chartType === 1) {
                //   this.initPieChart(res.data);
                // } else {
                //   this.initColumnarChart(res.data);
                // }
              });
            } else {
              this.$emit('showSubmergedAnalysis', res.data);
            }
          } else {
            this.$message.error(res.msg);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(radiusDistance, 'radiusDistance');
        this.$message.warning('请绘制区域');
      }
    },
    createAfterPolyline() {
      if (positions.length > 1) {
        const lineEntity = viewer.entities.add({
          polyline: {
            positions: [positions[0], positions[positions.length - 1]],
            width: 2,
            material: Cesium.Color.RED
          }
        });
        entities.push(lineEntity);
        // 计算两个点之间的距离
        const distance = Cesium.Cartesian3.distance(positions[0], positions[positions.length - 1]);
        console.log('创建最后一条边-距离', distance);

        // 计算线的中心位置
        const center = Cesium.Cartesian3.add(
          positions[positions.length - 1],
          positions[0],
          new Cesium.Cartesian3()
        );
        console.log('创建最后一条边-距离计算线的中心位置', center);

        const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
        // 创建 Label 标签来显示距离
        var labelEntity = viewer.entities.add({
          position: newcenter,
          label: {
            text: distance.toFixed(2) + 'm',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            pixelOffset: new Cesium.Cartesian2(0, -20)
          }
        });
        entities.push(labelEntity);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.RegionalBox {
  position: fixed;
  right: 80px;
  top: 106px;
  width: 346px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(52, 223, 255, 1), rgba(24, 113, 214, 0)) 1 1;
  backdrop-filter: blur(4px);
  .head {
    height: 32px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .left {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ebf5ff;
    }
    .right {
      color: #ebf5ff;
    }
  }
  .body {
    padding: 10px;
    .checkout {
      display: flex;
      align-items: center;
      .label {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        line-height: 15px;
        height: 19px;
      }
      .el-radio {
        color: #fff;
      }
    }
    .draw {
      margin-top: 5px;
      .label {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        text-align: left;
      }
      .draw {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background: url('~@/assets/home/subplatForm/icon/draw.png') no-repeat;
        background-size: 100% 100%;
        margin: 0 AUTO;
      }
    }
  }
  .foot {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      cursor: pointer;
      width: 96px;
      height: 32px;
      line-height: 32px;
      background: #50abff;
      border-radius: 2px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ebf5ff;
      margin-left: 10px;
      border: 1px solid transparent;
      transition: border 0.5s;
      &:hover {
        border: 1px solid #fff;
      }
    }
  }
}
</style>
