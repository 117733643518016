import { ThreeDimensionalApi } from '@/api';
import FileSaver from 'file-saver';
export default {
  // 获取entity数据
  handleClickAndGetEntityData(viewer, eventType, callback) {
    const handler = viewer.screenSpaceEventHandler;
    function handleEntityClick(click) {
      const pickedEntity = viewer.scene.pick(click.position);
      if (Cesium.defined(pickedEntity)) {
        const entityData = {
          id: pickedEntity.id,
          properties: pickedEntity.id._properties
        };
        callback(entityData);
      }
    }
    const clickHandler = handler.setInputAction(handleEntityClick, eventType);
    return {
      remove: function () {
        handler.removeInputAction(eventType, clickHandler);
      }
    };
  },
  async getTagGroupListFn() {
    const res = await ThreeDimensionalApi.getTagGroupList();
    if (res.code === 200) {
      const data = res.data.map((item) => {
        return {
          ...item,
          labelOuts:
            item.labelOuts && item.labelOuts.length > 0
              ? item.labelOuts.map((k) => {
                  return {
                    ...k,
                    status: false
                  };
                })
              : null
        };
      });
      this.tagTypeList = data;
    }
  },
  // 全选
  isAllFn(k) {
    try {
      const arr = [];
      this.tagTypeList[this.selectIndex].labelOuts.forEach((item) => {
        arr.push({
          ...item,
          status: k
        });
      });
      this.tagTypeList[this.selectIndex].labelOuts = JSON.parse(JSON.stringify(arr));
      this.$forceUpdate();
    } catch (error) {
      console.log(error);
    }
  },
  addMarker(type) {
    this.closeFn();
    this.add(type);
    // 禁止切换
    this.$store.commit('uavApplications/setState', {
      key: 'isPreventClick',
      value: true
    });
  },
  // 报告 start
  async convertToPdf() {
    try {
      const wordBlob = this.wordFile;
      const wordUrl = URL.createObjectURL(wordBlob);
      console.log(wordUrl, 'wordUrl');
      this.wordDocumentUrl = wordUrl;
    } catch (error) {
      console.error(error);
    }
  },
  blobToArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          resolve(reader.result);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(blob);
    });
  },
  saveFn() {
    FileSaver.saveAs(this.wordFile, `报告标注.doc`);
  },
  async showInspectionReport() {
    const _this = this;
    const ids = [];
    this.reportData.forEach((item) => {
      ids.push(item.id);
    });
    if (ids.length > 0) {
      let screenshotDataUrl = null;
      function handlePostRender() {
        try {
          const canvas = viewer.scene.canvas;
          screenshotDataUrl = canvas.toDataURL('image/png');
          _this.getMarkReportFn(screenshotDataUrl, ids);
        } catch (error) {
          console.log(error, 'xx');
        }

        if (screenshotDataUrl) {
          viewer.scene.postRender.removeEventListener(handlePostRender);
        }
      }
      viewer.scene.postRender.addEventListener(handlePostRender);
    }
  },
  getMarkReportFn(base64String, ids) {
    function base64ToBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
    if (base64String) {
      const blob = base64ToBlob(base64String);
      const formData = new FormData();
      // 接口
      formData.append('file', blob, '标注.jpeg');
      formData.append('ids', ids.join(','));
      formData.append('sort', 'asc');
      formData.append('sortType', this.reportStatus);
      ThreeDimensionalApi.getMarkReport(formData).then((res) => {
        const blob = new Blob([res], { doc: 'text/plain;charset=utf-8' });
        this.wordFile = blob;
        console.log(blob, 'wordFile');
        this.convertToPdf();
        // 在这里处理转换后的 HTML
        this.InspectionReport = true;
        this.reportDialog = false;
        this.$nextTick(() => {
          this.initPieChart(this.reportData, ids);
        });
      });
    }
  },

  // 报告 end
  // 取消搜索
  CancellationSeach() {
    this.getTagGroupListFn();
    this.isAll = false;
    this.labels = null;
    this.SelectItem = 0;
    this.markLevel = 0;
    this.getmarkerLists();
  },
  distance(point1, point2) {
    const point1cartographic = Cesium.Cartographic.fromCartesian(point1);
    const point2cartographic = Cesium.Cartographic.fromCartesian(point2);
    /** 根据经纬度计算出距离**/
    const geodesic = new Cesium.EllipsoidGeodesic();
    geodesic.setEndPoints(point1cartographic, point2cartographic);
    let s = geodesic.surfaceDistance;
    // console.log(Math.sqrt(Math.pow(distance, 2) + Math.pow(endheight, 2)));
    // 返回两点之间的距离
    s = Math.sqrt(
      Math.pow(s, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2)
    );
    return s;
  },
  getArea(entity) {
    var points = entity.polygon.hierarchy.getValue().positions;
    let res = 0;
    // 拆分三角曲面
    for (let i = 0; i < points.length - 2; i++) {
      const j = (i + 1) % points.length;
      const k = (i + 2) % points.length;
      const totalAngle = this.Angle(points[i], points[j], points[k]);

      const dis_temp1 = this.distance(points[j], points[0]);
      const dis_temp2 = this.distance(points[k], points[0]);
      res += (dis_temp1 * dis_temp2 * Math.sin(totalAngle)) / 2;
    }

    res = Math.abs(res).toFixed(4);
    return res;
  },
  /* 测量空间面积 */
  // 方向
  Bearing(from, to) {
    const fromCartographic = Cesium.Cartographic.fromCartesian(from);
    const toCartographic = Cesium.Cartographic.fromCartesian(to);
    const lat1 = fromCartographic.latitude;
    const lon1 = fromCartographic.longitude;
    const lat2 = toCartographic.latitude;
    const lon2 = toCartographic.longitude;
    let angle = -Math.atan2(
      Math.sin(lon1 - lon2) * Math.cos(lat2),
      Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
    );
    if (angle < 0) {
      angle += Math.PI * 2.0;
    }
    return angle;
  },
  /* 角度*/
  Angle(p1, p2, p3) {
    const bearing21 = this.Bearing(p2, p1);
    const bearing23 = this.Bearing(p2, p3);
    let angle = bearing21 - bearing23;
    if (angle < 0) {
      angle += 360;
    }
    return angle;
  }
};
