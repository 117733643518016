<template>
  <div class="task-notifications">
    <el-table style="width: 100%" :data="messageList" border height="360">
      <el-table-column :label="$t('header.serialNumber')" align="center" width="80px">
        <template slot-scope="scope">
          <div class="serial-wrap-number">
            <div v-if="!scope.row.read" class="dot" />
            <span class="number">{{ scope.$index + 1 }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :label="$t('header.messageTime')" align="center" />
      <el-table-column prop="taskName" :label="$t('flightApplication.TaskName')" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.taskName" placement="top">
            <div class="task-name">{{ scope.row.taskName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="type" :label="$t('header.messageType')" align="center">
        <template slot-scope="scope">
          <span>{{ getTaskStatusDictionary(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" :label="$t('operation.Operation')" align="center">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="$t('operation.Detail')" placement="top">
            <img
              v-hover
              src="~@/assets/home/subplatForm/icon/find.png"
              alt="#"
              style="width: 25px; height: 25px"
              @click="messageNotificationsShowClick(scope.row, scope.$index)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="pagination.totalCount"
        :page-size="pagination.pageSize"
        :current-page.sync="pagination.pageNo"
        :page-sizes="[10, 20, 30, 50]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import { taskStatusDictionary } from '@/utils/dictionary';
export default {
  name: 'Index',
  props: {
    messageList: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    messageNotificationsShowClick(taskItem, index) {
      this.$emit('messageNotificationsShowClick', taskItem, index);
    },
    getTaskStatusDictionary(type) {
      return taskStatusDictionary.find((v) => v.value === type)?.label;
    },
    // 分页
    handleCurrentChange(pageNo) {
      this.$emit('paginationChange', {
        pageNo,
        pageSize: this.pagination.pageSize
      });
    },
    handleSizeChange(pageSize) {
      this.$emit('paginationChange', {
        pageNo: this.pagination.pageNo,
        pageSize
      });
    }
  }
};
</script>

<style scoped lang="scss">
.task-notifications {
  .serial-wrap-number {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: red;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .task-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ::v-deep {
    el-table__body-wrapper {
      background-color: #0a0b0d;
    }
  }
}
</style>
