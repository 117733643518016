<template>
  <div class="videoWallControl">
    <div class="airplane_mode mt10">
      <div class="label ml10">{{$t('home.FlightMode')}}：</div>
      <el-select
        v-model="uav_status"
        style="width: 110px"
        :placeholder="$t('home.pleaseSelect')"
        @change="changeMode"
      >
        <el-option
          v-for="item in uav_status_options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div class="label ml10">{{$t('home.SelectedUAV')}}：</div>
      <el-tooltip
        :content="(this.$uav && this.$uav.name) || $t('home.no')"
        placement="top"
        :hide-after="1000"
        effect="dark"
      >
        <div class="deviceBox">
          {{ (this.$uav && this.$uav.name) || $t('home.no') }}
        </div>
      </el-tooltip>
      <div
        :class="`ctrl-icon ${
          isControl() ? 'icon--take-over-control-on' : 'icon--take-over-control'
        }`"
      />
    </div>
    <div class="drone-operation">
      <div class="drone-operation-left">
        <div
          class="drone-operation-item"
          :class="{ active: selectOperation === 1 ? true : false }"
          @click="onPosition"
        >
          <img src="~@/assets/uavApplications/drone-operation/定位.png" alt />
          <div>{{$t('home.flightPositioning')}}</div>
        </div>
        <div
          class="drone-operation-item"
          :class="{ active: selectOperation === 2 ? true : false }"
          @click="onSwitchAirline"
        >
          <img src="~@/assets/uavApplications/drone-operation/轨迹.png" alt />
          <div>{{$t('home.flightPath')}}</div>
        </div>
        <div
          class="drone-operation-item"
          :class="{ active: selectOperation === 3 ? true : false }"
          @click="onPauseFly"
        >
          <img
            src="~@/assets/uavApplications/drone-operation/暂停飞行.png"
            alt
          />
          <div v-if="isPause">{{$t('home.pauseFlight')}}</div>
          <div v-else>{{$t('home.flying')}}</div>
        </div>
        <!-- <div class="drone-operation-item" @click="onTakeOff"
                    :class="{ active: selectOperation === 4 ? true : false }">
                    <img src="~@/assets/uavApplications/drone-operation/一键起飞.png" alt />
                    <div>一键起飞</div>
                </div> -->
        <div
          class="drone-operation-item"
          :class="{ active: selectOperation === 5 ? true : false }"
          @click="onReturnFlight"
        >
          <img
            src="~@/assets/uavApplications/drone-operation/一键返航.png"
            alt
          />
          <div>{{$t('home.keyToReturn')}}</div>
        </div>
        <div
          class="drone-operation-item"
          :class="{ active: selectOperation === 6 ? true : false }"
          @click="onLand"
        >
          <img
            src="~@/assets/uavApplications/drone-operation/安全降落.png"
            alt
          />
          <div>{{$t('home.safeLanding')}}</div>
        </div>
        <div
          class="drone-operation-item"
          :class="{ active: selectOperation === 7 ? true : false }"
          @click="onPointingFlight"
        >
          <img
            src="~@/assets/uavApplications/drone-operation/指点飞行.png"
            alt
          />
          <div>{{$t('home.guidanceFlight')}}</div>
        </div>
        <!-- <div
              class="drone-operation-item"
              @click="onOperationLog"
              :class="{ active: selectOperation === 10 ? true : false }"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/operationLog.png"
                alt
              />
              <div>运行日志</div>
            </div> -->
        <template>
          <div v-if="flyMode == 'manual'" class="drone-operation-item"
            :class="{ active: selectOperation === 8 ? true : false }"
            @click="modeAuto"
          >
            <img src="~@/assets/uavApplications/drone-operation/手动.png" alt />
            <div>{{$t('home.manual')}}</div>
          </div>
          <div
            v-else  class="drone-operation-item" :class="{ active: selectOperation === 9 ? true : false }"
            @click="modeManual"
          >
            <img src="~@/assets/uavApplications/drone-operation/手动.png" alt />
            <div>{{$t('home.automatic')}}</div>
          </div>
        </template>
        <div
          class="drone-operation-item"
          :class="{ active: selectOperation === 7 ? true : false }"
          @click="takeOverTheDrone($uav)"
        >
          <img
            src="~@/assets/uavApplications/drone-operation/指点飞行.png"
            alt
          />
          <div>{{ isControl() ? $t('home.exitTakeOver') : $t('home.takeover')}}</div>
        </div>
      </div>
    </div>
    <!-- <OperationLog
              @onCloseOperationLog="closeOperationLog"
              v-if="showOperationLog"
            /> -->
  </div>
</template>
<script>
import OperationLog from "@/components/observe1/operationLog/index.vue";
import { mapState, mapMutations } from "vuex";
import { clearCookie } from "@/utils";
import { permissionIds } from "@/utils/permission";
import { Tg, Control_API } from "@/api";
let handler = null;
let Point = null;
export default {
  data() {
    return {
      isLogin: localStorage.getItem("tmj_token"),
      showOperationLog: false,
      uav_status: "LAND",
      uav_status_options: [
        {
          value: "LAND",
          label: this.$t('home.safeLanding'),
        },
        {
          value: "AUTO_RTL",
          label:  this.$t('home.returnFlightMode'),
        },
        {
          value: "AUTO_MISSION",
          label: this.$t('home.flightRouteMode'),
        },
        {
          value: "POSITION",
          label: this.$t('home.GPSMode'),
        },
      ],
      collapse: true,
      flightInfo: {},
      show_ms: 1,
      onOperation: false,
      selectOperation: 0,
      isPause: true, // 暂时飞行
      user_info: JSON.parse(window.localStorage.getItem("user_info")),
    };
  },
  // components:{OperationLog},
  computed: {
    ...mapState(["userInfo"]),
    onOperationLog() {
      this.selectOperation = 10;
      this.showOperationLog = !this.showOperationLog;
    },

    closeOperationLog() {
      this.showOperationLog = false;
    },
    stat() {
      const { data } = this;
      return {
        // altitude: data && data.gps ? data.gps.absoluteAlt : 0,
        battaryRemain: data && data.battaryRemain ? data.battaryRemain : 0,
        flyTime: data
          ? ((data.flightTime || 0.1) / 1000).toFixed(2).replace(".", ":")
          : 0,
        flyDistance:
          data && data.flightDistance ? data.flightDistance.toFixed(2) : 0,
        distanceToHome:
          data && data.distanceToHome ? data.distanceToHome.toFixed(2) : 0,
      };
    },
    battery() {
      return this.batteryData;
    },
    speed() {
      const { data } = this;
      // return {
      //   airSpeed: data ? data.airspeed : 0,
      //   groundSpeed: data ? data.groundSpeed : 0,
      // };
      return {
        airSpeed: data ? data.airSpeed : 0,
        groundSpeed: data ? data.groundSpeed : 0,
      };
    },
    height() {
      const { data } = this;
      // return {
      //   altitude: data && data.gps ? Math.abs(data.gps.absoluteAlt) : 0,
      //   height: data && data.gps ? Math.abs(data.gps.relativeAlt) : 0,
      // };
      return {
        height: data ? Math.abs(data.height) : 0,
        altitude: data ? data.altitude : 0,
      };
    },
    pitchyaw() {
      const { data } = this;
      return {
        yaw: data ? data.yaw : 0,
        roll: data ? data.roll : 0,
        pitch: data ? data.pitch : 0,
        climbRate: data ? data.climbRate : 0,
      };
    },

    flightTask() {
      return this.flight_info();
    },

    $uav() {
      return this.$store.state.uavApplications.uav;
    },

    // 飞行模式
    flyMode: {
      get() {
        return this.$store.state.uavApplications.flyMode;
      },
      set(val) {
        this.$store.commit("uavApplications/setState", {
          key: "flyMode",
          value: val,
        });
      },
    },
  },
  beforeDestroy() {
    if (Point) {
      window.viewer.entities.remove(Point);
    }
  },
  methods: {
    ...mapMutations([
      "SET_CESIUM_STATE",
      "SET_OPEN_LEFT_NAV_SILDER",
      "SET_PERMISSION",
      "SET_LEFT_MENU_ACTION",
      "SET_SUBPLAT_FORM_STATUS",
    ]),
    isControl() {
      if (this.$uav) {
        if (
          this.$uav.currentOperator &&
          this.$uav.currentOperator === this.user_info.id
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    messageBoxShow(title, message, fun) {
      if (!title || !message) return console.log("请输入提示标题以及提示内容");
      this.$msgbox({
        title,
        message,
        showCancelButton: true,
        confirmButtonText: this.$t('operation.Confirm'),
        cancelButtonText: this.$t('operation.Cancel'),
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {
        fun && fun();
      });
    },
    takeOverTheDrone(data) {
      if (!this.limitation(true)) return false;
      if (!data.online) {
        return;
      }
      if (data.currentOperator === this.userInfo.id) {
        // 是否被本账号接管
        this.messageBoxShow(this.$t('home.exitTakeOver'),
          `${this.$t('home.isExitTakeOver')}${data.name}`,
          async () => {
            const res = await Tg.setUavControlOff(data.id);
            if (res.code === 200) {
              this.$message.success(`${this.$t('home.HaveBeenTakenOver')}${data.name}`);
              this.$store.commit("uavApplications/setState", {
                key: "uav",
                value: { ...data, currentOperator: (data.currentOperator = 0) },
              });
            }
          }
        );
      } else if (data.currentOperator) {
        // 不是本账号接管的
        this.messageBoxShow(
          this.$t('operation.Hint'),
          `当前选择的无人机正在被其他用户接管，请稍后操作？`
        );
      } else {
        // 未被接管
        this.messageBoxShow(this.$t('home.takeoverUAV'), `${this.$t('home.takeOverOrNot')}${data.name}`, async () => {
          const res = await Tg.setUavControlOn({
            force: false,
            id: data.id,
            uid: this.userInfo.id,
          });
          console.log(res, "XXX");
          if (res.code === 200) {
            this.$message.success(`${this.$t('home.takeOverSuccess')}${data.name}`);
            this.$store.commit("uavApplications/setState", {
              key: "uav",
              value: { ...data, currentOperator: this.user_info.id },
            });
          }
        });
      }
    },
    isLoginFn() {
      if (!this.isLogin) {
        this.$confirm(this.$t('home.noPermissionLogout'), this.$t('operation.Hint'), {
          cancelButtonText: this.$t('operation.Cancel'),
          confirmButtonText: this.$t('operation.Confirm'),
          showClose: false,
        }).then(() => {
            return new Promise((resolve) => {
              this.SET_OPEN_LEFT_NAV_SILDER(false);
              window.localStorage.removeItem("tmj_token");
              window.localStorage.removeItem("user_info");
              this.SET_PERMISSION([]);
              clearCookie();
              this.$store.commit("subplatform/SET_SUBPLAT_FORM_STATUS", false);
              this.$message.success(this.$t('login.logoutMessage'));
              this.$bus.$emit("reloadHome");
              resolve(); // 返回一个 Promise 对象并调用 resolve() 方法
            });
          })
          .then(() => {
            this.$confirm("", this.$t('operation.Hint'), {showClose: false,});
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t('home.CancelledOperation'),
            });
          });
        return false;
      }
      return true;
    },
    isPurview(pid) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    // 563 为首页无人机 控制权限
    // 提示
    messageBox() {
      this.$msgbox({
        title: this.$t('operation.Hint'),
        message: this.$t('home.takeOverMessage'),
        showCancelButton: true,
        confirmButtonText: this.$t('operation.Confirm'),
        cancelButtonText: this.$t('operation.Cancel'),
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {});
    },
    limitation(type) {
      if (!this.isLoginFn()) {
        return false;
      }
      if (!this.isPurview(563)) {
        this.$message.warning("暂无功能权限！");
        return false;
      }
      if (!type) {
        if (this.userInfo.id !== this.$uav.currentOperator) {
          // 不是本账号接管的无人机
          this.messageBox();
          return false;
        }
      }
      return true;
    },
    // 定位
    onPosition() {
      if (!this.limitation()) return false;
      this.selectOperation = 1;
      this.$store.dispatch("uavApplications/flyToUavModel");
      this.updateTakeOver();
    },
    // 开关显示航线
    onSwitchAirline() {
      if (!this.limitation()) return false;
      this.selectOperation = 2;
      this.$store.state.uavApplications.airlineEntity.show =
        !this.$store.state.uavApplications.airlineEntity.show;
      this.updateTakeOver();
    },
    // 暂停飞行
    onPauseFly() {
      if (!this.limitation()) return false;
      this.selectOperation = 3;
      if (this.isPause) {
        // 继续
        this.$store.dispatch("uavApplications/continueFly", {
          callback: (isOk) => {
            isOk && this.$message.success("飞行指令已下发");
          },
        });
      } else {
        // 暂停
        this.$store.dispatch("uavApplications/pauseFly", {
          callback: (isOk) => {
            isOk && this.$message.success("暂停指令已下发");
          },
        });
      }
      this.isPause = !this.isPause;
      this.updateTakeOver();
    },
    // 一键起飞
    onTakeOff() {
      if (!this.limitation()) return false;

      this.selectOperation = 4;
      if (!this.$store.state.uavApplications.airlineData) {
        this.$message.warning("请选择航线");
        return;
      }
      this.$confirm("请确认是否进行一键起飞操作?", this.$t('home.SafetyConfirmation'), {
        cancelButtonText: this.$t('operation.Cancel'),
        confirmButtonText: this.$t('operation.Confirm'),
        customClass: "uav_controlPane",
        showClose: false,
      }).then(async () => {
        this.updateTakeOver();
        this.$store.dispatch("uavApplications/takeOff", {
          callback: (isOk) => {
            isOk
              ? this.$message.success("发出起飞指令成功")
              : this.$message.warning("发出起飞指令失败");
          },
        });
      });
    },
    // 一键返航
    onReturnFlight() {
      if (!this.limitation()) return false;
      this.selectOperation = 5;
      this.$confirm("请确认是否进行一键返航操作?", this.$t('home.SafetyConfirmation'), {
        cancelButtonText: this.$t('operation.Cancel'),
        confirmButtonText: this.$t('operation.Confirm'),
        customClass: "uav_controlPane",
        showClose: false,
      }).then((res) => {
        this.updateTakeOver();

        this.$store.dispatch("uavApplications/returnFlight", {
          callback: (isOk) => {
            isOk && this.$message.success("发出返航指令成功");
          },
        });
      });
    },
    // 一键降落
    onLand() {
      if (!this.limitation()) return false;

      this.selectOperation = 6;
      this.$confirm("请确认是否进行安全降落操作?", this.$t('home.SafetyConfirmation'), {
        cancelButtonText: this.$t('operation.Cancel'),
        confirmButtonText: this.$t('operation.Confirm'),
        customClass: "uav_controlPane",
        showClose: false,
      }).then((res) => {
        this.updateTakeOver();
        this.$store.dispatch("uavApplications/land", {
          callback: (isOk) => {
            isOk && this.$message.success("发出降落指令成功");
          },
        });
      });
    },
    // 指点飞行
    onPointingFlight() {
      if (!this.limitation()) return false;

      this.selectOperation = 7;
      if (this.$uav.network == 1) {
        const viewer = window.viewer;
        // 指点飞行 wgs84
        let position = null;
        this.$message("请点击地图获取目标位置");
        handler = new window.Cesium.ScreenSpaceEventHandler(viewer.canvas);
        handler.setInputAction((movement) => {
          const earthPosition = viewer.camera.pickEllipsoid(
            movement.position,
            viewer.scene.globe.ellipsoid
          );
          const cartographic = Cesium.Cartographic.fromCartesian(
            earthPosition,
            viewer.scene.globe.ellipsoid,
            new Cesium.Cartographic()
          );
          const lng = Cesium.Math.toDegrees(cartographic.longitude);
          const lat = Cesium.Math.toDegrees(cartographic.latitude);
          if (Point) {
            viewer.entities.remove(Point);
          }
          console.log(movement.position, " movement.position");
          // let position = window.viewer.scene.camera.pickEllipsoid(
          //   movement.position
          // );
          (position = Cesium.Cartesian3.fromDegrees(lng, lat, 20)),
            (Point = this.addPoint(window.viewer, position, "目标点"));

          this.$confirm(
            "位置获取成功，请确认是否进行指点模式飞行?",
            "引导飞行确认",
            {
              cancelButtonText: this.$t('operation.Cancel'),
              confirmButtonText: this.$t('operation.Confirm'),
              customClass: "uav_controlPane",
              showClose: false,
            }
          )
            .then(() => {
              console.log(
                {
                  data: {
                    latitude: lat,
                    longitude: lng,
                    speed: 6,
                  },
                  type: 518,
                },
                "指点移动指令下发"
              );
              this.$store.dispatch("uavApplications/routeControl", {
                latitude: lat,
                longitude: lng,
                speed: 6,
                callback: (isOk) => {
                  isOk && this.$message.success("发出指点飞行指令成功");
                },
              });
              handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            })
            .catch(() => {
              this.zdfcFlag = true;
              if (Point) {
                window.viewer.entities.remove(Point);
              }
              handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      } else {
        this.controlWsClick(14, 7);
      }
      this.updateTakeOver();
    },
    controlWsClick(select, num) {
      if (select === 21) {
        this.flyMode = "manual";
      } else if (select === 22) {
        this.selectOperation = 0;
        this.flyMode = "auto";
      }
      if (this.flyMode == "manual") {
        this.wsControl(select);
        this.selectOperation = num;
      }
    },
    addPoint(viewer, position, name) {
      return viewer.entities.add({
        position,
        point: {
          pixelSize: 12,
          color: Cesium.Color.fromCssColorString("#ee0000"),
          outlineColor: Cesium.Color.fromCssColorString("#fff"),
          outlineWidth: 1,
          show: true,
        },
        label: {
          text: name,
          scale: 0.5,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          outlineWidth: 0,
          pixelOffset: new Cesium.Cartesian2(26, -20),
        },
      });
    },
    modeManual() {
      if (!this.limitation()) return false;

      this.selectOperation = -1;
      this.$store.dispatch("uavApplications/modeManual", {
        callback: () => {},
      });
      this.flyMode = "manual";
    },
    modeAuto() {
      if (!this.limitation()) return false;

      this.selectOperation = -1;
      this.$store.dispatch("uavApplications/modeAuto", {
        callback: () => {},
      });
      this.flyMode = "auto";
    },
    clickMount(status) {
      this.wsControl(status);
    },
    // 无人机接管更新状态
    async updateTakeOver() {
      await Control_API.updateTakeOver(this.$uav.id);
    },
    changeMode(mode) {
      if (!this.limitation()) return false;

      const modeName = this.uav_status_options.filter(
        (e) => e.value === mode
      )[0].label;
      this.$store.dispatch(`uavApplications/mode${mode}`, {
        callback: (isOk) => {
          isOk && this.$message.success(`${modeName}指令已下发`);
        },
      });
      this.updateTakeOver();
    },
    controlWsClick(select, num) {
      if (select === 21) {
        this.flyMode = "manual";
      } else if (select === 22) {
        this.selectOperation = 0;
        this.flyMode = "auto";
      }
      if (this.flyMode == "manual") {
        this.wsControl(select);
        this.selectOperation = num;
      }
    },
    wsControl(e) {
      this.$nextTick(() => {
        this.$bus.$emit("control_ws", e);
      });
    },
    operation(e) {
      if (this.onOperation) {
        const box = document.getElementById("right-click-operation");
        box.classList.forEach((e) => box.classList.remove(e));
        const centerX = box.offsetWidth / 2;
        const centerY = box.offsetHeight / 2;
        const dx = e.offsetX - centerX;
        const dy = e.offsetY - centerY;
        const theta = Math.atan2(dy, dx);
        const r = Math.sqrt(dx * dx + dy * dy);
        const angle = (theta * 180) / Math.PI;
        if (r < 16) {
          box.classList.add("middle");
          console.log("中");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 16);
          });
          return "中";
        } else if (angle >= -45 && angle < 45) {
          box.classList.add("right");
          console.log("右");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 3);
          });
          return "右";
        } else if (angle >= 45 && angle < 135) {
          box.classList.add("down");
          console.log("下");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 4);
          });
          return "下";
        } else if (angle >= -135 && angle < -45) {
          box.classList.add("up");
          console.log("上");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 1);
          });
          return "上";
        } else {
          box.classList.add("left");
          console.log("左");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 2);
          });
          return "左";
        }
      }
    },
    // clickOperation() {
    //   document.getElementById("right-click-operation").addEventListener("click",this.operation)
    //   },
    //   removeClickOperation(){
    //     document.getElementById("right-click-operation").removeEventListener("click",this.operation)
    //   }
  },
};
</script>
<style lang="scss" scoped>
.videoWallControl {
  width: 428px;
  height: 25vh;
  background: #0a0b0d;
  border-radius: 4px;
  margin-left: 12px;
  margin-top: 18px;
  position: absolute;
  bottom: -33px;

  .airplane_mode {
    display: flex;
    align-items: center;
    color: #696c74;

    .label {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 20px;
    }

    .deviceBox {
      width: 112px;
      height: 26px;
      line-height: 26px;
      background: #16181b;
      border-radius: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid #233a58;

      text-indent: 1rem;
    }

    .ctrl-icon {
      width: 24px;
      height: 24px;

      &.icon--take-over-control {
        background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/take-over-control.png");
        background-size: 100% 100%;
      }

      &.icon--take-over-control-on {
        background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/take-over-control-on.png");
        background-size: 100% 100%;
      }
    }
  }

  .drone-operation {
    width: 100%;
    flex: 1;
    row-gap: 10px;
    background: #0a0b0d;
    border-radius: 0 4px 4px 4px;
    display: flex;
    padding: 8px;
    box-sizing: border-box;

    // height: 223px;
    .drone-operation-left {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      row-gap: 14px;
      column-gap: 44px;

      .drone-operation-item {
        cursor: pointer;
        width: 64px;
        height: 64px;
        background: #3a4252;
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #f8f8f8;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.active {
          background: url("~@/assets/uavApplications/drone-operation/Hover.png");
        }

        img {
          width: 32px;
          height: 32px;
        }

        &.more {
          background: #161d2b;
        }
      }

      .drone-operation-item:hover {
        background: url("~@/assets/uavApplications/drone-operation/Hover.png");
      }
    }

    .drone-operation-right {
      width: 154px;
      height: 209px;
      background: #161d2b;
      border: 1px solid #2b313d;
      border-radius: 4px;
      margin: 8px 6px 6px 0;
      color: #ccc;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .title {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #b3bbc5;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }

      .right-on-operation {
        display: flex;
        font-family: MicrosoftYaHei;
        font-size: 10px;
        color: #c6ccd4;
        letter-spacing: 0;
        font-weight: 400;
        cursor: pointer;

        &-left {
          text-align: center;
          line-height: 20px;
          width: 64px;
          height: 20px;
          background-color: #3c4251;
          border-radius: 30px 0 0 30px;
        }

        &-right {
          text-align: center;
          line-height: 20px;
          width: 64px;
          height: 20px;
          background-color: #3c4251;
          border-radius: 0 30px 30px 0;
        }

        .active {
          color: #0a0b0d;
          background-color: #50abff;
        }
      }

      .right-bottom-operation {
        display: flex;
        flex-wrap: wrap;

        .bottom-operation-item {
          cursor: pointer;
          text-align: center;
          margin: 4px 0 0 4px;
          width: 71px;
          height: 26px;
          line-height: 26px;
          background: #3a4252;
          border-radius: 4px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #b3bbc5;
          letter-spacing: 0;
          font-weight: 400;

          &:hover {
            background: #50abff;
            color: #fff;
          }
        }
      }
    }
  }
}

/* 当设备高度大于或等于600px时应用样式 */
@media screen and (min-height: 1000px) {
  .videoWallControl {
    position: absolute;
    bottom: 50px !important;

    .drone-operation {
      .drone-operation-left {
        margin-top: 20px;
      }
    }
  }
}
</style>

<style>
  .el-message-box__btns{
    display: flex;
  }
</style>