<template>
  <div class="videoWallmaxBox" :class="{ 'videoWallmaxBox--iframe': isIframe }">
    <div class="videoWallBox">
      <div class="videoLeftBg">
        <div class="pt270">
          <ball />
          <flyData />
        </div>
      </div>
    </div>
    <control />
    <videoList />
  </div>
</template>
<script>
import ball from './com/ball';
import flyData from './com/flyData';
import control from './com/control';

import videoList from './com/videoList/index.vue';
import { mapState } from 'vuex';

export default {
  components: {
    ball,
    flyData,
    control,
    videoList
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['isIframe'])
  },
  mounted() {
    // 改变地图大小 首页
    this.$store.commit('subplatform/SET_MAP_STYLE', 'videoWallMapStyle');
  },
  beforeDestroy() {
    this.$store.commit('subplatform/SET_MAP_STYLE', null);
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.videoWallmaxBox {
  width: 100%;
  height: 100%;
  background: #000000;
}
.videoWallBox {
  position: fixed;
  left: 0px;
  top: 46px;
  width: 447px;
  z-index: -1;
  height: 100%;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  // border-radius: 8px;
  backdrop-filter: blur(4px);

  .videoLeftBg {
    padding-bottom: 10px;
    height: 100%;
    background: url('./images/videoLeftBg.png');
  }
}
.videoWallmaxBox--iframe {
  position: fixed;
  left: 0;
  top: 0;

  .videoWallBox {
    position: absolute;
    top: 0;
  }
}
</style>
