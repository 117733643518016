/*
 * @Author: kai 17660422924@163.com
 * @Date: 2024-01-19 18:22:52
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-01-20 17:04:43
 * @FilePath: \mmc-vue-tianmu\src\views\uavApplications\components\flight-popup2\three-dimensionalv2\tagging\message\bubbleModel\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import Label from './index.vue';
const WindowVm = Vue.extend(Label);
export default class Bubble {
  constructor(val) {
    console.log(val, 'val');
    this.viewer = val.viewer;
    let position = null;
    if (val.markInfo) {
      try {
        position = Cesium.Cartesian3.fromDegrees(
          Number(val.markInfo.lng),
          Number(val.markInfo.lat),
          2
        );
      } catch (error) { }
    }
    this.position = position;
    const dataList = val.markInfo
    const id = val.entities._id;
    this.id = id
    this.vmInstance = new WindowVm({
      propsData: {
        data: dataList,
        id
      }
    }).$mount(); // 根据模板创建一个面板

    this.vmInstance.closeEvent = (e) => {
      this.vmInstance.$el.remove();
      this.vmInstance.$destroy();
    };

    val.viewer.cesiumWidget.container.appendChild(this.vmInstance.$el); // 将字符串模板生成的内容添加到DOM上

    this.addPostRender();
  }

  // 添加场景事件
  addPostRender() {
    this.viewer.scene.postRender.addEventListener(this.postRender, this);
  }

  // 场景渲染事件 实时更新窗口的位置 使其与笛卡尔坐标一致
  postRender() {
    if (!this.vmInstance.$el || !this.vmInstance.$el.style) return;
    const canvasHeight = this.viewer.scene.canvas.height;
    const windowPosition = new Cesium.Cartesian2();
    Cesium.SceneTransforms.wgs84ToWindowCoordinates(
      this.viewer.scene,
      this.position,
      windowPosition
    );
    this.vmInstance.$el.style.bottom = canvasHeight - windowPosition.y + 'px';
    const elWidth = this.vmInstance.$el.offsetWidth;
    this.vmInstance.$el.style.left = windowPosition.x - elWidth / 2 + 'px';

    const camerPosition = this.viewer.camera.position;
    let height = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(camerPosition).height;
    height += this.viewer.scene.globe.ellipsoid.maximumRadius;
    if (
      !(Cesium.Cartesian3.distance(camerPosition, this.position) > height) &&
      this.viewer.camera.positionCartographic.height < 50000000
    ) {
      this.vmInstance.$el.style.display = 'block';
    } else {
      this.vmInstance.$el.style.display = 'none';
    }
  }
  // 关闭
  windowClose(id) {
    if (this.vmInstance) {
      this.vmInstance.closeClick()
    }
    // this.vmInstance.$el.style.display = "none"; //删除dom
    this.viewer.scene.postRender.removeEventListener(this.postRender, this); // 移除事件监听
  }
}
