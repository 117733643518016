<script>
export default {
  name: 'Index',
  props: {
    statisticsList: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<template>
  <div class="statistics-card">
    <div v-for="v in statisticsList" :key="v.id" class="card-item">
      <div class="label">{{ v.label }}</div>
      <div class="value">
        <span>{{ v.value }}</span>
        <span class="unit">{{ v.unit }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.statistics-card {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  .card-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 74px;
    background: linear-gradient(360deg, rgba(69, 86, 119, 0.1) 0%, rgba(69, 86, 119, 0.4) 100%);
    border-radius: 4px;
    .label {
      font-size: 14px;
      color: #e3eeff;
    }
    .value {
      font-size: 22px;
      color: #e8f2ff;
      margin-top: 8px;
      .unit {
        font-size: 14px;
        color: #e3eeff;
        margin-left: 14px;
      }
    }
  }
}
</style>
