<template>
  <div class="panoramicDisplay">
    <div class="title cp">{{$t('navibar.PanoramicDisplay')}}</div>
    <div class="cf">
      <div v-for="(item, index) in imgList" :key="item" class="item">
        <div class="p5 jcsb pl10 itemCenter">
          {{ item.name }}
          <div>
            <div class="cp f12" @click="showDrawer(item, index)">{{$t('operation.Check')}}</div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      :wrapperClosable="false"
      :destroy-on-close="true"
      class="my-custom-drawer"
      :title="$t('threeDApplication.preview')"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div id="PhotoSphereViewer" class="panoramicDisplaycClass"></div>
      <div class="mt10 imgListBox pl20">
        <img
          crossorigin="anonymous"
          class="dib w100 h60 m10 cp"
          :class="{ active: i == itemIndex, default: i != itemIndex }"
          v-for="(item, i) in imgList"
          @click="changeImg(item, i)"
          :key="i"
          :src="item.url"
          :alt="item.name"
        />

      </div>
    </el-drawer>
  </div>
</template>
<script type="module">
import { projectAPI } from "@/api";
import { Viewer } from "@photo-sphere-viewer/core";
import "@photo-sphere-viewer/core/index.css";
export default {
  data() {
    return {
      drawer: false,
      direction: "rtl",
      PhotoSphereViewer: null,
      imgList: [],
      itemIndex: 0,
    };
  },
  mounted() {
    this.dimensionMapPageAll();
  },
  methods: {
    changeImg(item, index) {
      if (index != this.itemIndex) {
        this.itemIndex = index;
        this.PhotoSphereViewer.setPanorama(item.url); // 切换全景图
      }
    },
    async dimensionMapPageAll() {
      // ?sysType=1&appId=0&versions=NULL&status=NORMAL&currentPage=1&pageSize=10&type=5
      const res = await projectAPI.dimensionMapPageAll({
        currentPage: 1,
        pageSize: 110,
        type: 5,
      });
      if (res.code == 200) {
        this.imgList = res.data.items;
      }
    },
    showDrawer(item, index) {
      this.itemIndex = index;
      this.drawer = true;
      setTimeout(() => {
        this.init(item);
      }, 200);
    },
    init(item) {
      console.log(item.url, "item.url");
      this.PhotoSphereViewer = new Viewer({
        container: document.getElementById("PhotoSphereViewer"),
        panorama: item.url, // 这里设置全景图路径
        // 截图功能
        caption: "Parc national du Mercantour <b>&copy; Damien Sorel</b>",
        navbar: [
          "autorotate",
          "zoom",
          "markers",
          "move",
          "fullscreen",
          "download",
          {
            content: this.$t('threeDApplication.screenshot'),
            onClick(viewer) {
              viewer.addEventListener(
                "render",
                () => {
                  const link = document.createElement("a");
                  link.download = "screenshot.png";
                  link.href = viewer.renderer.renderer.domElement.toDataURL();
                  link.click();
                },
                { once: true }
              );
              viewer.needsUpdate();
            },
          },
        ],
      });
      // 双击缩放到目标为止
      this.PhotoSphereViewer.addEventListener("dblclick", ({ data }) => {
        this.PhotoSphereViewer.animate({
          yaw: data.yaw,
          pitch: data.pitch,
          zoom: 100,
          speed: 1000,
        });
      });
    },
    handleClose(drawer) {
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.panoramicDisplay {
  position: relative;
  .imgListBox {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: start;
  }
  .title {
    position: absolute;
    top: -30px;
    left: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #a4ff64;
  }
  .itemCenter{
    align-items: center;
  }

  .panoramicDisplaycClass {
    width: 100%;
    height: 400px;
  }

  ::v-deep .el-drawer {
    background-color: #000 !important;
    //height: 95vh !important;
    //top: 48px !important;
  }
  .active {
    border: 1px solid red;
  }
  .default {
    border: 1px solid transparent;
  }
}
</style>
