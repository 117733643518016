<template>
  <div ref="headerSearch" class="header-search">
    <div v-show="show" class="body">
      <div>
        <el-autocomplete
          ref="autocomplete"
          v-model="destination.name"
          :fetch-suggestions="querySearchAsync"
          :placeholder="searchContent"
          clearable
          :popper-append-to-body="false"
          select-when-unmatched
          @select="handleSelect"
          @keyup.enter="onQuery"
          @blur="hiddle"
          @clear="$refs.autocomplete.focus()"
        >
          <i slot="prefix">
            <div class="iconfont icon-tchaxun" />
          </i>
          <template slot-scope="{ item }">
            <div>
              {{ item.name || item.carNo || "" }}
            </div>
          </template>
        </el-autocomplete>
      </div>
      <!-- <div class="searchPoint"
           :class="isDraw?'icon_border':''"
           @click="draw">
        <span class="icon iconfont "
              style="font-weight: 400;color:#50ABFF;cursor: pointer;">&#xe6cb;</span>
      </div> -->
      <el-button @click="onQuery">{{$t('header.query')}}</el-button>
    </div>
    <div v-show="!show" class="xiao" @click="showSearch">
      <div class="iconfont icon-tchaxun" />
    </div>
  </div>
</template>

<script>
import { Utils } from "@/utils/cesium";
// import { lineString, along } from '@turf/turf'l
import * as turf from "@turf/turf";
import { Map } from "@/api";
import { gcj02towgs84 } from "@/utils/coordtransform";
import iconLocation from "@/assets/image/icons/icon_location2.png";

let dataSrouce;
let handler;
let tempEntities = [];
let tempPoints = [];
let pointsFormPolygon = [];
let pointsFromList = [];
let viewer;
const entitiesPoints = {};
export default {
  props: {
    itemList: {
      type: Array,
      default: () => [],
    },
    // 当前导航选中的id
    navId: {
      type: Number | String | null,
      default: "",
    },
  },

  data() {
    return {
      show: false,
      destination: {
        name: "",
        lng: 0,
        lat: 0,
      },
      timeout: null,
      searchList: [],
      isDraw: false,
      // pointsArr: [],
      // isShow: false
      searchContent: "",
      geoPointEntity: null, // 定位后的画点的实例
      timer: null,
      leftClickHandler: null,
    };
  },

  computed: {},

  watch: {
    itemList: {
      immediate: true,
      handler(val) {
        this.searchList = val;
        pointsFromList = this.searchList.map((val) => {
          const arr = [];
          arr.push(val.lon, val.lat);
          return arr;
        });
      },
    },
    navId: {
      immediate: true,
      handler(newVal) {
        // switch (newVal) {
        //   case 1: // 无人机
        //     this.searchContent = '请输入无人机名称/ID/地理位置';
        //     break;
        //   case 2: // 飞手
        //     this.searchContent = '请输入飞手/ID/地理位置';
        //     break;
        //   case 3: // 服务网点
        //   case 4: // 培训网点
        //   case 5: // 展示网点
        //     this.searchContent = '请输入网点/ID/地理位置';
        //     break;
        //   case 6: // 运行项目
        //     this.searchContent = '请输入项目名称/地理位置';
        //     break;
        //   default:
        //     this.searchContent = '地理位置/经纬度';
        // }
        this.searchContent = this.$t('header.locationSearch')  //地点搜索;
      },
    },
    show: {
      handler(val) {
        if (!val) {
          // viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK, this.leftClickHandler);
        } else {
          // 点击事件处理函数
          this.leftClickHandler = viewer.screenSpaceEventHandler.setInputAction(
            (e) => {
              this.show = false;
            },
            Cesium.ScreenSpaceEventType.LEFT_CLICK
          );
        }
      },
    },
  },
  mounted() {
    viewer = window.viewer;
    /* this.$bus.$on("handleCesiumState", e => {
      this.isShow = e
    }) */
    /* this.$bus.$on("handleZhedie2", (e) => {
      if(!this.$refs.headerSearch){
        return;
      }
      if (!e) {
        this.$refs.headerSearch.style.left = "520px"
      } else {
        this.$refs.headerSearch.style.left = "136px"
      }
    }) */
  },
  methods: {
    showSearch() {
      this.show = !this.show;
      this.$nextTick(() => {
        this.$refs.autocomplete.focus();
      });
    },
    hiddle() {
      // clearTimeout(this.timer)
      // this.timer = setTimeout(() => {
      //   if (!this.destination.name) {
      //     this.show = !this.show
      //   }
      // }, 500)
      if (!this.destination.name) {
        this.show = false;
      }
    },
    async querySearchAsync(queryString, cb) {
      console.log("queryString", queryString, this.searchList);
      const list = this.searchList;
      let result = queryString
        ? list.filter(this.getSearchList(queryString))
        : list;

      if (result.length === 0) {
        const res = await Map.geo({
          keywords: this.destination.name,
        });
        console.log("res", res);
        if (res.data.status === "1") {
          const list = res.data.tips.filter((item) => {
            return typeof item.location === "string";
          });
          result = list.map((item) => {
            item.name = item.district + " " + item.name;
            if (item.location && item.location.length) {
              return item;
            }
            return false;
          });
        }
      }
      cb(result);
    },
    getSearchList(queryString) {
      return (val) => {
        let state = false;
        if (val.name) {
          state = val.name.indexOf(queryString) >= 0;
        } else if (val.carNo) {
          state = val.carNo.indexOf(queryString) >= 0;
        }
        return state;
      };
    },
    // 下拉选中
    handleSelect(item) {
      console.log("item", item);
      if (item.id === undefined) {
        // 未选中按回车
        this.onQuery();
        return;
      }

      // 高德搜索返回的数据带location属性
      try {
        if (item.location || item.location.length) {
          this.destination.name = item.name;
          const pos = item.location.split(",");
          // 高德返回的是GCJ-02， 转换为 WGS-84
          const transPos = gcj02towgs84(Number(pos[0]), Number(pos[1]));
          item.lon = transPos[0];
          item.lat = transPos[1];

          if (this.geoPointEntity) {
            window.viewer.entities.remove(this.geoPointEntity);
          }

          this.geoPointEntity = this.locationFlag(item);
        }
      } catch (e) {
        this.$message({
          type: "warning",
          message: "查询失败，未找到此地址经纬度",
        });
        console.log("高德搜索返回的数据带location属性", e);
      }

      this.flyTo(item);
    },
    // 定位标记
    locationFlag({ lon, lat, id }) {
      if (entitiesPoints[id]) {
        window.viewer.entities.remove(item);
        entitiesPoints[id] = null;
      }

      const position = window.Cesium.Cartesian3.fromDegrees(lon, lat);
      const point = window.viewer.entities.add({
        position,
        billboard: {
          width: 36,
          height: 36,
          image: iconLocation,
          horizontalOrigin: window.Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: window.Cesium.VerticalOrigin.Bottom,
          heightReference: window.Cesium.HeightReference.CLAMP_TO_GROUND,
        },
        id: id,
        // id: 'search-location'
      });
      entitiesPoints[id] = point;
      return point;
    },
    flyTo(item) {
      const viewer = window.viewer;
      const position = Utils.transformWGS842Cartesian({
        lng: Number(item.lon),
        lat: Number(item.lat),
        alt: 3000,
      });
      viewer.camera.flyTo({
        destination: position,
      });
    },
    // 绘制面
    async draw() {
      this.isDraw = !this.isDraw;
      console.log(" this.isDraw ", this.isDraw);
      if (this.isDraw) {
        const viewer = window.viewer;

        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
          window.Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
        );

        if (!dataSrouce) {
          dataSrouce = new window.Cesium.CustomDataSource("draw_polygon");
          await viewer.dataSources.add(dataSrouce);
        }

        handler = new window.Cesium.ScreenSpaceEventHandler(viewer.canvas);

        handler.setInputAction(async (event) => {
          const windowPosition = event.position;
          console.log("windowPosition...", windowPosition);
          const pickedObject = viewer.scene.pick(windowPosition);
          console.log("pickedObject...", pickedObject);
          const position = Utils.getCartesian3FromPX(viewer, event.position);
          console.log("position...", position);

          // 世界坐标转经纬度
          // let ellipsoid = viewer.scene.globe.ellipsoid
          // let cartesian3 = new window.Cesium.Cartesian3(position.x, position.y, position.z)
          // let cartographic = ellipsoid.cartesianToCartographic(cartesian3)
          // let lat = window.Cesium.Math.toDegrees(cartographic.latitude)
          // let lng = window.Cesium.Math.toDegrees(cartographic.longitude)
          // console.log(lat, lng);
          // let pointPosition = window.Cesium.Cartographic.fromCartesian(position, ellipsoid)
          // console.log("pointPosition...", pointPosition);

          tempPoints.push(position);
          const tempLength = tempPoints.length;
          const point = this.drawPoint(position);
          tempEntities.push(point);
          if (tempLength > 1) {
            const pointLine = this.drawPolyLine([
              tempPoints[tempPoints.length - 2],
              tempPoints[tempPoints.length - 1],
            ]);
            tempEntities.push(pointLine);
          }
        }, window.Cesium.ScreenSpaceEventType.LEFT_DOWN);

        handler.setInputAction(async (event) => {
          const cartesian = Utils.getCartesian3FromPX(viewer, event.position);
          if (cartesian) {
            const tempLength = tempPoints.length;
            console.log("tempPoints...", tempPoints);
            console.log("tempLength...", tempLength);

            if (tempLength < 3) {
              this.$message({ type: "warning", message: "请绘制至少三个点！" });
            } else {
              const lastPointLine = this.drawPolyLine([
                tempPoints[tempPoints.length - 1],
                tempPoints[0],
              ]);
              tempEntities.push(lastPointLine);
              this.drawPolygon(tempPoints);
              tempEntities.push(tempPoints);
              handler = handler && handler.destroy();

              pointsFormPolygon = tempPoints.map((val) => {
                // 世界坐标转经纬度
                const ellipsoid = viewer.scene.globe.ellipsoid;
                const cartesian3 = new window.Cesium.Cartesian3(
                  val.x,
                  val.y,
                  val.z
                );
                const cartographic =
                  ellipsoid.cartesianToCartographic(cartesian3);
                const lat = window.Cesium.Math.toDegrees(cartographic.latitude);
                const lng = window.Cesium.Math.toDegrees(
                  cartographic.longitude
                );
                const arr = [];
                arr.push(lng);
                arr.push(lat);
                return JSON.parse(JSON.stringify(arr));
              });
              pointsFormPolygon.push(pointsFormPolygon[0]);
              const pointsInList = turf.points(pointsFromList);
              const searchWithin = turf.polygon([pointsFormPolygon]);
              const rightPoint = turf.pointsWithinPolygon(
                pointsInList,
                searchWithin
              );
              this.$bus.$emit("handleSearchItemList", rightPoint);
            }
          }
        }, window.Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      } else {
        if (dataSrouce) {
          window.viewer.entities.removeAll();
          // viewer.dataSources.remove(dataSrouce, true);
          // dataSrouce = null;
          tempEntities = [];
          tempPoints = [];
          handler = null;
          // positions = [];
        }
        handler = handler && handler.destroy();
        this.$bus.$emit("showSideList");
      }
    },
    // 画点
    drawPoint(p) {
      const pointEntity = window.viewer.entities.add({
        name: "pointItem",
        position: p,
        point: {
          color: window.Cesium.Color.fromCssColorString("#34DFFF"),
          pixelSize: 10,
          outLineColor: window.Cesium.Color.fromCssColorString("#34DFFF"),
          outLineWidth: 3,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
      });
      return pointEntity;
    },

    // 画线
    drawPolyLine(p) {
      if (p.length < 1) return;
      const polylineGeometry = window.viewer.entities.add({
        name: "lineItem",
        polyline: {
          positions: p,
          material: window.Cesium.Color.fromCssColorString("#2E63DC"),
          depthFailMaterial: window.Cesium.Color.fromCssColorString("#2E63DC"),
        },
      });
      return polylineGeometry;
    },
    // 画面
    drawPolygon(p) {
      if (p.length < 2) return;
      const polygonGeomery = window.viewer.entities.add({
        name: "polygonItem",
        polygon: {
          height: 0.1,
          hierarchy: new window.Cesium.PolygonHierarchy(p),
          material:
            window.Cesium.Color.fromCssColorString("#2E63DC").withAlpha(0.2),
          perPositionHeight: true,
        },
      });
      return polygonGeomery;
    },

    /**
     * 查询
     */
    async onQuery() {
      const find = this.searchList.find((item) => {
        return item.name === this.destination.name;
      });
      if (find) {
        this.flyTo(find);
      } else {
        // 搜索不到的情况下，支持经纬度搜索，格式为xx,xx或xx，xx
        console.log("this.destination", this.destination);
        const name = this.destination.name.replace("，", ",");
        const pos = name.split(",");
        if (pos.length === 2) {
          if (this.geoPointEntity) {
            window.viewer.entities.remove(this.geoPointEntity);
          }
          console.log("pos", pos);
          this.geoPointEntity = this.locationFlag(
            Number(pos[0]),
            Number(pos[1])
          );
          this.flyTo({
            lon: pos[0],
            lat: pos[1],
          });
        }
      }
    },

    /**
     * 处理运行项目
     */
    handleProject() {},
  },
};
</script>

<style lang="scss" scoped>
.header-search {
  position: absolute;
  z-index: 1;
  top: 56px;
  right: 4px;
  -moz-transition: right 0.25s; /* Firefox 4 */
  transition: right 0.25s; /* Safari and Chrome */
  -o-transition: right 0.25s; /* Opera */
  .body {
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border-radius: 8px;
    border: 1px solid #34dfff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;

    .searchPoint {
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon_border {
      border: 1px solid #000;
    }
    .iconfont {
      font-size: 24px;
      color: #b3bbc5;
      font-weight: 700;
      line-height: 32px;
      // height: 32px;
      // width: 32px;
      // display: flex;
      // justify-content: center;
    }
    
    &::v-deep {
      .el-autocomplete{
        display: flex;
      }
      .el-input {
        width: 227px;
        height: 32px;
        margin-right: 10px;
        .el-input__inner {
          // height: 100%;
          height: 32px;
          line-height: 32px;
          width: 100%;
          border-radius: 8px;
          border: none;
          color: #c6ccd4;
          background: #16181b;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #7f828a;
          letter-spacing: 0;
          font-weight: 400;
        }
        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }
  .xiao {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    background: #043d76;
    border-radius: 8px;
    border: 1px solid #01a0f6;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6px;
    width: 37px;
    height: 37px;
    cursor: pointer;
  }
  div {
    font-size: 36px;
    color: #34dfff;
  }
}
</style>
