<template>
  <div class="new-sesp-meta">
    <div class="sesp-bound">
      <el-image :src="img" fit="cover" style="width: 100%; vertical-align: bottom" />
    </div>
    <div class="sesp-meta-content">
      <div class="sesp-meta-product">
        <div class="title">产品简介</div>
        <div class="product-meta">
          <p>
            针对无人机专业建设中行业应用内容缺乏的问题，SESP-U1系统作为一款面向教育培训的
            <span>无人机行业应用仿真平台</span>
            ，依托精细化建模及飞行控制仿真技术，提供基础飞行
            <span>电力、安防、测绘、植保、编队飞行</span>
            等多个行业应用训练项目，为用户提供全套无人机仿真培训解决方案。
          </p>
        </div>
      </div>
      <div class="sesp-meta-application">
        <div class="title">应用场景</div>
        <div class="application-item">
          <el-row justify="space-between">
            <el-col
              v-for="(item, index) in applicationList"
              :key="index"
              :span="7"
              class="application-item-col"
            >
              <el-image :src="item.img" fit="cover" style="width: 100%; vertical-align: bottom" />
              <div class="application-item-meta">{{ item.meta }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="sesp-meta-download">
        <div class="title">软件下载</div>
        <div class="file-wrap">
          <img src="@/assets/home/file.png" />
          <span class="title">SESP-U1无人机仿真软件安装包</span>
        </div>
        <div class="download-option">
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="downloadClick('https://pan.baidu.com/s/1Aj9Pdj2yPgJJtYTC00oKaw?pwd=xbhe')"
            >点击下载</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveAs } from 'file-saver';
export default {
  data() {
    return {
      img: require('@/assets/home/sesp_bound.jpg'),
      applicationList: [
        {
          img: require('@/assets/home/sesp_application_01.jpg'),
          meta: '基础飞行'
        },
        {
          img: require('@/assets/home/sesp_application_02.jpg'),
          meta: '电力巡检'
        },
        {
          img: require('@/assets/home/sesp_application_03.jpg'),
          meta: '安防应用'
        },
        {
          img: require('@/assets/home/sesp_application_04.jpg'),
          meta: '影视航拍'
        },
        {
          img: require('@/assets/home/sesp_application_05.jpg'),
          meta: '农业植保'
        },
        {
          img: require('@/assets/home/sesp_application_06.jpg'),
          meta: '测绘应用'
        }
      ]
    };
  },
  methods: {
    downloadClick(url) {
      // saveAs(url, 'SESP-U1安装包');
      window.open(url);
    }
  }
};
</script>

<style scoped lang="scss">
.new-sesp-meta {
  .sesp-bound {
  }
  .sesp-meta-content {
    padding: 40px 0px 60px 0px;
    .sesp-meta-application,
    .sesp-meta-product,
    .sesp-meta-download {
      box-sizing: border-box;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #2c313b;
        line-height: 37px;
        margin-bottom: 20px;
      }
    }
    .sesp-meta-product {
      padding: 0 60px;
      .product-meta {
        text-indent: 2em;
        font-size: 18px;
        color: #6c7073;
        line-height: 36px;
        span {
          color: #007bff;
        }
      }
    }
    .sesp-meta-application {
      padding: 40px 40px 0 60px;
      .application-item {
        &-col {
          position: relative;
          margin-bottom: 20px;
          &:not(:nth-child(3n)) {
            margin-right: 20px;
          }
        }
        &-meta {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
          background: rgba(0, 0, 0, 0.32);
          height: 40px;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          padding-left: 20px;
          box-sizing: border-box;
        }
      }
    }
    .sesp-meta-download {
      padding: 40px 40px 0 60px;
      .file-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 26px;
          vertical-align: middle;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #2c313b;
          margin: 0;
          margin-left: 20px;
        }
      }
      .download-option {
        text-align: center;
        margin-top: 30px;
        button {
          width: 478px;
          height: 44px;
          background: #1890ff;
          border-radius: 4px;
          border: 1px solid #3bc1e5;
        }
      }
    }
  }
}
</style>
