import i18n from '@/plugins/i18n'
// 任务执行状态字典
export const taskStatusDictionary = [
  {
    value: 0,
    label: i18n.t('dict.PendingApproval'),//'待审批'
  },
  {
    value: 1,
    label: i18n.t('dict.Rejected'), //'已驳回'
  },
  {
    value: 2,
    label: i18n.t('dict.PendingExecution'), //'待执行'
  }
];

// 任务流程节点状态字典
export const taskNodeStatusDictionary = [
  {
    value: 0,
    label: i18n.t('dict.Launch'),//'发起'
  },
  {
    value: 1,
    label: i18n.t('dict.Approve'),//'审批'
  },
  {
    value: 2,
    label: i18n.t('dict.Execute'),//'执行'
  }
];
// 任务流程节点操作字典
export const taskNodeOperateDictionary = [
  {
    value: 0,
    label: i18n.t('dict.Pass'), //'通过'
  },
  {
    value: 1,
    label: i18n.t('dict.NoOperation'), //'未操作'
  },
  {
    value: 2,
    label: i18n.t('dict.Pass'), //'通过'
  },
  {
    value: 3,
    label: i18n.t('dict.Rejected'),//'驳回'
  }
];
// 执行状态
export const executionStatusDictionary = [
  {
    value: 0,
    label: i18n.t('dict.PendingExecution'), //'待执行'
  },
  {
    value: 1,
    label: i18n.t('dict.InExecution'), //'执行中'
  },
  {
    value: 2,
    label: i18n.t('dict.Completed'),//'已完成'
  }
];
// 月字典
export const getDateMonthList = () => {
  return new Array(31).fill(0).map((item, index) => {
    if (index === 31) {
      return {
        label: '最后一天',
        value: 'L'
      };
    }
    return {
      label: index + '号',
      value: index.toString()
    };
  });
};

// 机库状态字典
export const deviceStatusDictionary = [
  {
    value: 0,
    label: '空闲 '
  },
  {
    value: 1,
    label: '正在执行出仓待命 '
  },
  {
    value: 2,
    label: '正在执行回收入仓 '
  },
  {
    value: 3,
    label: '正在执行充电流程'
  },
  {
    value: 4,
    label: '正在结束充电流程'
  },
  {
    value: 5,
    label: '正在执行休眠流程'
  },
  {
    value: 6,
    label: '正在执行预热流程'
  },
  {
    value: 7,
    label: '正在执行初始化'
  },
  {
    value: 8,
    label: '未初始化'
  },
  {
    value: 9,
    label: '正在执行关舱流程 '
  },
  {
    value: 10,
    label: '正在执行回中器操作 '
  }
];

// 鹰巢任务类型字典
export const eagleTaskTypeDictionary = [
  {
    value: 0,
    label: 'IMMEDIATE'
  },
  {
    value: 1,
    label: 'FIX_TIME'
  },
  {
    value: 2,
    label: 'PERIOD'
  }
];
