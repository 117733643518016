import { log } from 'video.js';
import { url } from 'video.js';
import $ from 'jquery';
// import bg_img_01 from '~@/assets/headerIcon/titleLBG@svg.svg'
// import bg_img_02 from '~@/assets/headerIcon/titleRGB@svg.svg'

// 创建弹窗对象的方法
var Popup = function (info) {
  this.constructor(info);
};
Popup.prototype.id = 0;
Popup.prototype.constructor = function (info) {
  var _this = this;
  _this.viewer = info.viewer; // 弹窗创建的viewer
  _this.geometry = info.geometry; // 弹窗挂载的位置
  _this.data = info.data; // 点位数据
  _this.del_real = info.del_real; // 是否展示
  // _this.vueThis = info.arr;
  // if(info.hasOwnProperty('arr')) {
  //   _this.arr = info.arr
  // }
  console.log('数据', info);
  _this.id = 'popup_' + _this.__proto__.id++;
  _this.ctn = $("<div class='bx-popup-ctn' id='" + _this.id + "'>");
  $(_this.viewer.container).append(_this.ctn);

  console.log('_this.ctn2', _this.ctn);

  let contentHtml = '';

  /**
   * 单位名称
   */
  if (_this.data?.organizationName) {
    contentHtml += `<div class="li"><div class="label">单位：</div><div class="text" >${_this.data.organizationName}</div></div>`;
  }
  // if (_this.data?.account_name) {
  //   contentHtml += `<div class="li"><div class="label">飞手：</div><div class="text" style="width: 160px;">${_this.data.account_name}</div></div>`;
  // }
  if (_this.data?.phone_num) {
    contentHtml += `<div class="li" style="padding-left:10px"><div class="label">联系电话：</div><div class="text">${_this.data.phone_num}</div></div>`;
  }
  if (_this.data?.address) {
    contentHtml += `<div class="li" style="padding-left:10px"><div class="label">地址：</div><div class="text">${_this.data.address}</div></div>`;
  }
  if (_this.data?.lon) {
    contentHtml += `<div class="li" style="padding-left:10px"><div class="label">经度：</div><div class="text">${_this.data.lon}</div></div>`;
  }
  if (_this.data?.lat) {
    contentHtml += `<div class="li" style="padding-left:10px"><div class="label">纬度：</div><div class="text">${_this.data.lat}</div></div>`;
  }
  if (_this.data?.manager) {
    contentHtml += `<div class="li"><div class="label">负责人：</div><div class="text">${_this.data.manager}</div></div>`;
  }
  if (_this.data?.bs) {
    contentHtml += `<div class="li"><div class="label">快反时间：</div><div class="text">${_this.data.bs}</div></div>`;
  }
  if (_this.data?.unitIndexCode) {
    contentHtml += `<div class="li"><div class="hk-link" data-code="${_this.data.unitIndexCode}">视频查看</div></div>`;
  }
  if (_this.data.typeId == 1) {
    contentHtml += `<div class="li"><div class="label" >提示：</div><div class="text">此处为禁飞区</div></div>`;
  }
  if (_this.data.typeId == 2) {
    contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为电子围栏</div></div>`;
  }
  if (_this.data.typeId == 3) {
    contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为限高区</div></div>`;
  }

  if (_this.data?.height) {
    contentHtml += `<div class="li"><div class="label">限高距离：</div><div class="text">${_this.data.height}</div></div>`;
  }

  // 测试弹窗内容
  var testConfig = {
    header: _this.data.name || _this.data.carNo || _this.data.account_name || '',
    content: `<div>${contentHtml}</div>`
  };
  _this.ctn.append(_this.createHtml(testConfig.header, testConfig.content));
  $('#content-ctn2').hide();

  $('.cpt-cesium_layer .header-ctn1').on('click', function (e) {
    $(this).parent().attr('class', 'bx-popup-header-ctn titleLBG');
    $('#content-ctn1').show();
    $('#content-ctn2').hide();
    $('#header-ctn2').css('color', 'white');
    $('#header-ctn1').css('color', '#a4ff64');
  });

  $('.cpt-cesium_layer .header-ctn2').on('click', function (e) {
    $(this).parent().attr('class', 'bx-popup-header-ctn titleRBG');
    $('#content-ctn2').show();
    $('#content-ctn1').hide();
    $('#header-ctn2').css('color', '#a4ff64');
    $('#header-ctn1').css('color', 'white');
  });

  // 实时刷新
  _this.offsetWidth = _this.ctn.get(0).offsetWidth;
  _this.offsetHeight = _this.ctn.get(0).offsetHeight;

  _this.render(_this.geometry);
  // _this.eventListener = _this.viewer.clock.onTick.addEventListener(function (clock) {
  //   _this.render(_this.geometry);
  // })...

  _this.eventListener = () => {
    // 每一帧都去计算气泡的正确位置
    _this.render(_this.geometry);
  };
  _this.viewer.scene.postRender.addEventListener(_this.eventListener);

  // _this.ctn.on("click", ".leaflet-popup-close-button", function (e) {
  //   e.stopPropagation();
  //   _this.close();
  // });...
};
Popup.prototype.render = function (geometry) {
  var _this = this;
  var position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(_this.viewer.scene, geometry);
  _this.ctn.css('left', position.x - _this.offsetWidth / 2);
  _this.ctn.css('top', position.y - _this.offsetHeight - 10);
};
// 动态生成内容
Popup.prototype.createHtml = function (header, content) {
  var html =
    '<div class="bx-popup-header-ctn-nm" style="">' +
    header +
    '</div>' +
    '<div class="bx-popup-content-ctn" >' +
    '<div class="bx-popup-content" >' +
    content +
    '</div>' +
    '</div>' +
    '<div class="bx-popup-tip-container" >' +
    '<div class="bx-popup-tip" >' +
    '</div>' +
    '</div>';
  //  + '<div class="el-icon-close leaflet-popup-close-button"></div>';
  // 根据n要求t前端自己加假数据
  if (this.data?.carNo) {
    var header1 = this.data.carNo;
    var header2 = '健康状态';

    var content =
      `<div class="li" >
        <div class="label">单位：</div>
        <div class="text" >${this.data.organizationName}</div>
      </div > ` +
      `<div class="li" >
        <div class="label">经度：</div>
        <div class="text" >${this.data.lat}</div>
      </div > ` +
      `<div class="li" >
        <div class="label">纬度：</div>
        <div class="text" >${this.data.lon}</div>
      </div > `;
    var content_status =
      `<div style = "background:linear-gradient(to left,#1E2026,#77C0FF,#1E2026);height:1px;" ></div > ` +
      `<div class="li" >
        <div class="label iconfont icon-zijian_dianchixitong" style="font-size:12px;color:#159CF4"></div>
        <div class="text" style="color:#b3bbc5;">动力系统：</div >
        <div class="text" style="color:#7FFF42;">正常</div>
      </div > ` +
      `<div style = "background:linear-gradient(to left,#1E2026,#77C0FF,#1E2026);height:1px;" ></div > ` +
      `<div class="li" >
        <div class="label iconfont icon-zijian_hangdianxitong" style="font-size:12px;color:#159CF4"></div>
        <div class="text" style="color:#b3bbc5;">航电系统：</div >
        <div class="text" style="color:#7FFF42;">正常</div>
      </div > ` +
      `<div style = "background:linear-gradient(to left,#1E2026,#77C0FF,#1E2026);height:1px;" ></div > ` +
      `<div class="li" >
        <div class="label iconfont icon-zijian_dianchixitong" style="font-size:12px;color:#159CF4"></div>
        <div class="text" style="color:#b3bbc5;">电池系统：</div >
        <div class="text" style="color:#7FFF42;">正常</div>
      </div > ` +
      `<div style = "background:linear-gradient(to left,#1E2026,#77C0FF,#1E2026);height:1px;" ></div > ` +
      `<div class="li" >
        <div class="label iconfont icon-zijian_tuchuanxitong" style="font-size:12px;color:#159CF4"></div>
        <div class="text" style="color:#b3bbc5;">图传系统：</div >
        <div class="text" style="color:#7FFF42;">正常</div>
      </div > `;

    // var img = require(`${this.data.modelIMage}`);
    // var ctn = $(
    //   "<div class='bx-popup-ctn' style=' width: 230px;border: 1px solid #50ABFF;border-radius: 4px !important;'>"
    // );

    var html2 =
      '<div class="bx-popup-header-ctn titleLBG">' +
      '<div class="header-ctn1">' +
      header1 +
      '</div>' +
      '<div class="header-ctn2">' +
      header2 +
      '</div>' +
      '</div>' +
      '<div class="bx-popup-content-ctn" >' +
      '<div id="content-ctn1" >' +
      content +
      '</div>' +
      '<div id="content-ctn2" >' +
      '<div id="content-ctn2-left" >' +
      ' <img src=' +
      this.data.modelIMage +
      ' alt="Smiley face" width="112" height="84">' +
      this.data.modelName +
      '</div>' +
      '<div id="content-ctn2-right" >' +
      content_status +
      '</div>' +
      '</div>' +
      '</div>' +
      '<div class="bx-popup-tip-container" >' +
      '<div class="bx-popup-tip" >' +
      '</div>' +
      '</div>';

    return html2;
  }
  return html;
};
// 关闭弹窗按钮
Popup.prototype.close = function () {
  var _this = this;
  const { category, deviceHardId } = _this.data;
  _this.ctn.remove();
  if (category === 1) {
    _this.del_real(deviceHardId);
  }
  // _this.viewer.clock.onTick.removeEventListener(_this.eventListener);
  _this.viewer.scene.postRender.removeEventListener(_this.eventListener);
  // 防止点击关闭无法创建新的移动弹窗
  // if (this.data.hasOwnProperty("regionList")) {
  //   const index = _this.vueThis.move_id_arr.indexOf("_this.data.id");
  //   _this.vueThis.move_id_arr.splice(index, 1);
  // }

  // if (this.data.hasOwnProperty("entityType")) {
  //   const index = _this.vueThis.move_id_arr.indexOf("_this.data.id");
  //   _this.vueThis.zdys_id_arr.splice(index, 1);
  // }
};

export default Popup;
