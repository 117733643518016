<template>
  <div class="select-tack">
    <div v-interact class="header">
      <span class="title">选择任务</span>
      <span class="iconfont icon-guanbi" @click="$emit('fn')" />
    </div>
    <div class="body">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="选择任务" required>
          <el-select v-model="form.selectTask" placeholder="请选择任务名称">
            <!-- filterable -->
            <el-option
              v-for="item in selectTask_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="form_btn">
      <div class="btn_cancel" @click="change_active(1)">{{$t('operation.Cancel')}}</div>
      <div class="btn_send">发送任务至无人机</div>
    </div>
  </div>
</template>

<script>
import { flightTaskAPI } from '@/api';

export default {
  inject: ['change_active', 'flight_info'],
  name: 'SelectTack',

  data() {
    return {
      form: {
        selectTask: ''
      },
      selectTask_options: []
    };
  },

  mounted() {
    // this.getFlightTaskList()
  },

  methods: {
    getFlightTaskList() {
      const param = {
        num: 1,
        size: 9999
      };
      flightTaskAPI.getFlightList({ ...param }).then((resp) => {
        this.selectTask_options = resp.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.select-tack {
  width: 323px;
  height: 170px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  position: absolute;
  z-index: 88;
  bottom: 300px;
  right: 400px;
  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }
    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .body {
    height: calc(100% - 85px);
    width: 317px;
    box-sizing: border-box;
    border-bottom: 1px solid #494949;
    border-top: 1px solid #494949;
    .el-form {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      letter-spacing: 0;
      &::v-deep {
        .el-form-item__label {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #b3bbc5;
        }
        .select-tack {
          padding-top: 20px;
          right: 400px;
        }
        .el-input {
          width: 227px;
          height: 32px;
          .el-input__inner {
            height: 100%;
            width: 100%;
            background: #16181b;
            border-radius: 8px;
            border: none;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #c6ccd4;
            letter-spacing: 0;
            font-weight: 400;
          }
          .el-input__icon {
            line-height: 32px;
            color: #1eb9fa;
          }
        }
      }
    }
  }
  .form_btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    position: absolute;
    bottom: 10px;
    width: 317px;
    .btn_cancel,
    .btn_send {
      box-sizing: border-box;
      height: 32px;
      padding: 0 20px 0 20px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    .btn_cancel {
      padding: 0 30px 0 30px;
    }
  }
}
</style>
