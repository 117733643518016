<template>
  <div class="paginationBox">
    <div class="title">共计{{ pagination.total }}条 ，已选中{{ reportData.length }}条</div>
    <div class="mt5 jcsb content">
      <div class="btn" @click="handleCurrentChange(1)">K</div>
      <div class="btn" @click="handleCurrentChange(4)">
        <img class="mt4" src="../../img/1.svg" alt="" />
      </div>
      <div class="w50 tc f14" style="color: #b3bbc5">
        {{ pagination.currentPage }} /{{ pagination.totalPage }}
      </div>
      <div class="btn" @click="handleCurrentChange(5)">
        <img class="mt4" src="../../img/2.svg" alt="" />
      </div>
      <div class="btn" @click="handleCurrentChange(2)">
        <div style="transform: rotate(180deg)">K</div>
      </div>
      <input v-model="search" class="input_text" type="text" @change="sanitizeInput" />
      <div class="btn" @click="handleCurrentChange(3)">GO</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => {
        return { currentPage: 1, totalPage: 2, total: 12 };
      }
    },
    reportData: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      search: 1
    };
  },
  computed: {},
  methods: {
    sanitizeInput() {
      const { totalPage } = this.pagination;
      // 移除非数字字符
      let num = this.search.replace(/\D/g, '');
      if (totalPage) {
        // 将输入限制在 1 到 10 之间
        if (num < 1) {
          num = 1;
        } else if (num > totalPage) {
          console.log(num, 'mim');
          num = totalPage;
        }
        console.log(num, 'inout');
      }

      this.search = num;
    },
    handleCurrentChange(type) {
      let num = 1;
      const { currentPage, totalPage } = this.pagination;

      if (type === 1) {
        num = 1;
      } else if (type === 2 || type === 3) {
        num = type === 2 ? totalPage : this.search;
      } else if (type === 4) {
        num = currentPage > 1 ? currentPage - 1 : 1;
      } else if (type === 5) {
        num = currentPage < totalPage ? currentPage + 1 : totalPage;
      }
      console.log(num, 'num');
      this.$emit('handleCurrentChange', num);
    }
  }
};
</script>
<style lang="scss">
.paginationBox {
  width: 100%;
  height: 50px;
  background-color: #1e2026;

  &::v-deep {
    .el-input__inner {
      background: #16181b;
      width: 32px;
      color: #fff;
      padding: 0;
      border-radius: 0px;
      text-align: center;
    }
  }

  .title {
    text-align: center;
    font-size: 10px;
    font-family: MicrosoftYaHei;
    color: #91959c;
    line-height: 14px;
    margin-top: 5px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
  }

  .btn {
    text-align: center;
    width: 35px;
    color: #9da5ae;
    font-size: 12px;
    overflow: hidden;
    height: 24px;
    cursor: pointer;
    line-height: 24px;
    background: #292c35;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000,
      inset 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
  }

  .input_text {
    width: 32px;
    background: #16181b;
    color: #fff;
    border: 0;
    text-align: center;
  }
}

input[type='text']:focus {
  border: 1px solid #16181b !important;
}
</style>
