<template>
  <div class="box" id="box_nav_bubble">
    <div id="box_nav" v-if="imgList.length>0" > 
      <div :class="`img${index+1} pa imgbox`" :key="index" v-for="(item,index) in imgList" @click='handClick(item)'>
        <el-badge :is-dot="item.title=='水位预警'" class='img'> <img class="w25 h25 dib bor50" :src="item.img" alt=""></el-badge>
      </div>
  </div>
  <!-- <div class=' cire'>1</div> -->
  <EntitiesModel  :EntitiesStyle='EntitiesStyle' @close='entitiesModelFlag=false' v-if='entitiesModelFlag' :title='title' :entitiesModelData='entitiesModelData'></EntitiesModel>
    </div>
  </div>
  </div>
</template>

<script>
import store from '@/store';
import EntitiesModel from "../../../com/entitiesModel";
export default {
  components:{EntitiesModel},
  name: 'DynamicLabel',
  props: {
    data: {
      type: Object,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      EntitiesStyle:null,
      imgList:[],
      entitiesModelData:[],
      videoPostion: true,
      show: true,
      title:"title",
      entitiesModelFlag:false
    };
  },
  mounted () {
    let {streamUrl,panoramaUrl,sources,waterType} =this.data
    let data=[]
    if(streamUrl){
      data.push({
        img:require('./img/1.png'),
        title:"监控视频"
      })
    }
 
    if(panoramaUrl){
      data.push({
        img:require('./img/2.png'),
        title:"全景图"
      })
    }
    if(sources.length>0){
      let sourceImg= []
      let sourceVideo= []
      for (let i = 0; i < sources.length; i++) {
        const item = sources[i];
        if(item.sourceType==1){
          sourceImg.push(item)
        }
        if(item.sourceType==2){
          sourceVideo.push(item)
        }
      }
      if(sourceImg.length>0){
        data.push({
        img:require('./img/6.png'),
        title:"图片"
      })
      }
      if(sourceVideo.length>0){
        data.push({
        img:require('./img/3.png'),
        title:"视频"
      })
      }
    }
    if(waterType){
      data.push({
        img:require('./img/4.png'),
        title:"水位预警"
      })
    }
setTimeout(() => {
  this.imgList=data
}, 200);
  },
  destroyed() {
    window.this = null;
  },
  methods: {
 getClosestSideOfElement() {
  // 获取 DOM 元素
  var element = document.getElementById("box_nav_bubble");

  // 获取元素相对于文档左上角的坐标
  var elementRect = element.getBoundingClientRect();
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  var elementX = elementRect.left + scrollLeft;
  var elementY = elementRect.top + scrollTop;

  // 获取屏幕可视区域的尺寸
  var screenWidth = window.innerWidth || document.documentElement.clientWidth;
  var screenHeight = window.innerHeight || document.documentElement.clientHeight;

  // 计算元素离屏幕四个边界的距离
  var distanceTop = elementY;
  var distanceBottom = screenHeight - (elementY + element.offsetHeight);
  var distanceLeft = elementX;
  var distanceRight = screenWidth - (elementX + element.offsetWidth);

  // 确定最近的边界方向
  var closestSide;
  var farthestSide;
  console.log(distanceTop,'distanceTop');
  console.log(distanceBottom,'distanceBottom');
  if (distanceTop > distanceBottom) {
    closestSide = '-390px';
  } else {
    closestSide = '-66px';
  }

  if (distanceLeft <= distanceRight) {
    farthestSide = '216px';
  } else {
    farthestSide = '-621px';
  }
  console.log(closestSide,'closestSide');
  // 构造结果对象
  var result = {
    top: closestSide,
    left: farthestSide
  };

  // 返回结果
  return result;
},
    handClick(item){
      // 关闭 编辑弹出框/添加弹出框
     store.commit('uavApplications/setState', {
          key: 'dimensionReset',
          value: parseFloat(Date.now().toString() + Math.random().toString())
        });
      // this.entitiesModelFlag= false
            if(item.title=="监控视频"){
              const {streamUrlType,streamUrl}=this.data
           let streamUrlList = null;
            if (streamUrlType == "webrtc") {
              streamUrlList = [
                {
                  label: "WebRtc",
                  brand: "SRS",
                  url: streamUrl,
                },
              ];
            } else {
              streamUrlList = [
                {
                  label:streamUrlType,
                  brand: streamUrlType,
                  url: streamUrl,
                },
              ];
            }
            this.entitiesModelData={
              ...this.data,
              streamUrlList
            }
            }else{
              this.entitiesModelData=this.data
            }
      this.title = item.title
      let style = this.getClosestSideOfElement()
     this.EntitiesStyle=style
     setTimeout(() => {
      this.entitiesModelFlag=true
     }, 10);
    },
    getLeftTop(iR,iDeg){
        var ileft=Math.round(Math.sin(iDeg/180*Math.PI)*iR);
        var iTop=Math.round(Math.cos(iDeg/180*Math.PI)*iR);
        //返回两个值的时候，用对象的方式。
        return{
            "l":ileft,
            "t":iTop
            }
        },
        closeClick() {
          this.entitiesModelFlag=false
          const dom = document.getElementById("box_nav");
          if(dom){
            const list =dom.getElementsByClassName('imgbox')
          const len = list.length
          for (let i = 0; i < len; i++) {
            const item = list[i];
            item.classList.remove(`img${i+1}`);
            item.classList.add(`close${i+1}`)
          }
          }
     setTimeout(() => {
       if (this.closeEvent) {
        this.closeEvent();
      }
     }, 500);
    },
    },


};
</script>

<style lang="scss" scoped>
.bfe{
  color:#42bfe5
}
.box {
  width: 200px;
  position: absolute;
  left: 0;
  #stage {
    width: 300px;
    height: 300px;
    position: relative;
    top: 150px;
    margin: 0 auto;
}
#box_nav {
    position: absolute;
    width: 25px;
    height: 25%;
    left: 50%;
    margin-left: -60px;
    top: 50%;
    margin-top: -53px;
    
}
.cire{
  top: -84px;
    width: 166px;
    left: 15px;
    text-align: center;
    height: 166px;
    border-radius: 50%;
    position: absolute;
    background: #eef3fd94;
    z-index:1;
    line-height: 166px;
    }
#box_nav .imgbox {
  width: 25px;
  height: 25px;
  left: 41px;
    top: 33px;
    text-align: center;
    line-height: 25px;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    background: #2c313b;
    padding: 5px;
}
@keyframes moveElement {
  from {
    left: 41px;
    top: 33px;
    opacity: 0;
    }
    to {
      left: -25px;
    top: 37px;
    opacity: 1;
    }
}
@keyframes moveElement2 {
  from {
    left: 41px;
    top: 33px;
    opacity: 0;
    }
    to {
      
      left: -11px;
    top: -6px;
    opacity: 1;

    }
}
@keyframes moveElement3 {
  from {
    left: 41px;
    top: 33px;
    opacity: 0;
    }
    to {
      
      left: 41px;
    top: -30px;
    opacity: 1;

    }
}
@keyframes moveElement4 {
  from {
    left: 41px;
    top: 33px;
    opacity: 0;
    }
    to {
      
      left: 89px;
    top: -9px;
    opacity: 1;
    }
}
@keyframes moveElement5 {
  from {
    left: 41px;
    top: 33px;
    opacity: 0;
    }
    to {
      left: 106px;
    top: 37px;
    opacity: 1;
    }
}
#box_nav .img1 {
    animation: moveElement 0.5s ease forwards;
}
#box_nav .img2 {
    animation: moveElement2 0.5s ease forwards;
}
#box_nav .img3 {
    animation: moveElement3 0.5s ease forwards;
}
#box_nav .img4 {
    animation: moveElement4 0.5s ease forwards;
}
#box_nav .img5{
    animation: moveElement5 0.5s ease forwards;
}

#box_nav .close1 {
    animation: newMoveElement 0.5s ease forwards;
}
#box_nav .close2 {
    animation: newMoveElement2 0.5s ease forwards;
}
#box_nav .close3 {
    animation: newMoveElement3 0.5s ease forwards;
}
#box_nav .close4 {
    animation: newMoveElement4 0.5s ease forwards;
}
#box_nav .close5{
    animation: newMoveElement5 0.5s ease forwards;
}
@keyframes newMoveElement {
  from {
    
      left: -11px;
    top: -6px;
    opacity: 1;
    }
    to {
      left: 41px;
    top: 33px;
    opacity: 0;
    }
}
@keyframes newMoveElement2 {
  from {
    
      left: -11px;
    top: -6px;
    opacity: 1;

    }
    to {
      left: 41px;
    top: 33px;
    opacity: 0;
    }
}
@keyframes newMoveElement3 {
  from {
    
      left: 41px;
    top: -30px;
    opacity: 1;

    }
    to {
      left: 41px;
    top: 33px;
    opacity: 0;
    }
}
@keyframes newMoveElement4 {
  from {
    opacity: 1;
    
      left: 89px;
    top: -9px;
    }
    to {
      left: 41px;
    top: 33px;
    opacity: 0;
    }
}
@keyframes newMoveElement5 {
  from {
    opacity: 1;
      left: 106px;
    top: 37px;
    }
    to {
      left: 41px;
    top: 33px;
    opacity: 0;
    }
}

}
</style>
