function tree(con, list = []) {
  con.forEach((item) => {
    if (item.id) {
      list.push(item.id);
    }
    if (item.children && item.children.length > 0) {
      tree(item.children, list);
    }
    if (item.btns && item.btns.length > 0) {
      tree(item.btns, list);
    }
  });
  return list;
}
// 获取权限 id数组
export function permissionIds() {
  const str = localStorage.getItem('permission');
  return tree(JSON.parse(str));
}
