var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"silderBar",style:('width:' + _vm.width)},[(_vm.loginState && _vm.active !== -1)?_c('div',{staticClass:"zhedie1",on:{"click":_vm.changewidth}},[_c('i',{staticClass:"iconfont icon-zhedie1",class:{ 'icon-zhedie': _vm.isShow, 'icon-zhedie1': !_vm.isShow },attrs:{"id":"change_icon"}})]):_vm._e(),_c('div',{staticClass:"silderBar_top",style:('width:' + _vm.width)}),_c('div',{ref:"navRef",staticClass:"silderBar_nav"},[(_vm.loginState)?_c('div',{staticClass:"silderBar_box_top"},[_c('div',{staticClass:"up-arrow-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.scrollTop && _vm.appShowCount < _vm.appProjectList.length),expression:"scrollTop && appShowCount < appProjectList.length"}],staticClass:"up-arrow",on:{"click":_vm.upArrowClick}},[_c('div',{staticClass:"up-triangle"})])]),_c('div',{ref:"appRef",staticClass:"application-list-wrap",style:({ height: _vm.appListHeight + 'px' })},_vm._l((_vm.appProjectList),function(item,index){return _c('div',{key:item.id},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.name,"placement":"right"}},[_c('div',{class:{
                  item: true,
                  item_icon: true,
                  'item-active': _vm.currentAppIndex === index
                },on:{"click":function($event){return _vm.appSelectEvent(index)}}},[_c('img',{attrs:{"src":item.icon}})])])],1)}),0),_c('div',{staticClass:"down-arrow-wrap"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.scrollTop != (_vm.appProjectList.length - _vm.appShowCount) * _vm.appItemHeight &&
              _vm.appShowCount < _vm.appProjectList.length
            ),expression:"\n              scrollTop != (appProjectList.length - appShowCount) * appItemHeight &&\n              appShowCount < appProjectList.length\n            "}],staticClass:"down-arrow",on:{"click":_vm.downArrowClick}},[_c('div',{staticClass:"down-triangle"})])])]):_vm._e(),(_vm.loginState)?_c('div',{ref:"sliderBar_bottom",staticClass:"sliderBar_bottom"},_vm._l((_vm.silderBar_bottom),function(item,index){return _c('div',{directives:[{name:"pms",rawName:"v-pms",value:([item.name]),expression:"[item.name]"}],key:index,staticClass:"item item_icon_top",class:{
            active: _vm.openLeftNavSilder && _vm.currentOptionIndex === index
          },on:{"click":function($event){return _vm.goMeg(index + 1)}}},[_c('i',{class:item.icon,style:({ fontSize: item.fontSize })}),_c('div',{staticClass:"item_hover_bottom"},[_vm._v(_vm._s(item.name))])])}),0):_vm._e()]),(_vm.isShow)?_c('FlightPopup2'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }