<script>
import MessageModal from '@/components/common/message-modal/index.vue';
import { flightTaskAPI } from '@/api';

export default {
  name: 'Index',
  components: {
    MessageModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      applyForm: {
        approveStatus: 2,
        operateDescribe: undefined
      },
      rules: {
        operateDescribe: [{ required: true, message: this.$t('taskMangement.请输入驳回原因'), trigger: 'blur' }]
      }
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    // 审批确认
    submitApply() {
      this.$refs.applyFormRef.validate((valid) => {
        if (valid) {
          flightTaskAPI
            .approveTask({
              ...this.applyForm,
              taskId: this.taskId
            })
            .then(({ code }) => {
              if (code === 200) {
                this.$message.success(this.$t('taskMangement.审批成功'));
                this.$emit('applySuccess');
              }
            });
        }
      });
    }
  }
};
</script>

<template>
  <div class="apply-modal">
    <message-modal :show="show" :title="$t('taskMangement.任务审批')" :width="320" @close="close">
      <div class="apply-form">
        <el-form
          ref="applyFormRef"
          :model="applyForm"
          label-width="80px"
          label-position="left"
          :rules="rules"
        >
          <el-form-item>
            <el-radio-group v-model="applyForm.approveStatus">
              <el-radio :label="2">{{$t('dict.Pass')}}</el-radio>
              <el-radio :label="3">{{$t('dict.NoPass')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="applyForm.approveStatus === 3"
            :label="$t('taskMangement.驳回原因')"
            required
            prop="operateDescribe"
          >
            <el-input
              v-model="applyForm.operateDescribe"
              type="textarea"
              :rows="2"
              :placeholder="$t('taskMangement.请输入驳回原因')"
              :maxlength="64"
            />
          </el-form-item>
        </el-form>
        <div class="apply-form-submit">
          <el-button @click="submitApply">{{$t('operation.Confirm')}}</el-button>
        </div>
      </div>
    </message-modal>
  </div>
</template>

<style scoped lang="scss">
.apply-modal {
  position: relative;
  .apply-form {
    padding: 12px;
    background-color: #0a0b0d;
    &-submit {
      text-align: center;
      margin-top: 20px;
      .el-button {
        background-color: #1890ff;
      }
    }
  }
}
</style>
