<template>
  <!-- 飞行日志弹框 -->
  <!-- <Dialog
    @on-close="closeIDsPopup"
    title="飞行日志"
    :width="1540"
    style="top: 1vh; height: 900px"
    :left="100"
  > -->
  <div class="box">
    <div v-interact class="title">
      <div>飞行日志</div>
      <span class="iconfont icon-guanbi1 close" @click="closeIDsPopup" />
    </div>
    <div class="content hwb-flex ofh">
      <!-- 左侧飞机图片 -->
      <!-- <div class="aircraft-pictures" :class="modelFlag ? '' : 'w200'"> -->
      <!-- 无人机下拉选择 -->
      <!-- <el-select
            v-model="deviceHardId"
            style="width: 100%"
            placeholder="请选择无人机"
            clearable
          >
            <el-option
              v-for="item in uavs"
              :key="item.deviceHardId"
              :label="item.deviceName"
              :value="item.deviceHardId"
            >
            </el-option>
          </el-select> -->
      <!-- 无人机列表 -->
      <!-- <div class="uav-list">
            <div
              class="one-uav"
              @click="changeList(item.deviceHardId, index, item)"
              :class="itemIndex == index ? 'active' : 'default'"
              v-for="(item, index) in uavList"
              :key="item.deviceHardId"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.deviceName"
                placement="top"
              >
                <div class="text">{{ item.deviceName }}</div>
              </el-tooltip>
              <img class="img" :src="baseUrl + item.image" alt="" />
            </div>
          </div> -->
      <!-- 中间的竖线装饰 -->
      <!-- <div class="dec"></div> -->
      <!-- </div> -->
      <!-- 统计模式 -->
      <!-- <Statistical
          v-show="modelFlag"
          @changeModel="changeModelFn"
          :deviceHardId="deviceHardId"
          ref="StatisticalRef"
        >
        </Statistical> -->
      <div v-if="!modelFlag" class="w1200 h700 pa">
        <FilghtMap
          :filght-map-data="FilghtMapData"
          :device-hard-id="deviceHardId"
          @changeModel="changeModelFn"
        />
      </div>
    </div>
  </div>
  <!-- </Dialog> -->
</template>

<script>
import Dialog from '@/components/common/new-dialog/index-tow.vue'; // 弹框组件

import mqtt from 'mqtt';
import { mapGetters } from 'vuex';
import * as turf from '@turf/turf';
import dayjs from 'dayjs';
import { transformCartesian2WGS84 } from '@/utils/cesium';
const { VUE_APP_MTQQ, VUE_APP_WS_IP } = process.env;
// import Statistical from "./components/statistical/index.vue"; // 分页组件
import FilghtMap from './components/map/index.vue';
import { AirLine } from '@/api';
export default {
  components: {
    Dialog,
    // Statistical,
    FilghtMap
  },
  props: {
    recordData: {
      typeof: Object,
      default: () => {}
    }
  },
  data() {
    return {
      FilghtMapData: {}, // 数据
      itemIndex: 0, // 选中无人机
      baseUrl: VUE_APP_WS_IP,
      modelFlag: false, // true是统计模式
      deviceHardId: '', // 选择无人机存储
      uavs: [], // 无人机下拉数据
      uavList: [], // 无人机列表数据
      dataSource: [], // 任务列表数据
      id: ''
    };
  },
  computed: {
    ...mapGetters(['user_info_hn']),
    ...mapGetters(['dbox_data'])
  },
  watch: {
    patternDate(val) {
      this.getdataSource();
    },
    deviceHardId(val) {
      if (val) {
        const data = this.uavs.findIndex((item) => {
          return val === item.deviceHardId;
        });
        this.itemIndex = data;
        this.getdataSource(val);
      }
      //  else {
      //   this.uavList = [...(this.uavs)]
      // }
    }
  },
  async created() {
    await this.getFilghtMapData();
  },
  mounted() {
    console.log(this.recordData, 'akshdakjshdkjasbdnjk');
    // this.getList()
    // this.getdataSource()
  },
  methods: {
    changeModelFn(row) {
      if (row) {
        this.FilghtMapData = row;
        this.id = row.deviceHardId;
      }
      this.modelFlag = false;
    },

    changeList(deviceHardId, index, item) {
      this.itemIndex = index;
      this.getdataSource(deviceHardId);
    },
    getdataSource(deviceHardId) {
      // this.modelFlag = true
      // this.deviceHardId = deviceHardId || this.uavList[0].deviceHardId
      // if (this.modelFlag && this.$refs.StatisticalRef) {
      //   this.$refs.StatisticalRef.pagination = {
      //     page: 1,
      //     pageSize: 5,
      //     total: 0,
      //   }
      //   this.$refs.StatisticalRef.taskTitle = null
      //   this.$refs.StatisticalRef.patternDate = null
      //   this.$refs.StatisticalRef.getdataSource(deviceHardId ? deviceHardId : this.uavList[0].deviceHardId)
      // }
    },
    async getList() {
      // 无人机列表获取
      // const res = await API.DEVICE.deviceList({
      //   pageSize: 1000,
      //   pageNum: 1,
      //   outGoodsId: 6
      // });
      // if (res.status == 1) {
      //   this.modelFlag = 1
      //   this.uavs = res.data.data;
      //   this.uavList = [...(res.data.data)];
      //   this.deviceHardId = this.uavList[0].deviceHardId
      // }
    },
    closeIDsPopup() {
      // 关闭弹框
      this.$emit('closeDemand');
      // if (this.modelFlag) {
      //   this.$emit("closeDemand");
      // } else {
      //   this.modelFlag = true
      //   this.deviceHardId = this.id
      // }
    },

    taskTitleBlur() {},
    async getFilghtMapData() {
      // const res = await AirLine.getSortiesTrajectory({
      //   flightSortie: this.recordData.flightSortieName
      // })
      // console.log(res);
      // this.FilghtMapData = { ...res.data, taskTitle: this.recordData.taskName }
      this.FilghtMapData = this.recordData;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input__icon {
    line-height: 26px;
  }

  .el-input__inner {
    background-color: #000;
    border: 0;
    height: 30px;
    line-height: 30px;
    color: #fff;
  }

  .el-range-separator {
    line-height: 24px;
    color: #fff;
  }

  .el-input__inner {
    background-color: #000;
    border: 0;
    height: 30px;
    line-height: 30px;
    color: #fff;
  }

  .el-range-separator {
    line-height: 26px;
    color: #fff;
  }
}
.box {
  position: fixed;
  top: 100px;
  left: 400px;
  //z-index: 3000;
  width: 1540px;
  height: 750px;
  border: 1px solid #73a6d1;
  background-color: #2c313b;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 30px;
    color: #73a6d1;
    .close {
      cursor: pointer;
      font-size: 24px;
    }
  }
}

.content {
  height: 710px;
  /* 左侧 */
  .aircraft-pictures {
    width: 188px;
    height: 710px;
    position: relative;

    .dec {
      position: absolute;
      top: -28px;
      height: 767px;
      left: 200px;
      width: 0px;
      border-right: 1px solid #73a6d1;
      box-shadow: 0px 0px 5px #73a6d1;
    }

    .uav-list::-webkit-scrollbar {
      width: 0px;
      /*滚动条宽度*/
      height: 8px;
      /*滚动条高度*/
    }

    .uav-list {
      width: 100%;
      height: 95%;
      margin-top: 16px;
      overflow-y: auto;

      .active {
        border: 1px solid red;
      }

      .default {
        border: 1px solid transparent;
      }

      .one-uav {
        width: 185px;
        height: 120px;
        position: relative;
        margin-bottom: 16px;
        cursor: pointer;

        .img {
          display: inline-block;
          width: 178px;
          height: 80px;

          // margin-left: 72px;
          margin-top: 39px;
        }

        .text {
          position: absolute;
          width: 100%;
          // height: 40px;
          height: 32px;
          background: #000000;
          opacity: 0.28;
          text-align: center;
          line-height: 32px;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          white-space: nowrap; //禁止换行
          overflow: hidden; //超出隐藏
          text-overflow: ellipsis; //显示省略号
        }
      }
    }
  }
}
</style>
