<template>
  <el-dialog
    :title="title"
    class="hwb-dialog"
    :top="'0px'"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :modal="false"
    :style="'left:' + left + 'px' + ';top:' + top + 'px'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :width="width + 'px'"
  >
    <div v-show="closeFlag" class="guanbi" @click="close">X</div>
    <img class="jiantou" src="" />
    <slot   />
  </el-dialog>
</template>

<script>
export default {
  props: {
    width: {
      // 控制宽度
      type: Number,
      default: 400
    },
    title: {
      // 标题
      type: String,
      default: '请传入一个标题放在这里'
    },
    top: {
      type: Number,
      default: 200
    },
    left: {
      type: Number,
      default: 200
    },
    create: {
      type: Boolean,
      default: false
    },
    closeFlag: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    close() {
      this.$emit('on-close');
    }
  }
};
</script>

<style lang="scss" scoped>
.hwb-dialog {
  right: unset;
  bottom: unset;
  color: #fff;

  ::v-deep {
    .el-dialog {
      border: 0px !important;
      background: transparent;
      position: relative;
      margin: 0;

      .el-dialog__header {
        width: 100%;
        height: 34px;
        padding: 0;
        box-sizing: border-box;
        background: rgba(21, 95, 192, 0.3);
        box-shadow: inset 0px 0px 10px 0px #00142f;
        border: 1px solid #73a6d1;
        backdrop-filter: blur(2px);

        .el-dialog__title {
          height: 32px;
          box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);
          border-radius: 4px 4px 0px 0px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ebf5ff;
          line-height: 31px;
          margin-left: 10px;
        }

        .el-dialog__headerbtn {
          display: none;
        }
      }

      .guanbi {
        position: absolute;
        top: -30px;
        right: 16px;
        font-size: 19px;
        font-family: YouSheBiaoTiHei;
        color: #f4f7fb;
        cursor: pointer;
      }

      .jiantou {
        position: absolute;
        top: -27px;
        left: 8px;
        font-size: 22px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
      }

      .chuangjian {
        position: absolute;
        top: -30px;
        right: 100px;
        width: 86px;
        height: 26px;
        background: rgba(38, 71, 238, 0.71);
        box-shadow: inset 0px 1px 3px 0px #ffffff, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
        border-radius: 2px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        line-height: 26px;
        text-align: center;
        cursor: pointer;
      }

      .el-dialog__body {
        background: #0a0b0d;
        border: 1px solid #73a6d1;
        box-shadow: 0px 0px 5px #73a6d1;
        border-top: 0;
        backdrop-filter: blur(2px);
      }
    }
  }
}
</style>
