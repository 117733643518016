<template>
  <div class="wih100 flightDataBox pr">
    <Pitchyaw :fly-data="flyData" class="pitchyawStyle pa bottom164" />
    <div class="flightDataStyle" />
    <div class="pa wih100 jcsb w978 bottom210 cf pl10 pr10">
      <div class="jcsb w370 tc">
        <div>
          <div>
            <span class="title">{{ isNANfn(flyData.height) }}</span
            >/ m
          </div>
          飞行高度
        </div>
        <div>
          <div>
            <span class="title">{{ isNANfn(flyData.flightDistance) }}</span> / m
          </div>
          飞行距离
        </div>
        <div>
          <div>
            <span class="title">{{ isNANfn(flyData.flightTime) }}</span
            >/min
          </div>
          飞行时间
        </div>
      </div>
      <div class="w370 tl">
        <div class="dib tc">
          <div>
            <span class="title">{{ isNANfn(flyData.groundSpeed) }}</span> m/s
          </div>
          飞行速度
        </div>
        <div class="dib ml50 mr40 tc">
          <div>
            <span class="title">{{ isNANfn(flyData.climbRate) }}</span> m/s
          </div>
          爬升率
        </div>
        <div>
          <Battery :fly-data="flyData" class="pa ml10 right20" style="top: -30px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pitchyaw from '../../ball/index.vue';
import Battery from '../../battery/index.vue';

export default {
  components: { Pitchyaw, Battery },
  props: {
    flyTimeData: {
      type: Object,
      default: () => 'middle'
    }
  },
  data() {
    return {};
  },
  computed: {
    flyData() {
      const { flyTimeData } = this;
      console.log(flyTimeData);
      const data = {
        ...flyTimeData,
        height: Number(flyTimeData.height)?.toFixed(2) || 0,
        flightTime: Number(flyTimeData.flightTime / 60)?.toFixed(2) || 0,
        groundSpeed: Number(flyTimeData.groundSpeed)?.toFixed(2) || 0,
        climbRate: Number(flyTimeData.climbRate)?.toFixed(2) || 0,
        distanceToHome: Number(flyTimeData.distanceToHome)?.toFixed(2) || 0,
        flightDistance: Number(flyTimeData.flightDistance)?.toFixed(2) || 0,
        roll: flyTimeData.roll || 0,
        yaw: flyTimeData.yaw || 0,
        pitch: flyTimeData.pitch || 0
      };

      // battaryRemain
      // climbRate
      // dateTime
      console.log(data, 'data');
      return data;
    }
  },
  methods: {
    isNANfn(data) {
      if (data === data && data !== 'NaN') {
        return data || 0;
      } else {
        return 0;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.flightDataBox {
  transform: translateX(calc(50% - 489px));

  .pitchyawStyle {
    margin-left: 21vw;
  }

  .flightDataStyle {
    position: absolute;
    bottom: 200px;
    width: 978px;
    height: 78px;
    opacity: 0.37;
    background: #eef6f8;
    border-radius: 4px;
    backdrop-filter: blur(4px);
  }

  .title {
    font-size: 22px;
    font-family: D-DIN-Bold, D-DIN;
    font-weight: bold;
    color: #ffffff;
    line-height: 30px;
  }
}
</style>
