<template>
  <div>
    <el-upload
      class="upload-video"
      action="#"
      :before-upload="beforeVideoUpload"
      accept=".mp4"
      :file-list="fileList"
      :on-change="changePic"
      :auto-upload="false"
      :on-remove="handleRemove"
      multiple
      :on-exceed="handleExceed"
      :limit="3"
    >
      <div class="videoBox" title=" 只能上传mp4格式的视频文件，大小不超过500MB">
        <i slot="trigger" class="el-icon-plus"></i>
      </div>
      <div slot="file" slot-scope="{ file }" class="wih100 ht100 fileList">
        <div class="title cp cf" :title="file.name" @dblclick="copyValue(file.name)">
          <i class="el-icon-s-order" style="color: #1fff1f"></i>
          {{ file.name }}
        </div>
        <div class="cf mr4 ml4 cp" @click="handleRemove(file)">
          <i class="el-icon-circle-close"></i>
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      fileList: []
    };
  },
  methods: {
    copyValue(value) {
      const oInput = document.createElement('input'); // 创建一个input标签
      oInput.setAttribute('value', value); // 设置改input的value值
      document.body.appendChild(oInput); // 将input添加到body
      oInput.select(); // 获取input的文本内容
      document.execCommand('copy'); // 执行copy指令
      document.body.removeChild(oInput); // 删除input标签
      this.$message({ message: '复制成功', type: 'success' });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    handleExceed() {
      this.$message.warning(`当前限制选择 3 个文件`);
    },
    beforeVideoUpload(file) {
      const isMp4 = file.type === 'video/mp4';
      const isLt100M = file.size / 1024 / 1024 < 500;

      if (!isMp4) {
        this.$message.error('上传视频只能是MP4格式!');
      }
      if (!isLt100M) {
        this.$message.error('上传视频大小不能超过500MB!');
      }

      return isMp4 && isLt100M;
    },
    changePic(file, fileList) {
      this.fileList = fileList;
    }
  }
};
</script>

<style scoped lang="scss">
.upload-video {
  margin-top: 4px;
}
.videoBox {
  background: #4faafd;
  width: 20px;
  height: 20px;
  font-size: 9px;
  line-height: 20px;
}
.fileList {
  display: flex;
  .title {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.upload-tip {
  margin-top: 10px;
  color: #999;
}
::v-deep {
  .el-icon-plus:before {
    color: #fff;
  }
  .el-icon-close {
    top: 14px;
  }
  .el-upload-list__item:hover {
    background-color: #1e1f22;
  }
}
</style>
