const Cesium = window.Cesium;

export function createPoint(
  viewer,
  { position, image, title, id, longitude, markInfo, width, height, scale }
) {
  let markType = false
  if (markInfo && markInfo.markType == "POINT" && markInfo.waterType) {
    markType = true
  }
  const point = viewer.entities.add({
    id: id || null,
    name: 'point',
    position,
    billboard: {
      image: image,
      scale: scale || 1,
      width: width || 40,
      height: height || 36,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    },
    properties: markInfo,
    longitude: longitude || null,
    label: {
      fillColor: Cesium.Color.WHITE,
      text: title,
      font: '14pt Source Han Sans CN ',
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 39),
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    }
  });
  // 如果有水位预警就添加红点
  if (markType) {
    // 获取标牌的图像对象
    const billboardImage = point.billboard.image.getValue();
    // 创建一个新的Image对象来加载标牌图像
    const image = new Image();
    image.onload = function () {
      // 创建一个画布
      const canvas = document.createElement('canvas');
      console.log(image.width, ' image.width');
      canvas.width = image.width;
      canvas.height = image.height;
      // 在画布上绘制标牌图像
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);
      // 在画布上绘制红色点
      const radius = 8;
      const centerX = canvas.width - radius - 5;  // 调整X坐标，使红点位于右上角
      const centerY = radius + 5;  // 调整Y坐标，使红点位于右上角
      context.beginPath();
      context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      context.fillStyle = '#f56c6c';
      context.fill();
      const borderWidth = 2;
      context.lineWidth = borderWidth;
      context.strokeStyle = 'white';
      context.stroke();
      // 将画布转换为图像数据URL
      const imageDataUrl = canvas.toDataURL();

      // 创建一个新的Image对象来加载包含红色点的图像数据URL
      const newImage = new Image();
      newImage.onload = function () {
        // 将新的图像设置为标牌的图像
        point.billboard.image = newImage;
      };
      newImage.src = imageDataUrl;
    };
    // 加载标牌图像
    image.src = billboardImage;
  }

  return point;
}
export function createAreaPoint(
  viewer,
  { position, image, title, id, ellipse, longitude, markInfo }
) {
  const point = viewer.entities.add({
    id: id || null,
    name: 'pointArea',
    position,
    billboard: {
      image,
      width: 30,
      height: 30,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    },
    properties: markInfo,
    ellipse: ellipse,
    longitude: longitude || null,
    label: {
      // @ts-ignore
      text: title,
      fillColor: Cesium.Color.WHITE,
      font: '14pt Source Han Sans CN ',
      // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian3(0.0, 32, 0.0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY
    }
  });
  return point;
}

export function createLine(viewer, { polyCenter, positions, width, color, title, markInfo }) {
  const line = viewer.entities.add({
    position: polyCenter,
    polyline: {
      positions,
      width,
      material: Cesium.Color.fromCssColorString(color),
      clampToGround: true
    },
    properties: markInfo,
    label: {
      // @ts-ignore
      fillColor: Cesium.Color.WHITE,
      text: title,
      font: '14pt Source Han Sans CN ',
      // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 0)
    }
  });
  return line;
}

export function createPolygon(
  viewer,
  { polyCenter, outlineColor, positions, title, color, markInfo }
) {
  const polygon = viewer.entities.add({
    position: polyCenter,
    polygon: {
      hierarchy: positions,
      material: Cesium.Color.fromCssColorString(color),
      outline: true,
      outlineWidth: 1,
      outlineColor: Cesium.Color.fromCssColorString(outlineColor)
    },
    properties: markInfo,
    label: {
      text: title,
      font: '14pt Source Han Sans CN',
      // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      pixelOffset: new Cesium.Cartesian2(0, 0)
    }
  });
  return polygon;
}
