<script>
import MessageModal from '../../../../common/message-modal/index.vue';
import TaskInfo from './components/task-info/index.vue';
import TaskFlow from './components/task-flow/index.vue';
import ApplyModal from './components/apply-modal/index.vue';
import ProcessNodeModal from './components/process-node-modal/index.vue';
import { MessageAPI } from '@/api';

export default {
  name: 'Index',
  components: {
    ProcessNodeModal,
    ApplyModal,
    MessageModal,
    TaskInfo,
    TaskFlow
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    currentTaskItem: {
      type: Object,
      default: () => undefined
    }
  },
  data() {
    return {
      currentTab: 'taskInfo',
      applyModalShow: false,
      processNodeModalShow: false,
      msgTaskDetail: {}, // 任务详情
      flowItem: {} // 单个节点信息
    };
  },
  mounted() {
    this.getMsgTaskDetail();
  },
  methods: {
    // 任务详情
    getMsgTaskDetail() {
      MessageAPI.getMsgDetailById({ id: this.currentTaskItem.id }).then(({ data }) => {
        if (data) {
          this.msgTaskDetail = data;
        }
      });
    },
    close() {
      this.$emit('close');
    },
    // 审批弹窗
    applyClick() {
      this.applyModalShow = true;
    },
    applyModalClose() {
      this.applyModalShow = false;
    },
    applySuccess() {
      this.applyModalShow = false;
      this.getMsgTaskDetail();
      setTimeout(() => {
        this.$emit('refreshMsgList');
      }, 2000);
    },
    // 流程描述弹窗
    processNodeModalShowClick(flowItem) {
      this.flowItem = flowItem;
      this.processNodeModalShow = true;
    },
    processNodeModalClose() {
      this.processNodeModalShow = false;
    }
  }
};
</script>

<template>
  <div class="message-notifications">
    <message-modal
      :show="show"
      title="任务通知"
      :width="currentTab === 'taskInfo' ? 500 : 700"
      @close="close"
    >
      <div class="message-notifications-info">
        <div class="info-content">
          <el-tabs v-model="currentTab">
            <el-tab-pane label="任务信息" name="taskInfo">
              <task-info :msg-task-detail="msgTaskDetail" @applyClick="applyClick" />
            </el-tab-pane>
            <el-tab-pane label="任务流程" name="taskFlow">
              <task-flow
                :msg-task-detail="msgTaskDetail"
                @processNodeModalShowClick="processNodeModalShowClick"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </message-modal>
    <!--审批弹窗-->
    <apply-modal
      :show="applyModalShow"
      :task-id="msgTaskDetail.taskId"
      @close="applyModalClose"
      @applySuccess="applySuccess"
    />
    <!--流程节点描述-->
    <process-node-modal
      :show="processNodeModalShow"
      :flow-item="flowItem"
      @close="processNodeModalClose"
    />
  </div>
</template>

<style scoped lang="scss">
.message-notifications {
  &-info {
    padding: 8px;
    .info-content {
      background-color: #2c313b;
      padding-bottom: 8px;
    }
  }
}
::v-deep {
  .el-tabs__item {
    font-size: 18px;
    font-weight: 600;
    color: #a4ff64;
  }
  .el-tabs__nav-wrap::after {
    background: none;
  }
  .el-tabs__header {
    padding: 0 40px;
  }
  .el-tabs__active-bar {
    width: 37px;
    height: 4px;
    background: #a4ff64;
  }
  .el-tabs__header {
    margin: 0;
  }
}
</style>
