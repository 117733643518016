<template>
  <div class="operationLog">
    <div v-interact class="title">
      <div class="title-left">运行监控日志</div>
      <div class="title-right">
        <i class="el-icon-close" @click="onClose" />
      </div>
    </div>
    <div class="body">
      <div class="head">
        <div class="hd-left">消息等级</div>
        <div class="hd-middle">消息内容</div>
        <div class="hd-right">时间</div>
      </div>
      <div class="bd-box">
        <div v-for="item in list" :key="item.id" class="bd">
          <div class="bd-left">{{ item.id }}</div>
          <div class="bd-middle">{{ item.cmd_title }}</div>
          <div class="bd-right">{{ item.time_strap }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Control_API } from "@/api";
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      list: [
        // {
        //   id: 1,
        //   cmd_title: '测试数据1',
        //   time_strap: new Date().toLocaleString()
        // },
        // {
        //   id: 2,
        //   cmd_title: '测试数据2',
        //   time_strap: new Date().toLocaleString()
        // },
        // {
        //   id: 3,
        //   cmd_title: '测试数据3',
        //   time_strap: new Date().toLocaleString()
        // },
      ],
      Time: null,
    };
  },
  computed: {
    ...mapState("uavApplications", ["uav"]),
  },
  watch: {
    async uav(newVal) {
      if (newVal) {
        await this.getUavLog();
      }
    },
  },
  async created() {
    await this.getUavLog();
    this.Time = setInterval(() => {
      this.getUavLog();
    }, 1000);
  },
  beforeDestroy() {
    if (this.Time) clearInterval(this.Time);
  },
  methods: {
    onClose() {
      this.$emit("onCloseOperationLog");
    },
    async getUavLog() {
      const res = await Control_API.getUavAllLog({
        page: 1,
        pageSize: 999,
        deviceId: this.uav.hardId,
        startDateId: dayjs().format("YYYY-MM-DD"),
        endDateId: dayjs().format("YYYY-MM-DD"),
      });
      if (res.status === 1) {
        console.log(this.list, "this.list");
        this.list = res.data.data;
        // .filter((item) => {
        //   const current_date = new Date();
        //   const difference =
        //     current_date.getTime() - new Date(item.time_strap).getTime();
        //   const hoursMilli = 1000 * 60 * 30; // 半个小时内
        //   return Math.abs(difference) < hoursMilli;
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.operationLog {
  position: absolute;
  right: 0vw;
  bottom: 26vh;
  width: 445px;
  height: 300px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(52, 223, 255, 1),
      rgba(24, 113, 214, 0)
    )
    1 1;
  backdrop-filter: blur(4px);
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000;
    border-radius: 4px 4px 0px 0px;
    color: #ebf5ff;
    padding: 0 8px;
    .title-left {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      line-height: 19px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  .body {
    padding: 8px;
    .head {
      display: flex;
      align-items: center;
      color: #b3bbc5;
      .hd-left,
      .hd-middle,
      .hd-right {
        text-align: center;
        width: 83px;
        height: 36px;
        line-height: 36px;
        background: #1e2026
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.05) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
          inset 1px 1px 0px 0px #000000;
      }
      .hd-middle,
      .hd-right {
        width: 173px;
      }
    }
    .bd-box {
      height: 216px;
      overflow-y: auto;
      .bd {
        display: flex;
        height: 36px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        color: #c6ccd4;
        &:nth-child(odd) {
          background-color: #000000;
        }
        &:nth-child(even) {
          background-color: rgb(20, 21, 26);
        }
        .bd-left,
        .bd-middle {
          border-right: 1px solid #1e2026;
        }
        .bd-left {
          width: 83px;
        }
        .bd-middle,
        .bd-right {
          width: 173px;
        }
        .bd-left,
        .bd-middle,
        .bd-right {
          text-align: center;
          line-height: 36px;
        }
      }
    }
  }
}
</style>
