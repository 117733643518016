export function formatDate(t, str) {
    var obj = {
        yyyy: t.getFullYear(),
        yy: ("" + t.getFullYear()).slice(-2),
        M: t.getMonth() + 1,
        MM: ("0" + (t.getMonth() + 1)).slice(-2),
        d: t.getDate(),
        dd: ("0" + t.getDate()).slice(-2),
        H: t.getHours(),
        HH: ("0" + t.getHours()).slice(-2),
        h: t.getHours() % 12,
        hh: ("0" + t.getHours() % 12).slice(-2),
        m: t.getMinutes(),
        mm: ("0" + t.getMinutes()).slice(-2),
        s: t.getSeconds(),
        ss: ("0" + t.getSeconds()).slice(-2),
        w: ['日', '一', '二', '三', '四', '五', '六'][t.getDay()],
        yyyyMMddhhmmss: t.getFullYear() + ("0" + (t.getMonth() + 1)).slice(-2) + ("0" + t.getDate()).slice(-2) + ("0" + t.getHours() % 12).slice(-2) + ("0" + t.getMinutes()).slice(-2) + ("0" + t.getSeconds()).slice(-2),
    };
    return str.replace(/([a-z]+)/ig, function ($1) {
        return obj[$1]
    });
}
export function newDate() {
    let date = new Date();
      let Year = date.getFullYear();
      let Month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let Hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let Minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let Seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let getDay = date.getDay();
      return Year+"-"+Month+"-"+Day+" "+Hours+":"+Minutes+":"+Seconds
}