<script>
import { mapState } from 'vuex';
export default {
  name: 'Index',
  data() {
    return {
      currentTab: '运行状态',
      operateStatusList: [
        {
          id: 1,
          label: '鹰巢',
          icon: require('@/assets/image/icons/eagle-monitor-01.svg')
        },
        {
          id: 2,
          label: '射频',
          icon: require('@/assets/image/icons/eagle-monitor-02.svg'),
          key: 'rfPower',
          option: [
            {
              status: 2,
              label: '射频',
              icon: require('@/assets/image/icons/eagle-monitor-02.svg')
            },
            {
              status: 1,
              label: '射频',
              icon: require('@/assets/image/icons/eagle-monitor-02-active.svg'),
              labelColor: '#ff0'
            }
          ]
        },
        {
          id: 3,
          label: '舱内摄像头',
          icon: require('@/assets/image/icons/eagle-monitor-03.svg'),
          key: 'inCameraPower',
          option: [
            {
              status: 2,
              label: '舱内摄像头',
              icon: require('@/assets/image/icons/eagle-monitor-03.svg')
            },
            {
              status: 1,
              label: '舱内摄像头',
              icon: require('@/assets/image/icons/eagle-monitor-03-active.svg'),
              labelColor: '#ff0'
            }
          ]
        },
        {
          id: 4,
          label: '舱外摄像头',
          icon: require('@/assets/image/icons/eagle-monitor-04.svg'),
          key: 'outCameraPower',
          option: [
            {
              status: 2,
              label: '舱外摄像头',
              icon: require('@/assets/image/icons/eagle-monitor-04.svg')
            },
            {
              status: 1,
              label: '舱外摄像头',
              icon: require('@/assets/image/icons/eagle-monitor-04-active.svg'),
              labelColor: '#ff0'
            }
          ]
        },
        {
          id: 5,
          label: 'UAV充电器',
          icon: require('@/assets/image/icons/eagle-monitor-06.svg'),
          key: 'chargerPower',
          option: [
            {
              status: 2,
              label: 'UAV充电器',
              icon: require('@/assets/image/icons/eagle-monitor-06.svg')
            },
            {
              status: 1,
              label: 'UAV充电器',
              icon: require('@/assets/image/icons/eagle-monitor-06-active.svg'),
              labelColor: '#ff0'
            }
          ]
        },
        {
          id: 6,
          label: '内部灯',
          icon: require('@/assets/image/icons/eagle-monitor-07.svg'),
          key: 'inLamp',
          option: [
            {
              status: 2,
              label: '内部灯',
              icon: require('@/assets/image/icons/eagle-monitor-07.svg')
            },
            {
              status: 1,
              label: '内部灯',
              icon: require('@/assets/image/icons/eagle-monitor-07-active.svg'),
              labelColor: '#ff0'
            }
          ]
        },
        {
          id: 7,
          label: '外部灯',
          icon: require('@/assets/image/icons/eagle-monitor-08.svg'),
          key: 'outLamp',
          option: [
            {
              status: 2,
              label: '外部灯',
              icon: require('@/assets/image/icons/eagle-monitor-08.svg')
            },
            {
              status: 1,
              label: '外部灯',
              icon: require('@/assets/image/icons/eagle-monitor-08-active.svg'),
              labelColor: '#ff0'
            }
          ]
        }
      ],
      operateMonitorList: [
        {
          id: 1,
          key: 'rtkPower',
          tooltip: 'RTK基站',
          option: [
            {
              label: '关闭',
              icon: require('@/assets/image/icons/eagle-monitor-09.png'),
              status: 2
            },
            {
              label: '打开',
              icon: require('@/assets/image/icons/eagle-monitor-09-active.png'),
              status: 1
            }
          ],
          label: '关闭',
          icon: require('@/assets/image/icons/eagle-monitor-09.png')
        },
        {
          id: 2,
          key: 'code',
          tooltip: '准飞/禁飞',
          option: [
            {
              label: '禁飞',
              icon: require('@/assets/image/icons/eagle-monitor-10.png'),
              status: 1
            },
            {
              label: '准飞',
              icon: require('@/assets/image/icons/eagle-monitor-10-active.png'),
              status: 10000
            }
          ],
          label: '禁飞',
          icon: require('@/assets/image/icons/eagle-monitor-10.png')
        },
        {
          id: 3,
          key: 'cover',
          tooltip: '舱盖',
          option: [
            {
              label: '关闭',
              icon: require('@/assets/image/icons/eagle-monitor-11.png'),
              status: 1
            },
            {
              label: '打开',
              icon: require('@/assets/image/icons/eagle-monitor-11-active.png'),
              status: 2
            }
          ],
          label: '关闭',
          icon: require('@/assets/image/icons/eagle-monitor-11.png')
        },
        {
          id: 4,
          key: 'folder',
          tooltip: '回中器',
          option: [
            {
              label: '放松',
              icon: require('@/assets/image/icons/eagle-monitor-12.png'),
              status: 2
            },
            {
              label: '夹紧',
              icon: require('@/assets/image/icons/eagle-monitor-12-active.png'),
              status: 1
            }
          ],
          label: '放松',
          icon: require('@/assets/image/icons/eagle-monitor-12.png')
        },
        {
          id: 5,
          tooltip: '起降台',
          key: 'lifts',
          option: [
            {
              label: '降下',
              icon: require('@/assets/image/icons/eagle-monitor-13.png'),
              status: 1
            },
            {
              label: '升起',
              icon: require('@/assets/image/icons/eagle-monitor-13-active.png'),
              status: 2
            }
          ],
          label: '降下',
          icon: require('@/assets/image/icons/eagle-monitor-13.png')
        }
      ]
    };
  },
  computed: {
    ...mapState('uavApplicationsEagleNest', ['currentEagle']),
    ...mapState('uavApplications', ['uav']),
    getCurrentList() {
      return this.currentTab === '运行状态' ? this.operateStatusList : this.operateMonitorList;
    },
    mqttDataSet() {
      return this.$store.state.MMCMQTT?.dataSet?.[this.currentEagle?.hardId];
    },
    uavMqData() {
      return this.$store.state.MMCMQTT?.dataSet?.[this.uav?.hardId];
    }
  },
  methods: {
    getItem(item) {
      const mqEagleData = {
        ...this.mqttDataSet?.['2065']?.['data'],
        ...this.uavMqData?.['270']?.['data']
      };
      if (mqEagleData.hasOwnProperty(item.key)) {
        return item.option.find((v) => v.status === mqEagleData[item.key]) || item;
      } else {
        return item;
      }
    }
  }
};
</script>

<template>
  <div class="eagle-monitoring">
    <div class="eagle-monitoring-header">
      <el-radio-group v-model="currentTab">
        <el-radio-button label="运行状态"></el-radio-button>
        <el-radio-button label="运行监测"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="eagle-monitoring-content">
      <div class="monitoring-list">
        <div v-for="v in getCurrentList" :key="v.id">
          <el-tooltip :disabled="!v.tooltip" effect="dark" :content="v.tooltip" placement="top">
            <div class="monitoring-list-item">
              <div class="item-icon">
                <img :src="getItem(v).icon" class="svg-img" />
              </div>
              <div class="item-name" :style="{ color: getItem(v).labelColor || '#fff' }">
                {{ getItem(v).label }}
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.eagle-monitoring {
  position: absolute;
  width: 478px;
  height: 194px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 10px;
  border: 1px solid rgba(52, 223, 255, 1);
  //border-image: linear-gradient(180deg, rgba(52, 223, 255, 1), rgba(24, 113, 214, 0)) 1 1;
  //backdrop-filter: blur(4px);
  transform: translateY(10px);
  left: 0;
  overflow: hidden;
  background-color: rgb(89, 99, 120);
  &-header {
    .el-radio-group {
      width: 100%;
      display: flex;
      .el-radio-button {
        flex: 1;
        background-color: rgb(89, 99, 120);
      }
    }
  }
  &-content {
    height: 156px;
    background: #0a0b0d;
    border-radius: 4px;
    .monitoring-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      padding: 8px;
      box-sizing: border-box;
      &-item {
        width: 64px;
        height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #3a4252;
        border-radius: 4px;
        .item-icon {
          img {
            width: 30px;
            height: 30px;
            vertical-align: top;
          }
        }
        .item-name {
          font-size: 12px;
          color: #f8f8f8;
          line-height: 16px;
          margin-top: 7px;
        }
      }
    }
  }
}
::v-deep {
  .el-radio-button__inner {
    width: 100% !important;
    color: #fff;
    background: none;
    border: none;
  }
  .el-radio-button:first-child .el-radio-button__inner {
    border: none;
  }
}
</style>
